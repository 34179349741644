/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import SideDrawer from './../../Components/SideDrawer';
import { createTheme, ThemeProvider } from "@mui/material";
import { LoadResourceAction, UpdateResourceAction } from "../../Redux/Actions/CrudActions";
import { useSelector, useDispatch } from 'react-redux';
import RequestsEdit from './Edit';
import { Spinner } from "../../Components/Spinner";
import { UpdateDataService } from "../../Services/CrudServices";
import { isMobile } from "react-device-detect";

const CustomersGrid = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {

    const ACTION_TYPE = "COMMON";
    
    const BACKEND_ROUTE = "customers";

    const theme = createTheme();

    const [id, setID] = React.useState(null);

    const [refreshTable, setRefreshTable] = useState(false);

    const [editDrawerStatus, setEditDrawerStatus] = React.useState(null);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();


    const HandleEditDrawer = (event) => {
        if (editDrawerStatus) {
            setID(null);
            setEditDrawerStatus(false);
        }
    }
    const HandleEdit = (rowData) => {
        setID(rowData.id);
        setEditDrawerStatus(true);
    }

    React.useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        };
        setRefreshTable(false);
    }, [refreshTable]);


    const HandleActiveInActiveStatus = (rowData) => {
        var update_status_route = `update_resource_status/Customer/${rowData.id}`
        var newStatus = rowData.status === 1 ? false : true;
        var data = {'_method': 'put', 'status': newStatus};
        UpdateDataService(update_status_route, data)
        .then((res) => {
            setRefreshTable(true);
        }, error => {
            console.log("error");
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={isMobile ? '': Title}
                    isLoading={state.all.loading}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            icon: () => (isMobile)
                            ?<i className="fa-solid fa-plus theme-text-color-primary" style={{fontSize: '1.2rem'}}></i>
                            :<span className="btn btn-theme-primary btn-sm ml-2">Create a Customer</span>,
                            tooltip: 'Add Customer',
                            isFreeAction: true,
                            onClick: (event, rowData) => HandleDrawerCreate()
                        },
                        rowData => ({
                            icon: () => (rowData.status) 
                            ? <i className="fa-solid fa-ban text-danger" style={{fontSize: '1.2rem'}}></i> 
                            : <i className="fa-solid fa-ban text-success" style={{fontSize: '1.2rem'}}></i> ,
                            tooltip: (rowData.status) ?  'Deactivate Customer' : 'Activate Customer',
                            onClick: (event, rowData) => HandleActiveInActiveStatus(rowData),
                        }),
                    ]}
                />
            </div>
            <SideDrawer status={editDrawerStatus} handle={HandleEditDrawer} >
                {id &&<RequestsEdit ID={id} />}
            </SideDrawer>
        </ThemeProvider>
    );
}

export default CustomersGrid;