/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import RolesGrid from './Grid';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';
import { LoadResourceAction } from '../../../Redux/Actions/CrudActions';


export default function RolesIndex() {

    const PageName = 'Roles';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "roles";

    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            { title: 'Id', field: 'id' },
            { title: 'Role Name', field: 'name' },
            { title: 'Description', field: 'description' },
            { title: 'Created At', field: 'created_at' },
            { title: 'Updated At', field: 'updated_at' },
        ]
    });

    const [data, setData] = useState([]);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);
    

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({type:`RESTART_${ACTION_TYPE}_LIST`});
        }
    }, [state]);


    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
            <div className="card rounded shadow">
                <div className="card-body p-0">
                    <RolesGrid
                        dataList={data}
                        setDataList={setData}
                        columns={tablecolumn}
                        Title={PageName}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
