import React from 'react'

export default function MasterFormDragItem({...props}) {
    return (
        <div
            className="MasterFormDragItem d-flex align-items-center bg-light border border-secondary px-2 py-0 rounded-lg mb-1 " 
            style={{'justifyContent':'space-between'}}
            data-id={props.section.id}
            draggable
            onDragStart={(e) => props.onDragStart(e)}
        >
            <p className="m-0 p-0">{props.section.name} </p>
            <div onClick={(e) => props.open_properties(e, props.section)} style={{'cursor':'pointer'}}>
            {props.type =='master' ? '' : '>>'}
            </div>
        </div>
    )
}
