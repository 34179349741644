/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import UsersForm from "./Form";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FormSkeleton from "../../Components/Skeleton/FormSkeleton";
import { LoadResourceAction, ShowResourceAction, UpdateResourceAction } from './../../Redux/Actions/CrudActions';

const UserEdit = ({ ID }) => {

    const SubmitButtonName = 'Update User';

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "users";

    var fields = {
        title: "",
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        mobile_number: "",
        role_id: "",
        utility_id: "",
        utility_department_id: [],
        _method: 'PUT',
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);
    
    const [refreshTable, setRefreshTable] = useState(false);

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    useEffect(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(ShowResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (state.form.success) {
            setErrors(fields);
            setRefreshTable(true);
        }
        if (state.show.success && state.show.payload) {
            setData({
                "_method": "PUT",
                'title': state.show.payload.data.title ?? '',
                'first_name': state.show.payload.data.first_name  ?? '',
                'last_name': state.show.payload.data.last_name  ?? '',
                'name': state.show.payload.data.name  ?? '',
                'email': state.show.payload.data.email  ?? '',
                'mobile_number': state.show.payload.data.mobile_number  ?? '',
                'role_id': state.show.payload.data.roles[0].id  ?? '',
                'utility_id': state.show.payload.data.utility_id  ?? '',
                'utility_department_id': state.show.payload.data.utility_departments.map(a => a.id)  ?? [],
            });
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        }
    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        var formData = {...data,name: data.first_name + ' ' + data.last_name};
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, formData, ACTION_TYPE));
    }

    return (
        <div>
            {
                state.show.loading ?
                <FormSkeleton />
                :
                <UsersForm
                    data={data}
                    errors={errors}
                    setData={setData}
                    hide_password={true}
                    handleSubmit={handleSubmit}
                    btnloading={state.form.loading}
                    SubmitButtonName={SubmitButtonName}
                />
            }
        </div>
    );
}

export default UserEdit;