/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import Form from "./Form";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { LoadResourceAction, ShowResourceAction, UpdateResourceAction } from './../../Redux/Actions/CrudActions';
import FormSkeleton from "../../Components/Skeleton/FormSkeleton";

const CustomersEdit = ({ ID }) => {

    const PageName = 'Edit Customer';
    const SubmitButtonName = 'Edit Customer';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "customers";

    var fields = {
        customer_id: '',
        utility_id:'',
        email:'',
        mobile_number:'',
        first_name: '',
        middle_name:'',
        last_name:'',
        street:'',
        city:'',
        state:'',
        zip:'',
        country:'',
        status:'',
        _method: 'PUT',
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);
    
    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    // Load Particular Resource By ID and clear previous state for form.
    useEffect(() => {
        // Clear Previous State of The Form from Redux Store
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });

        // Fetch the Resource from Server via redux store.
        dispatch(ShowResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {

        if (state.form.validation_errors) {
            // store the validation errors in the state object setErrors.
            state.form.validation_errors.map((value, key) => {
                setErrors({ ...data, [key]: value })
            });
            // Restart the Form on validation errors came from the server.
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        // Successfully Update the resouce and clear the form.
        if (state.form.success && state.form.payload) {
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            }, 1000);
            setRefreshTable(true);
        }

        // Successfully Fetched resouce from the server
        if (state.show.success && state.show.payload) {
            // Set All data in state object.
            setData({
                "_method": "PUT",
                "customer_id": state.show.payload.data.id,
                "utility_id":2,
                "email":state.show.payload.data.email,
                "mobile_number":state.show.payload.data.mobile_number,
                "first_name": state.show.payload.data.first_name,
                "middle_name":state.show.payload.data.middle_name ?  state.show.payload.data.middle_name : "",
                "last_name":state.show.payload.data.last_name,
                "street":state.show.payload.data.street,
                "city":state.show.payload.data.city,
                "state":state.show.payload.data.state,
                "zip":state.show.payload.data.zip,
                "country":state.show.payload.data.country,
                "status":state.show.payload.data.status,
                // 'service_category_id': state.show.payload.data.service_category_id,
                // 'title': state.show.payload.data.title,
                // 'description': state.show.payload.data.description,
            });
            // Restart the show action on came from the server.
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        }

    }, [state]);


    function handleSubmit(e) {
        e.preventDefault();
        // Call Redux Action to update the resouce
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <div>
            {
                state.show.loading ?
                    <>
                        <FormSkeleton />
                    </>
                    :
                    <Form
                        btnloading={state.form.loading}
                        handleSubmit={handleSubmit}
                        data={data}
                        setData={setData}
                        SubmitButtonName={SubmitButtonName}
                        errors={errors}
                    />
            }
        </div>
    );
}

export default CustomersEdit;