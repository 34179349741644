import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useState } from "react";
import DynTextInput from "./DynInputs/DynTextInput";
import './SelectedInputElement.css';

function SelectedInputElement({ id, input_type_name, icon, component, index, deleteBoardElement , input_props , updateInputProps, moveBoardElement}) {
    
    /* const [{ isDragging }, drag] = useDrag(() => ({
        type: "div",
        item: { id: id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    })); */

    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: 'div',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveBoardElement(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })



    const [editInput, SetEditInput] = useState(false);

    return (
        <div className="sie bg-white" data-id={id} data-handler-id={handlerId} >
            <div className="p-2 d-flex align-items-center justify-content-between">
                <div>
                    <i className={`mr-2 ${icon}`}></i>
                    {input_type_name}
                </div>
                <div className="action-cons">
                    <i className={`fas fa-${ editInput ? "check" : "pencil"} text-primary`} onClick={() => SetEditInput( (editInput === true) ? false : true)} ></i>
                    <i className="text-danger fas fa-trash " onClick={() => deleteBoardElement(index)}></i>
                </div>
            </div>
            <hr className="m-0 p-0" />
            <div className="p-2">
                {
                    component === 'dy_input' ?
                    <DynTextInput 
                        index = {index}
                        editInput={editInput} 
                        input_props={input_props}  
                        updateInputProps = {updateInputProps}               
                    />
                    :
                    <></>
                }
                {/* <component /> */}
            </div>
        </div>
    );
}

export default SelectedInputElement;

