import { configureStore } from '@reduxjs/toolkit'
import RootReducer from "./Reducers/RootReducer"

export const store = configureStore({
    reducer: RootReducer,
    devTools: true, // false to hide redux store
});


// import {createStore, applyMiddleware, compose} from 'redux';
// import thunk from 'redux-thunk';
// import RootReducer from "./Reducers/RootReducer"


// export const store = createStore(
//     RootReducer,
//     compose(
//       applyMiddleware(thunk),
//       window.__REDUX_DEVTOOLS_EXTENSION__
//         ? window.__REDUX_DEVTOOLS_EXTENSION__()
//         : f => f
//     ),
// );
