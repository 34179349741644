import update from 'immutability-helper'
import React from 'react'
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';
import { FormGenerator } from 'dynamic-material-ui';
import * as MUI from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop } from "react-dnd";
import TemplateInputElement from '../Utilities/Services/TemplateInputElement';
import '../Utilities/Services/MasterForm.css';
import SelectedInputElement from '../Utilities/Services/SelectedInputElement';
// import DynTextInput from '../Utilities/Services/DynTextInput';
import ViewInputElement from '../Utilities/Services/ViewInputElement';
import LabelDragDrop from './LabelDragDrop';
import './LabelDragDrop.css';
export default function MasterFormForm() {

    const PAGENAME = 'Create';

    const [pageName, setPageName] = useState(PAGENAME);

    const JSONData = [
        {
            id: 1,
            type: 'textfield',
            props: {
                id: "name",
                label: "Hello, Whats your name?",
                fullWidth: true,
                margin: "normal",
                value: "Patched Name",
                errorText: ""
            },
            rules: {
                validation: [
                    {
                        rule: "mandatory",
                        message: "Name is required!!"
                    }
                ]
            },
            layout: {
                row: 1,
                xs: { col: 6 },
                sm: { col: 6 },
                md: { col: 6 },
                lg: { col: 6 }
            },
        },
        {
            id: 2,
            type: 'textfield',
            props: {
                id: "name1",
                label: "Hello, Whats your name?",
                fullWidth: true,
                margin: "normal",
                value: "",
                errorText: ""
            },
            rules: {
                validation: [
                    {
                        rule: "mandatory",
                        message: "Name is required!!"
                    }
                ]
            },
            layout: {
                row: 1,
                xs: { col: 3 },
                sm: { col: 3 },
                md: { col: 3 },
                lg: { col: 3 }
            },
        }
    ];
    const [formData, setFormData] = useState(JSONData);
    const [displayFormErrors, setDisplayFormErrors] = useState();

    const TemplateInputElementsList = [
        {
            id: 1, input_type_name: "Header Text", icon: "fas fa-heading", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 2, input_type_name: "Label", icon: "fas fa-font", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 3, input_type_name: "Paragraph", icon: "fas fa-paragraph", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 4, input_type_name: "Line Break", icon: "fas fa-arrows-alt-h", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 5, input_type_name: "Dropdown", icon: "far fa-caret-square-down", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 6, input_type_name: "Tags", icon: "fas fa-tags", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 7, input_type_name: "Checkboxes", icon: "far fa-check-square", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 8, input_type_name: "Multiple Choice", icon: "far fa-dot-circle", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 9, input_type_name: "Text Input", icon: "fas fa-font", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 10, input_type_name: "Email", icon: "fas fa-envelope", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 11, input_type_name: "Number Input", icon: "fas fa-plus", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 12, input_type_name: "Phone Number", icon: "fas fa-phone", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 13, input_type_name: "Multi-line Input", icon: "fas fa-text-height", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 14, input_type_name: "Two Column Row", icon: "fas fa-columns", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 15, input_type_name: "Three Columns Row", icon: "fas fa-columns", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 16, input_type_name: "Four Columns Row", icon: "fas fa-columns", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 17, input_type_name: "Image", icon: "far fa-image", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 18, input_type_name: "Rating", icon: "fas fa-star", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 19, input_type_name: "Date", icon: "far fa-calendar-alt", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 20, input_type_name: "Signature", icon: "fas fa-pen-square", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 21, input_type_name: "Website", icon: "fas fa-link", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 22, input_type_name: "File Attachment", icon: "fas fa-file", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 23, input_type_name: "Range", icon: "fas fa-sliders-h", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 24, input_type_name: "Camera", icon: "fas fa-camera", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
        {
            id: 25, input_type_name: "File Upload", icon: "fas fa-file", component: 'dy_input',
            input_props: {
                name: 'email',
                label: 'Email Address',
                placeholder: 'name@example.com',
                id: 'email',
                class: '',
                defaultValue: '',
                required: true
            }
        },
    ];


    const allFormLabel = {
        main_section: {
            label: "Source Fields",
            items: [
                { id: 1, name: "Form Label 1" },
                { id: 2, name: "Form Label 2" },
                { id: 3, name: "Form Label 3" },
                { id: 4, name: "Form Label 4" },
                { id: 5, name: "Form Label 5" },

            ]
        },
        dynamic_Section_1: {
            label: "Basic Info",
            items: [
                { id: 6, name: "Form Label 6" },
            ]
        },
        dynamic_Section_2: {
            label: "Basic Info",
            items: [
                // { id: 6, name: "Form Label 6" },
            ]
        },
    };


    const [dropPannel, setDropPannel] = React.useState(allFormLabel);
    const [rightDrag, setRight] = React.useState([4, 5, 6, 7]);



    // const allRightSideLabel

    const [board, setBoard] = useState([]);

    // const [{ isOver }, drop] = useDrop(() => ({
    //     accept: "div",
    //     drop: (item) => addImageToBoard(item.id),
    //     collect: (monitor) => ({
    //         isOver: !!monitor.isOver(),
    //     }),
    // }));

    const addImageToBoard = (id) => {
        const tieList = TemplateInputElementsList.filter((tie) => id === tie.id);
        console.log(tieList, tieList[0]);

        setBoard((board) => [...board, tieList[0]]);

    };

    const moveBoardElement = useCallback((dragIndex, hoverIndex) => {
        setBoard((board) =>
            update(board, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, board[dragIndex]],
                ],
            }),
        )
    }, [])
    const updateInputProps = (index, e) => {

        const boardData = JSON.parse(JSON.stringify(board)); // deep copy of board array

        const getArray = { ...boardData[index].input_props };

        const { name, value } = e.target;

        boardData[index].input_props = { ...getArray, [name]: value };

        // console.log("old*****",board,"new*****",boardData);

        setBoard(boardData);

    }

    const deleteBoardElement = (index) => {
        console.log(index);
        board.splice(index, 1);
        setBoard([...board]);
    }


    const [fruitItems, setFruitItems] = React.useState([
        "Apple",
        "Banana",
        "Orange",
    ])
    const [newFruitItem, setNewFruitItem] = React.useState("")

    //save reference for dragItem and dragOverItem
    const dragItem = React.useRef();
    const dragOverItem = React.useRef();

    //const handle drag sorting
    const handleSort = () => {
        //duplicate items
        let _fruitItems = [...fruitItems]

        //remove and save the dragged item content
        const draggedItemContent = _fruitItems.splice(dragItem.current, 1)[0]

        //switch the position
        _fruitItems.splice(dragOverItem.current, 0, draggedItemContent)

        //reset the position ref
        dragItem.current = null
        dragOverItem.current = null

        //update the actual array
        setFruitItems(_fruitItems)
    }

    //handle name change
    const handleNameChange = (e) => {
        setNewFruitItem(e.target.value)
    }

    //handle new item addition
    const handleAddItem = () => {
        const _fruitItems = [...fruitItems]
        _fruitItems.push(newFruitItem)
        setFruitItems(_fruitItems)
    }

    const itemDragStart = (e, index, dropsection) => {
        console.log("drag start =====>",e, index);
        e.dataTransfer.clearData();
        e.dataTransfer.setData('text/plain', JSON.stringify({ index: index, dropsection: dropsection }));

    }

    /* draggable element */
    // const item = document.querySelector('.item');

    // item.addEventListener('dragstart', dragStart);

    // function dragStart(e) {
    //     e.dataTransfer.setData('text/plain', e.target.id);
    //     setTimeout(() => {
    //         e.target.classList.add('hide');
    //     }, 0);
    // }

    /* drop targets */
    const boxes = document.querySelectorAll('.box');

    boxes.forEach(box => {
        box.addEventListener('dragenter', dragEnter)
        box.addEventListener('dragover', dragOver);
        box.addEventListener('dragleave', dragLeave);
        box.addEventListener('drop', drop);
    });

    function dragEnter(e) {
        console.log("drag enter");
        e.preventDefault();
        e.target.classList.add('drag-over');
    }

    function dragOver(e) {
        console.log("drag over");
        e.preventDefault();
        e.target.classList.add('drag-over');
    }

    function dragLeave(e) {
        console.log("drag leave");
        e.target.classList.remove('drag-over');
    }

    function drop(e) {

        const dropdata = e.dataTransfer.getData('text');
        const dropdataObj = JSON.parse(dropdata);

       

        const { index, dropsection } = dropdataObj;

        const newdropsection = e.target.dataset.dropsection;


        
        console.log(e.target.dataset.dropsection);



        console.log("dropped id===>", dropdataObj.index, dropdataObj.dropsection);
        e.target.classList.remove('drag-over');


        // const tieList = TemplateInputElementsList.filter((tie) => id === tie.id);
        // console.log(tieList, tieList[0]);

        // setBoard((board) => [...board, tieList[0]]);



        const dropPannelData = JSON.parse(JSON.stringify(dropPannel)); // deep copy of board array
        // get label 
        const getLabel = dropPannelData[dropsection].items[dropdataObj.index];


        // add label
        dropPannelData[newdropsection].items.push(getLabel);

        // remove label
        dropPannelData[dropsection].items.splice(dropdataObj.index, 1);


        console.log(dropPannelData);

        setDropPannel(dropPannelData);

        e.dataTransfer.clearData();


        // console.log(dropPannelData[dropsection].items);


        // dropPannelData.splice(dropdataObj.index, 1);


        // setDropPannel(dropPannelData);

        // const {name , value} = e.target;

        // boardData[index].input_props = {...getArray, [name]:value};

        // // console.log("old*****",board,"new*****",boardData);

        // setBoard(boardData);


        // 




        // arrangeDrag(id);

        // console.log("drag drop");


        // console.log(e.target.id);
        // console.log(e);


        // // get the draggable element
        // const id = e.dataTransfer.getData('text/plain');

        // console.log(id);


        // const draggable = document.getElementById(id);

        // // add it to the drop target
        // e.target.appendChild(draggable);

        // // display the draggable element
        // draggable.classList.remove('hide');

    }



    return (

        <ContentWrapper pageName={pageName} breadcrumbs={breadcrumbs(pageName, `/masterform`, "Masters")}>
            <div className="card rounded">
                <div className="card-body p-2">
                    <FormGenerator
                        guid="simple-form" //mandatory unique id
                        data={formData}
                        displayErrors={displayFormErrors} //Displays only mandatory field errors
                        library={MUI}
                        forceUpdate={false} //Default(false) -> Force update the form data
                        patch={{ 1: 'Patched Name' }} //pass patch to update the data partially {"1": "Name"}

                    />
                </div>
            </div>

            <div className="card rounded">
                <div className="d-flex">
                    <div id="form-elements" className="py-5 pt-0 px-2 border-sky-100" style={{ width: "40%" }}>
                        <div >
                            <label>Form Section</label>

                        </div>

                        {/* {
                                allFormLabel.map((label) => {
                                    return <LabelDragDrop label={label} />;
                                })
                            } */}



                        {/* {TemplateInputElementsList.map((TIE) => {
                                return <TemplateInputElement input_type_name={TIE.input_type_name} id={TIE.id} icon={TIE.icon}/>;
                            })} */}
                    </div>
                    <div id="form-elements" className="py-5 px-2" style={{ width: "40%" }}>

                        {/* {
                            allFormLabel.map((label) => {
                                return <LabelDragDrop label={label} />;
                            })
                        } */}


                        {/* {TemplateInputElementsList.map((TIE) => {
                                return <TemplateInputElement input_type_name={TIE.input_type_name} id={TIE.id} icon={TIE.icon}/>;
                            })} */}
                    </div>
                    <div id="form-generator" className="bg-light p-5"  >
                        {board.map((TIE, index) => {
                            return <SelectedInputElement
                                input_type_name={TIE.input_type_name}
                                id={TIE.id}
                                icon={TIE.icon}
                                index={index}
                                deleteBoardElement={deleteBoardElement}
                                moveBoardElement={moveBoardElement}
                                updateInputProps={updateInputProps}
                                component={TIE.component}
                                input_props={TIE.input_props}
                            />;
                        })}
                    </div>
                    <div id="form-elements" className="py-5 px-2" style={{ width: "75%" }}>
                        {board.map((TIE, index) => {
                            return <SelectedInputElement
                                input_type_name={TIE.input_type_name}
                                id={TIE.id}
                                icon={TIE.icon}
                                index={index}
                                deleteBoardElement={deleteBoardElement}
                                moveBoardElement={moveBoardElement}
                                updateInputProps={updateInputProps}
                                component={TIE.component}
                                input_props={TIE.input_props}
                            />;
                        })}
                    </div>
                </div>
            </div>
            <div className="card rounded">
                <h1>Form OutPut</h1>
                {board.map((TIE, index) => {
                    return <ViewInputElement
                        input_type_name={TIE.input_type_name}
                        id={TIE.id}
                        icon={TIE.icon}
                        index={index}
                        component={TIE.component}
                        input_props={TIE.input_props}
                    />;
                })}
            </div>

            <div className="app">
                <h2>Fruit List</h2>
                <div className="input-group">
                    <input
                        type="text"
                        name="fruitName"
                        placeholder="e.g Banana"
                        onChange={handleNameChange}
                    />
                    <button className="btn" onClick={handleAddItem}>
                        Add item
                    </button>
                </div>

                {/** List container //TODO break into component */}
                <div className="list-container item dragHere box" data-dropsection="main_section">
                    {dropPannel.main_section.items.map((item, index) => (
                        <div
                            key={index}
                            className="list-item item"
                            id="item"
                            data-index={index}
                            draggable
                            onDragStart={(e) => itemDragStart(e, index, 'main_section')}
                        // onDragEnter={(e) => (dragOverItem.current = index)}
                        // onDragEnd={handleSort}
                        // onDragOver={(e) => console.log(e)}
                        >
                            <i className="fa-solid fa-bars"></i>
                            <h3>{item.name}</h3>
                        </div>
                    ))}
                </div>
                <div>
                    <p>Drag here</p>
                    <div className='dragHere box' id="hello" data-dropsection="dynamic_Section_1">
                        {dropPannel.dynamic_Section_1.items.map((item, index) => (
                            <div
                                key={index}
                                className="list-item item"  
                                id="item"
                                data-index={index}
                                draggable
                                onDragStart={(e) => itemDragStart(e, index, 'dynamic_Section_1')}
                            // onDragEnter={(e) => (dragOverItem.current = index)}
                            // onDragEnd={handleSort}
                            // onDragOver={(e) => console.log(e)}
                            >
                                <i className="fa-solid fa-bars"></i>
                                <h3>{item.name}</h3>
                            </div>
                        ))}
                    </div>
                    <div className='dragHere box' id="hello2" data-dropsection="dynamic_Section_2">
                    {dropPannel.dynamic_Section_2.items.map((item, index) => (
                            <div
                                key={index}
                                className="list-item item"  
                                id="item"
                                data-index={index}
                                draggable
                                onDragStart={(e) => itemDragStart(e, index, 'dynamic_Section_2')}
                            // onDragEnter={(e) => (dragOverItem.current = index)}
                            // onDragEnd={handleSort}
                            // onDragOver={(e) => console.log(e)}
                            >
                                <i className="fa-solid fa-bars"></i>
                                <h3>{item.name}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </ContentWrapper>

    )
}

function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {
                IndexPageName &&
                <li className="breadcrumb-item">
                    <NavLink to={IndexRoute}>{IndexPageName}</NavLink>
                </li>
            }
            <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>Master Forms</NavLink>
            </li>

            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
