/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React from 'react';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';
import { NavLink } from 'react-router-dom';

export default function FormTypeIndex(props) {

  const PageName = 'Master Form Type';
  const CreateNewButtonName = 'Create New Form Type';
  const IndexRoute = '/masterformtype';
  const CreateNewRoute = '/masterformtype/create';
  const EditPageRoute = '/masterformtype/edit/';
  
  return (
    <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
        <div className="card rounded shadow">
            <div className="card-header">
                <h3 className="card-title">{PageName}</h3>
                <div className="card-tools">
                    <NavLink to={CreateNewRoute} className="btn btn-theme-primary">{CreateNewButtonName}</NavLink>
                </div>
            </div>
            <div className="card-body">
                {/*  */}
            </div>
        </div>
    </ContentWrapper>
  );
};
function breadcrumbs(PageName,IndexRoute=false,IndexPageName=false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item"><NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
