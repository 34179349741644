/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React from "react";
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetDataService } from "../../Services/CrudServices";
import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import { Electric } from "./AccountField/Electric";
import { Water } from './AccountField/Water';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from "../../Components/Shared/LoadingButton";
import { LoadResourceAction, UpdateResourceAction } from "../../Redux/Actions/CrudActions";
import './AssignedWorkOrdersForm.css'

const AssignedWorkOrdersForm = ({ handle, RowData, ID }) => {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "work-orders";

    var original_fields_value = {
        team_id: RowData.team_id,
        work_order_id: RowData.id,
        leader_id: RowData.team ? RowData.worker_id : null,
        worker_id: RowData.team ? null : RowData.worker_id,
        current_status_id: RowData.current_status_id,
        current_level_id: RowData.current_level_id,
        comment: RowData.statuses[RowData.statuses.length - 1].pivot.comment,
        customer_id: String(RowData.customer_request.customer.id),
        status_name:RowData.statuses[RowData.statuses.length - 1].name,
        utility_id: String(RowData.customer_request.service_category.utility.id),
        customer_name: RowData.customer_request.customer.full_name,
        customer_phone: RowData.customer_request.customer.mobile_number,
        customer_email: RowData.customer_request.customer.email,
        customer_address: RowData.customer_request.customer.full_address,
        account_number: String(RowData.customer_request.account_number),
        street: String(RowData.customer_request.customer.street),
        city: String(RowData.customer_request.customer.city),
        state: String(RowData.customer_request.customer.state),
        zip: String(RowData.customer_request.customer.zip),
        reason_code: String(RowData.reason_code ?? ''),
        country: String(RowData.customer_request.customer.country),
    }

    var error_fields = {
        team_id: '',
        worker_id: '',
        current_status_id: '',
        current_level_id: '',
    }

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    const [alllevels, setAllLevels] = React.useState([]);

    const [allstatus, setAllStatus] = React.useState([]);

    const [textArea, setTextArea] = React.useState(false);

    const [errors, setErrors] = React.useState(error_fields);

    const [refreshTable, setRefreshTable] = React.useState(false);

    const [current_utility, set_current_utility] = React.useState('');

    const [accountFieldData, setAccountFieldData] = React.useState({});

    const [formData, setFormData] = React.useState(original_fields_value);

    const [allstatusloading, setAllStatusLoading] = React.useState(false);

    const [alllevelsloading, setAllLevelsLoading] = React.useState(false);

    React.useEffect(() => {


        setAllStatusLoading(true);
        GetDataService('master-work-order-statuses?worker_status=6,7,8').then((res) => {
            setAllStatusLoading(false);
            if (res.success) {
                setAllStatus(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setAllStatus([]);
        });

        GetDataService('service-request-level').then((res) => {
            setAllLevelsLoading(false);
            if (res.success) {
                setAllLevels(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setAllLevels([]);
        });

        setAccountFieldData(RowData.customer_request.customer);

    }, []);



    React.useEffect(() => {
        if (String(formData.utility_id) === "1") {
            set_current_utility('gas');
        }
        if (String(formData.utility_id) === "2") {
            set_current_utility('water');
        }
        if (String(formData.utility_id) === "3") {
            set_current_utility('electric');
        }
        if (String(formData.utility_id) === "4") {
            set_current_utility('gas');
        }
    }, []);

    React.useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    React.useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (state.form.success) {
            setErrors(error_fields);
            setRefreshTable(true);
            handle();
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
    }, [state]);

    const onHandleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    };

    const onHandleAccountFieldChange = (event) => {
        setAccountFieldData({
            ...accountFieldData,
            [event.target.name]: event.target.value
        })
    };

    const HandleReactSelectBox = (selection, action) => {
        if (action.name === 'current_status_id') {
            setTextArea(true);
        }
        setFormData({ ...formData, [action.name]: selection ? selection.value : null });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var data = {
            _method: "put",
            comment: formData.comment,
            current_level_id: formData.current_level_id,
            current_status_id: formData.current_status_id,
            team_id: formData.team_id ? formData.team_id : null,
            worker_id: formData.team_id ? formData.leader_id : formData.worker_id,
            reason_code: formData.reason_code ? formData.reason_code : formData.reason_code,
        }
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <>
            <form className="m-2" onSubmit={handleSubmit}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <legend>Work Order Information</legend>
                    </AccordionSummary>
                    <AccordionDetails>
                        <fieldset>
                            <div className="row">
                                <div className="col-md-2 p-1">
                                    <MUIInputField
                                        label="Work Order #"
                                        value={RowData.id}
                                    />
                                </div>
                                <div className="col-md-3 p-1">
                                    <MUIInputField
                                        label="Service Request"
                                        value={RowData.customer_request.title}
                                    />
                                </div>
                                <div className="col-md-3 p-1">
                                    <MUIInputField
                                        label="Service Request Level"
                                        value={RowData.current_level.name}
                                    />
                                </div>
                                <div className="col-md-4 p-1">
                                    <MUIInputField
                                        label="Request Created On"
                                        value={RowData.customer_request.created_at}
                                    />
                                </div>
                                <div className="col-md-4 p-1">
                                    <MUIInputField
                                        label="Customer Comments"
                                        value={RowData.customer_request.description}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <legend>Customer Information</legend>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-md-12">
                                <fieldset>
                                    <div className="row mb-2">
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="Customer Name"
                                                value={accountFieldData.full_name}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-1">
                                            <MUIInputField
                                                label="Phone Number"
                                                value={accountFieldData.mobile_number}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-5 mb-1">
                                            <MUIInputField
                                                label="Email Address"
                                                value={accountFieldData.email}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <MUIInputField
                                                label="Street Address"
                                                value={accountFieldData.street}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="City"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.city}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="State"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.state}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="Zip"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.zip}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <MUIInputField
                                                label="Country"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.country}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <legend>Account Information</legend>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-md-12">
                                {(current_utility && current_utility === 'electric') && <Electric accountFieldData={accountFieldData} onHandleAccountFieldChange={onHandleAccountFieldChange} />}
                                {(current_utility && current_utility === 'water') && <Water accountFieldData={accountFieldData} onHandleAccountFieldChange={onHandleAccountFieldChange} />}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <legend>Update Work Order Information</legend>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-md-12">
                                <fieldset>
                                    <div className="row mb-2">
                                        <div className="col-md-12">
                                            <div className="row pl-1 pr-1" style={{gap:"10px"}}>
                                                <div className="col-sm-2">
                                                    <span className="font-weight-bold"><u>Current Status:</u></span>
                                                </div>
                                                <div className="col-sm-1 ">
                                                    <span className="badge badge-pill badge-info">{formData.status_name}</span>
                                                </div>
                                                <div className="col-sm-3">
                                                    <span className="font-weight-bold"><u>Current Status Comment:</u></span>
                                                </div>
                                                <div className="col" style={{  maxHeight: "100px", backgroundColor: "#fff2a4" }}>
                                                    <div className="d-inline rounded text-sm px-2 py-1">{formData.comment}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6>Update Work Order Status:</h6>
                                            <Select
                                                name="current_status_id"
                                                options={allstatus}
                                                isLoading={allstatusloading}
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                classNamePrefix="select"
                                                closeMenuOnSelect={true}
                                                placeholder={alllevelsloading ? "Data Loading..." : "Search Status..."}
                                                value={allstatus.map((o) => o.value === formData.current_status_id ? o : '')}
                                                onChange={HandleReactSelectBox}
                                            />
                                            <h6 className="mt-3" style={{ display: textArea ? 'block' : 'none' }}>Status Comment:</h6>
                                            <textarea
                                                name="comment"
                                                cols="40"
                                                className="workOrderEditFormTextarea form-control my-2"
                                                style={{ display: textArea ? 'block' : 'none' }}
                                                onChange={(e) => onHandleChange(e)}
                                            >{formData.comment}</textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Service Request Level:</h6>
                                            <Select
                                                name="current_level_id"
                                                options={alllevels}
                                                isLoading={alllevelsloading}
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder={alllevelsloading ? "Data Loading..." : "Search Level..."}
                                                classNamePrefix="select"
                                                closeMenuOnSelect={true}
                                                value={alllevels.map((o) => o.value === formData.current_level_id ? o : '')}
                                                onChange={HandleReactSelectBox}
                                            />
                                            <h6 className="mt-3">Reason Code:</h6>
                                            <textarea
                                                cols="40"
                                                name="reason_code"
                                                className="form-control my-2"
                                                onChange={(e) => onHandleChange(e)}
                                            >{formData.comment}</textarea>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div className="flex items-center justify-content-end px-2 py-4 assignworkorder-sm-btn">
                    <button className="btn btn-sm mx-1 btn-danger d-sm-block" onClick={() => handle()}>Cancel</button>
                    <LoadingButton className="mx-1 btn-sm" loading={state.form.loading} type="submit">Save Changes</LoadingButton>
                </div>
                <div style={{ marginBottom: "200px" }}></div>
            </form>
        </>
    );
}

export default AssignedWorkOrdersForm;
