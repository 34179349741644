export const removeElementFromArray = (arr, value)   => {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
}

export const checkElementInArray = (arr , value) => {
    const index = arr.indexOf(value);
    if (index > -1) {
        return false;
    } else {
        return true;
    }
}