import React from 'react';
import "./style.css"

export default function ErrorPage({status_code,message}) {
    return (
        <div className="antialiased">
            <div className="relative flex items-top justify-center min-h-screen bg-gray-100 dark:bg-gray-900 sm:items-center sm:pt-0">
                <div className="max-w-xl mx-auto sm:px-6 lg:px-8">
                    <div className="flex items-center pt-8 sm:justify-start sm:pt-0">
                        <div className="px-4 text-lg text-gray-500 border-r border-gray-400 tracking-wider">
                            {status_code}
                        </div>

                        <div className="ml-4 text-lg text-gray-500 uppercase tracking-wider">
                            {message}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
