import React, { useEffect, useState } from "react";
import DropDown from "../../Components/Form/DropDown";
import TextInput  from '../../Components/Form/TextInput';
import LoadingButton from '../../Components/Shared/LoadingButton';
import { GetDataService } from "../../Services/CrudServices";


const SetingsForm = ({handleSubmit,data,setData,SubmitButtonName,errors,btnloading}) => {
    
    const [Customers , setCustomers ] = useState([]);
    const [serviceCategory , setServiceCategory ] = useState([]);
    const [loading , setLoading ] = useState(true);

    useEffect(() => {
        // Get All Customers
        GetDataService('customers').then((res) => {
            setLoading(false);
            setCustomers(res.data);
        }, error => {
            setCustomers([]);
        });
        // Get All service categories
        GetDataService('service-categories').then((res) => {
            setLoading(false);
            setServiceCategory(res.data);
        }, error => {
            setServiceCategory([]);
        });

    }, []);

    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };
    return (
        
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                <DropDown 
                    className="w-full p-2 lg:w-100"
                    label="Customer"
                    name="customer_id"
                    onChange={onHandleChange}
                    value={data.customer_id}
                    loading={loading}
                    required
                >
                    {
                        Customers.map((value,key) => {
                            return(
                                <option key={key} value={value.id}>{value.first_name} {value.last_name}</option>                              
                            )
                        })
                    }
                </DropDown>

                <DropDown 
                    className="w-full p-2 lg:w-100"
                    label="Service Category"
                    name="service_category_id"
                    onChange={onHandleChange}
                    value={data.service_category_id}
                    loading={loading}
                    required
                >
                    {
                        serviceCategory.map( (value,key ) => {
                            return(
                                <option key={key} value={value.id} >{value.name}</option>                              
                            )
                        })
                    }
                </DropDown>

                <TextInput
                    type="text"
                    className="w-full p-2 lg:w-100"
                    label="Title"
                    name="title"
                    value={data.title}
                    onChange={onHandleChange}
                    errors={errors.title}
                    required/>

                <TextInput
                    type="text"
                    className="w-full p-2 lg:w-1/0"
                    label="Description"
                    name="description"
                    value={data.description}
                    errors={errors.description}
                    onChange={onHandleChange}
                    required/>

            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    
    );
}

export default SetingsForm;