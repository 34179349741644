import React from "react";
import MenuItem from '@mui/material/MenuItem';
import { GetDataService } from "../../Services/CrudServices";
import LoadingButton from '../../Components/Shared/LoadingButton';
import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import MUIDropDown from "../../Components/MuiFormComponents/SelectBox";
import MUIInputRadio from '../../Components/MuiFormComponents/InputRadio';
import MUIGroupRadio from '../../Components/MuiFormComponents/InputGroupRadio';
import Select from 'react-select';
const CustomersForm = ({handleSubmit,data,setData,SubmitButtonName,errors,btnloading,hide_password=false}) => {
    
    const [roles, setRoles] = React.useState([]);
    const [utilities, setUtilities] = React.useState([]);
    const [utilitiesLoading, setUtilitiesLoading] = React.useState(false);
    const [locations, setLocations] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selectedUtilityId, setSelectedUtilityId] = React.useState('');
  
 

    // React.useEffect(() => {
    //     GetDataService('roles').then((res) => {
    //         setLoading(false);
    //         setRoles(res.data);
    //     }, error => {
    //         setRoles([]);
    //     });
    // }, []);

    const onHandleRadioChange = (event) => {
        if (event.target.checked) {
            setData({ ...data, [event.target.name]: event.target.value })
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    };


    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };
    console.log(data);
    
    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
            <MUIInputField
                    label="Customer First Name"
                    name="first_name"
                    value={data.first_name}
                    onChange={onHandleChange}
                    validation_error={errors.first_name}
                    required
                />
                <MUIInputField
                    label="Customer Middle Name"
                    name="middle_name"
                    value={data.middle_name}
                    onChange={onHandleChange}
                    validation_error={errors.middle_name}   
                />
                <MUIInputField
                    label="Customer Last Name"
                    name="last_name"
                    value={data.last_name}
                    onChange={onHandleChange}
                    validation_error={errors.last_name}
                    required
                />
                <MUIInputField
                        label="Customer Email"
                        value={data.email}
                        inputProps={{readOnly: false,style:{fontSize: '14px'}}}
                        name="email"
                        validation_error={errors.email}
                        onChange={onHandleChange}
                        required
                    />
                <MUIInputField
                        label="Customer Phone"
                        value={data.mobile_number}
                        inputProps={{readOnly: false,style:{fontSize: '14px'}}}
                        name="mobile_number"
                        validation_error={errors.mobile_number}
                        onChange={onHandleChange}
                        required
                    />

                 <div className="w-100 my-2">
                        <h6>Address:</h6>
                        <hr className="m-0" />
                    </div>
                    <div className="col-md-12">
                        <MUIInputField
                            label="Street"
                            name="street"
                            value={data.street}
                            onChange={onHandleChange}
                            inputProps={{style:{fontSize: '14px'}}}
                            validation_error={errors.street}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <MUIInputField
                            label="City"
                            name="city"
                            value={data.city}
                            onChange={onHandleChange}
                            inputProps={{style:{fontSize: '14px'}}}
                            validation_error={errors.city}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <MUIInputField
                            label="State"
                            name="state"
                            value={data.state}
                            onChange={onHandleChange}
                            inputProps={{style:{fontSize: '14px'}}}
                            validation_error={errors.state}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <MUIInputField
                            label="Zip"
                            name="zip"
                            value={data.zip}
                            onChange={onHandleChange}
                            inputProps={{style:{fontSize: '14px'}}}
                            validation_error={errors.zip}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <MUIInputField
                            label="Country"
                            name="country"
                            value={data.country}
                            onChange={onHandleChange}
                            inputProps={{style:{fontSize: '14px'}}}
                            validation_error={errors.country}
                            required
                        />
                    </div>
                <MUIGroupRadio name="status" label="Customer Status"
                        value={data.status}
                        validation_error={errors.status}
                        onChangeHandler={onHandleRadioChange}
                        required={true}
                    >
                        <MUIInputRadio value="1">Active</MUIInputRadio>
                        <MUIInputRadio value="0">Inactive</MUIInputRadio>
                    </MUIGroupRadio>
                
         
            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default CustomersForm;