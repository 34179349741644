/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { useState } from 'react';
import { useEffect } from 'react';
import { GetDataService } from '../../Services/CrudServices';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    // responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Utlities Wise Completed Work Order Summary',
        },
    },
    maintainAspectRatio : false,
};

const BarChart = () => {

    const [LoadingbarGraphData, setLoadingBarGraphData] = useState(true);
    const [barGraphData, setBarGraphData] = useState([]);

    useEffect(() => {
        GetDataService('utilities').then((res)=>{
            if (res.success) {
                const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
                var created_dataset_array = res.data.map((o) => {
                    return {
                        label:o.name,
                        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                        backgroundColor: o.color_code,
                    }
                })
                var dataset_with_label = {
                    labels,
                    datasets:created_dataset_array
                }
                setBarGraphData(dataset_with_label);
                setLoadingBarGraphData(false);
            }
        },(error)=>{

        });

    }, []);
    return (
        <>
            {
                LoadingbarGraphData 
                ? 'Loading......' 
                : 
                <>
                    <div id="canvas-container">
                        <Bar options={options} data={barGraphData} />
                    </div>
                </>
            }
        </>
    )
}
export default BarChart;