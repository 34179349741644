import React from "react";
import LoadingButton from '../../../Components/Shared/LoadingButton';
import MUIInputField from "../../../Components/MuiFormComponents/InputBox";
import MUIInputRadio from "../../../Components/MuiFormComponents/InputRadio";
import MUIGroupRadio from "../../../Components/MuiFormComponents/InputGroupRadio";
import MUIDropDown from "../../../Components/MuiFormComponents/SelectBox";
import { MenuItem } from '@mui/material';
import { GetDataService } from "../../../Services/CrudServices";

const FieldMasterForm = ({handleSubmit,data,setData,SubmitButtonName,errors,btnloading}) => {
    
    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const [utilities, setUtilities] = React.useState([]);

    const [utilityLoading, setUtilityLoading] = React.useState(false);

    React.useEffect(() => {
        setUtilityLoading(true);
        GetDataService(`utilities`).then((res) => {
            setUtilityLoading(false);
            setUtilities(res.data);
        }, error => {
            setUtilityLoading(false);
            setUtilities([]);
        });
    }, [])

    const HandleReactSelectBox = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const onHandleRadioChange = (event) => {
        if (event.target.checked) {
            setData({ ...data, [event.target.name]: event.target.value})
        } else {
            setData({ ...data, [event.target.name]: event.target.value})
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap my-2" style={{gap:"10px"}}>
                <MUIInputField
                    label="Master Field Name"
                    name="name"
                    value={data.name}
                    onChange={onHandleChange}
                    validation_error={errors.name}
                    required
                />
                <MUIInputField
                    label="Master Field Description"
                    name="description"
                    value={data.description}
                    onChange={onHandleChange}
                    validation_error={errors.description}
                />
                <MUIDropDown
                    label="Utility"
                    name="utility_id"
                    value={utilityLoading ? 'Loading' : data.utility_id}
                    validation_error={errors.utility_id}
                    onChangeHandler={HandleReactSelectBox}
                    loading={utilityLoading}
                >
                    {utilityLoading && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                    {utilities.map((u, i) => <MenuItem key={i} value={u.id}>{u.name}</MenuItem>)}
                </MUIDropDown>
                <MUIGroupRadio name="status" label="Master Field Status" 
                    value={data.status} 
                    validation_error={errors.status} 
                    onChangeHandler={onHandleRadioChange} 
                    required={false}
                >
                    <MUIInputRadio value="1">Enabled</MUIInputRadio>
                    <MUIInputRadio value="0">Disabled</MUIInputRadio>
                </MUIGroupRadio>
            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default FieldMasterForm;