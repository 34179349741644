import React from 'react';
import { NavLink } from "react-router-dom";

const BrandLogo = ({name}) => {
    return (
        <>
            {/* // <!-- Brand Logo --> */}
            <NavLink to="/" className="brand-link brand-bg-color navbar-navy elevation-2 border-0">
                <img src="/dist/img/AdminLTELogo.png" alt="Admin-Logo" className="brand-image img-circle elevation-4"/>
                <span className="brand-text font-weight-normal text-light">{name}</span>
            </NavLink>
        </>
    );
}
export default BrandLogo;