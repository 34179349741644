/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import RequestStatusGird from './Grid';
import RequestStatusCreate from './Create';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SideDrawer from '../../../Components/SideDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { LoadResourceAction } from '../../../Redux/Actions/CrudActions';
export default function RequestStatusIndex(props) {

  const PageName = 'Request Status index  Page';
  const CreateNewButtonName = 'Create New Status';
  const BACKEND_ROUTE = 'master-request-statuses';
  const ACTION_TYPE = "COMMON";

  
  const [tablecolumn, setTablecolumn] = useState({
    columns: [
        { title: 'Id', field: 'id' },
        { title: 'Name', field: 'name' },
       // { title: 'Description', field: 'description' },
        { title: 'Status', field: 'status' },
        { title: 'created At', field: 'created_at' },
        { title: 'updated At', field: 'updated_at' },
        
    ]
});

const [data, setData] = useState([]);
console.log('setDataList22',data);
const [DrawerCreateStatus, setDrawerCreateStatus] = useState(false);

const state = useSelector(state => state.common);

const dispatch = useDispatch();

useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
    return () => {
        controller.abort();
        console.log("Aborted Customer");
    }
}, []);

useEffect(() => {
    if (state.all.success && state.all.list) {
        setData(state.all.list);
        dispatch({type:`RESTART_${ACTION_TYPE}_LIST`});
    }
}, [state]);

const HandleDrawerCreate = () => {
    if (DrawerCreateStatus === false) {
        setDrawerCreateStatus(true);
    } else if (DrawerCreateStatus === true) {
        setDrawerCreateStatus(false);
    }
}


return (
    <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
        <div className="card rounded shadow">
            <SideDrawer 
                status={DrawerCreateStatus} 
                action_heading={CreateNewButtonName} 
                handle={HandleDrawerCreate}>
                {DrawerCreateStatus && <RequestStatusCreate />}
            </SideDrawer>
            <div className="card-body card-body-table">
                <RequestStatusGird
                    HandleDrawerCreate={HandleDrawerCreate }
                    dataList={data}
                    setDataList={setData}
                    columns={tablecolumn}
                    Title={PageName}
                />
            </div>
        </div>
    </ContentWrapper>
);
};
function breadcrumbs(PageName,IndexRoute=false,IndexPageName=false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item"><NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
