import React from 'react';

const DropDown = ({
	label,
	name,
	className,
	children,
	errors = [],
	loading = false,
	...props
}) => {
	return (
		<div className={className}>
			{label && (
				<label className="form-label" htmlFor={name}>
					{label}:
				</label>
			)}
			<select
				id={name}
				name={name}
				{...props}
				className={`form-select block w-full rounded-md  ${errors.length ? 'error' : ''}`}
			>
				{
					(loading === false) ? 
					<>
						<option value="">Select</option>
						{children}
					</> : <>
						<option value="">Loading...</option>
					</>
				}
			
			</select>
			{errors && <div className="form-error">{errors}</div>}
		</div>
	);
};
export default DropDown;