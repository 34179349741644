import HttpService from './HttpService';


export const RegisterUserService = (credentials) => {
    const http = new HttpService();
    let signupUrl = process.env.REACT_APP_BACKEND_REGISTER_ROUTE;
    return http.postData(credentials, signupUrl).then(data => data);
}

export const LoginUserService = (credentials) => {
    const http = new HttpService();
    let loginUrl = process.env.REACT_APP_BACKEND_LOGIN_ROUTE;
    return http.postData(credentials, loginUrl).then(data => data);
}


export const LogOutUserService = () => {
    const http = new HttpService();
    const loginUrl = process.env.REACT_APP_BACKEND_LOGOUT_ROUTE;
    const tokenId = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData([], loginUrl, tokenId).then(data => data);
}

