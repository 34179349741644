import React from 'react';
import { useState } from 'react';
import AvatarMenu from '../AvatarMenu';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Logout, Settings } from '@mui/icons-material';
import { NavLink, useNavigate } from "react-router-dom";
import { LogoutAction } from '../../Redux/Actions/AuthActions';
import { Divider, ListItemIcon, MenuItem } from '@mui/material';


export function getCurrentDateTime(separator = '/') {
    var current_time = new Date();
    let date = current_time.getDate();
    let month = current_time.getMonth() + 1;
    let year = current_time.getFullYear();
    var hours = current_time.getHours() > 12 ? current_time.getHours() - 12 : current_time.getHours();
    var am_pm = current_time.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = current_time.getMinutes() < 10 ? "0" + current_time.getMinutes() : current_time.getMinutes();
    var seconds = current_time.getSeconds() < 10 ? "0" + current_time.getSeconds() : current_time.getSeconds();
    return `${month < 10 ? `<span>0${month}` : `<span>${month}`}${separator}${date}${separator}${year}</span>&nbsp;&nbsp;&nbsp;<span>${hours}:${minutes}:${seconds} ${am_pm}</span>`;
}

export default function Navbar(props) {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const logOut = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(LogoutAction(navigate));
    }
    const profile_state = useSelector(state => state.userDetails);

    const [current_date_time, setCurrentDateTime] = useState(getCurrentDateTime());

    React.useEffect(() => {
        setInterval(() => {
            setCurrentDateTime(getCurrentDateTime())
        }, 1000);
    }, []);

    return (
        <nav className="main-header navbar navbar-expand navbar-navy elevation-2 border-0">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <div className="nav-link" data-widget="pushmenu">
                        <i className="fas fa-bars" style={{cursor:"pointer"}}></i>
                    </div>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <NavLink to="/" className="nav-link">Home</NavLink>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item px-1 text-white mr-1">
                    <div className="mt-2" style={{ fontSize: '20px', color: 'white', textAlign: 'center' }}
                        dangerouslySetInnerHTML={{ __html: current_date_time }} >
                    </div>
                </li>
                <li className="nav-item px-1 text-white">
                    <AvatarMenu title={profile_state.loading === false && profile_state.success ? profile_state.userProfile.name : ""}>
                        <MenuItem>
                           <Avatar sx={{ width: 20, height: 20 }}/>
                           <NavLink to="/profile" > My Profile</NavLink>
                        </MenuItem>
                        <Divider />
                        <MenuItem>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                        <MenuItem onClick={logOut}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </AvatarMenu>
                </li>
                {/* <li className="nav-item px-1">
                    <button className="nav-link btn btn-sm btn-outline-light px-4 logoutBtn" onClick={logOut}>
                        {loading ?
                            <Spinner color='text-dark' />
                            : <div>
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </div>}
                    </button>
                </li> */}
            </ul>
        </nav>
    );
}
