import React from 'react';
import BrandLogo from './BrandLogo';
import SideBarMenu from './SideBarMenu';
import UserHead from './UserHead';

export default function SideBar(props) {
    return (
        <aside className="main-sidebar sidebar-light-navy elevation-4">
            {/* Brand Logo Section*/}
            <BrandLogo name="WOMS" />
            {/* Main Sidebar */}
            <div className="sidebar">
                {/* Sidebar User Section */}
                <UserHead></UserHead>
                {/* Sidebar Menu Section*/}
                <SideBarMenu></SideBarMenu>
            </div>
        </aside>
    );
}
