/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import TeamsForm from "./Form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const TeamsCreate = () => {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "teams";

    const SUBMIT_BUTTOM_NAME = 'Create Team';

    var fields = {
        name: '',
        status: '',
        utility_id: '',
        location_id: '',
        leader_id: '',
        team_members: [],
        utility_service_id: [],
        utility_department_id:[],
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (state.form.success) {
            setData(fields);
            setErrors(fields);
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            setRefreshTable(true);
        }
    }, [state]);


    function handleSubmit(e) {
        e.preventDefault();
        var submitdata = {
            name: data.name,
            status: data.status,
            utility_id:data.utility_id,
            location_id:data.location_id,
            leader_id:data.leader_id,
            team_members:data.team_members,
            utility_service_id:data.utility_service_id,
            utility_department_id:data.utility_department_id,
        }
        dispatch(CreateResourceAction(BACKEND_ROUTE, submitdata, ACTION_TYPE));
    }

    return (
        <TeamsForm
            data={data}
            errors={errors}
            setData={setData}
            handleSubmit={handleSubmit}
            btnloading={state.form.loading}
            SubmitButtonName={SUBMIT_BUTTOM_NAME}
        />
    );

}
export default TeamsCreate;
