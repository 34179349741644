/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React from 'react';
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from './../Spinner';
import { LoadProfileAction } from './../../Redux/Actions/ProfileActions';

export default function UserHead() {
    const dispatch = useDispatch();
    const profile_state = useSelector(state => state.userDetails);

    React.useEffect(() => {
        if (profile_state.userProfile === null) {
            dispatch(LoadProfileAction());
        }
    }, []);

    return (
        <div className="user-panel mt-2 pb-2 mb-2 d-flex">
            <div className="info text-dark">
                <NavLink to="/profile" className={'font-weight-bold'}>
                    {
                    profile_state.loading === false && profile_state.success 
                    ? profile_state.userProfile.name 
                    : <Spinner color="text-navy" />
                    } 
                </NavLink>
            </div>
        </div>
    );
}
