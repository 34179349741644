/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import DepartmentEdit from './Edit';
import MaterialTable from "material-table";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from "../../../../Components/Spinner";
import SideDrawer from '../../../../Components/SideDrawer';
import { createTheme, ThemeProvider } from "@mui/material";
import { UpdateDataService } from "../../../../Services/CrudServices";
import { DestroyResourceAction, LoadResourceAction } from "../../../../Redux/Actions/CrudActions";

const DepartmentGrid = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {

    const { utility_id } = useParams(); 

    const ACTION_TYPE = "COMMON";

    const EDIT_PAGE_NAME = "Edit Department";
        
    const BACKEND_ROUTE = 'utility_departments';

    const LIST_BACKEND_ROUTE = `departments_by_utility/${utility_id}`;

    const theme = createTheme();

    const navigate = useNavigate();
    
    const dispatch = useDispatch();
    
    const state = useSelector(state => state.common);

    const [editId, setEditId] = React.useState(null);
    
    const [refreshTable, setRefreshTable] = React.useState(false);

    const [editDrawerStatus, setEditDrawerStatus] = React.useState(null);

    React.useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(LIST_BACKEND_ROUTE, ACTION_TYPE));
        };
        setRefreshTable(false);
    }, [refreshTable]);

    const HandleEdit = (rowData) => {
        setEditId(rowData.id);
        setEditDrawerStatus(true);
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
        console.log(state);
    }

    const HandleCloseEditDrawer = (event) => {
        if (editDrawerStatus) {
            setEditId(null);
            setEditDrawerStatus(false);
        }
    }

    const HandleActiveInActiveStatus = (rowData) => {
        var update_status_route = `update_resource_status/UtilityDepartment/${rowData.id}`
        var newStatus = rowData.status === 1 ? false : true;
        var data = {'_method': 'put', 'status': newStatus};
        UpdateDataService(update_status_route, data)
        .then((res) => {
            setRefreshTable(true);
        }, error => {
            console.log("error");
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={state.all.loading ? <Spinner />: Title}
                    isLoading={state.all.loading}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            icon: () => <i className="fa-solid fa-landmark"></i>,
                            tooltip: 'Sub-Departments list',
                            onClick: (event, rowData) => navigate(`/utilities/${utility_id}/departments/${rowData.id}/sub_departments`)
                        },
                        {
                            icon: () => <span className="btn btn-sm btn-theme-primary ml-2">Create a Department</span>,
                            tooltip: 'Create a Department',
                            isFreeAction: true,
                            onClick: (event, rowData) => HandleDrawerCreate()
                        },
                        rowData => ({
                            icon: () => (rowData.status) 
                            ? <i className="fa-solid fa-ban text-danger" style={{fontSize: '1.2rem'}}></i> 
                            : <i className="fa-solid fa-ban text-success" style={{fontSize: '1.2rem'}}></i> ,
                            tooltip: (rowData.status) ?  'Deactivate Department' : 'Activate Department',
                            onClick: (event, rowData) => HandleActiveInActiveStatus(rowData),
                        }),
                    ]}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.parent_id)}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...dataList]
                                    data.splice(data.indexOf(oldData), 1);
                                    setDataList(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }}
                />
            </div>
            <SideDrawer action_heading={EDIT_PAGE_NAME}status={editDrawerStatus} handle={HandleCloseEditDrawer} >
                {editId && <DepartmentEdit ID={editId} />}
            </SideDrawer>
        </ThemeProvider>
    );
}
export default DepartmentGrid;