/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Select from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { GetDataService } from "../../Services/CrudServices";
import LoadingButton from '../../Components/Shared/LoadingButton';
import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import MUIDropDown from "../../Components/MuiFormComponents/SelectBox";

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const UsersForm = ({ handleSubmit, data, setData, SubmitButtonName, errors, btnloading, hide_password = false }) => {

    const dispatch = useDispatch();

    const state_show = useSelector(state => state.common);

    const [roles, setRoles] = React.useState([]);

    const [utilities, setUtilities] = React.useState([]);

    const [departments, setDepartments] = React.useState([]);

    const [rolesLoading, setRoleLoading] = React.useState(false);

    const [utilitiesLoading, setUtilitiesLoading] = React.useState(false);

    const [departmentsLoading, setDepartmentsLoading] = React.useState(false);

    const [selectedDepartmentsId, setSelectedDepartmentsId] = React.useState([]);

    React.useEffect(() => {
        setRoleLoading(true);
        GetDataService('roles').then((res) => {
            setRoleLoading(false);
            setRoles(res.data);
        }, error => {
            setRoles([]);
        });
        setUtilitiesLoading(true);
        GetDataService('utilities').then((res) => {
            setUtilities(res.data);
            setUtilitiesLoading(false);
        }, error => {
            setUtilities([]);
        });
    }, []);

    React.useEffect(() => {
        if (data.utility_id) {
            setDepartmentsLoading(true);
            GetDataService(`departments_by_utility/${data.utility_id}`).then((res) => {
                // var datalist = res.data.map(
                //     (o) => {
                //         return {
                //             label: o.name,
                //             options: o.children.map(
                //                 c => {
                //                     return {
                //                         value: c.id,
                //                         label: c.name
                //                     }
                //                 }
                //             )
                //         }
                //     });
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setDepartments(datalist);
                setDepartmentsLoading(false);
            }, error => {
                setDepartments([]);
                setDepartmentsLoading(false);
            });
        }
    }, [data.utility_id]);

    React.useEffect(() => {
        if (state_show.show.success && state_show.show.payload) {
            const fetched_data = state_show.show.payload.data;
            const selected_utility_departments_array = fetched_data.utility_departments.map((o) => { return { value: o.id, label: o.name } });
            setSelectedDepartmentsId(selected_utility_departments_array);
            dispatch({ type: `RESTART_COMMON_SHOW` });
        }
    }, [state_show]);

    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const HandleMutipleSelectForDepartmentBox = (selection, action) => {
        setData({ ...data, [action.name]: selection.map(element => element.value) });
        setSelectedDepartmentsId(selection.map(element => { return { label: element.label, value: element.value } }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                <div className="row w-100">
                    <div className="col-md-12 mt-3">
                        <h6>User Details:</h6>
                        <hr className="m-0" />
                    </div>
                    <div className="col-md-2 my-1">
                        <MUIInputField
                            label="Title"
                            name="title"
                            value={data.title}
                            onChange={onHandleChange}
                            validation_error={errors.title}
                            required
                        />
                    </div>
                    <div className="col-md-5 my-1">
                        <MUIInputField
                            label="First Name"
                            name="first_name"
                            value={data.first_name}
                            onChange={onHandleChange}
                            validation_error={errors.first_name}
                            required
                        />
                    </div>
                    <div className="col-md-5 my-1">
                        <MUIInputField
                            label="Last Name"
                            name="last_name"
                            value={data.last_name}
                            onChange={onHandleChange}
                            validation_error={errors.last_name}
                            required
                        />
                    </div>
                    <div className="col-md-6 my-1">
                        <MUIInputField
                            type='email'
                            label="Emaill Address"
                            name="email"
                            value={data.email}
                            onChange={onHandleChange}
                            validation_error={errors.email}
                            required
                        />
                    </div>
                    <div className="col-md-6 my-1">
                        <MUIInputField
                            label="Phone Number"
                            name="mobile_number"
                            value={data.mobile_number}
                            onChange={onHandleChange}
                            validation_error={errors.mobile_number}
                            required
                        />
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-md-12 mt-3">
                        <h6>Role Assignment:</h6>
                        <hr className="my-1" />
                    </div>
                    <div className="col-md-12">
                        <MUIDropDown
                            label="Roles"
                            name="role_id"
                            value={rolesLoading ? 'Loading' : data.role_id}
                            validation_error={errors.customer_id}
                            onChangeHandler={onHandleChange}
                            loading={rolesLoading}
                            required={true}
                        >
                            {rolesLoading && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                            {roles.map((role, i) => <MenuItem key={i} value={role.id}>{role.name}</MenuItem>)}
                        </MUIDropDown>
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-md-12 mt-3">
                        <h6>Utility and Department Assignment:</h6>
                        <hr className="my-1" />
                    </div>
                    <div className="col-md-12">
                        <MUIDropDown
                            label="Utility"
                            name="utility_id"
                            value={utilitiesLoading ? 'Loading' : data.utility_id}
                            validation_error={errors.loading}
                            onChangeHandler={onHandleChange}
                            loading={utilitiesLoading}
                            required={true}
                        >
                            {utilitiesLoading && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                            {utilities.map((utility, i) => <MenuItem key={i} value={utility.id}>{utility.name}</MenuItem>)}
                        </MUIDropDown>
                    </div>
                    <div className="col-md-12">
                        <Select
                            isMulti
                            id="utility_department_id"
                            name="utility_department_id"
                            options={departments}
                            isLoading={departmentsLoading}
                            isDisabled={false}
                            isClearable={true}
                            isSearchable={true}
                            closeMenuOnSelect={false}
                            placeholder="Select Utility's Department..."
                            classNamePrefix="select"
                            className="w-100 mt-2"
                            value={selectedDepartmentsId}
                            onChange={HandleMutipleSelectForDepartmentBox}
                            formatGroupLabel={formatGroupLabel}
                            required={true}
                        />
                    </div>
                </div>
                {!hide_password &&
                    <div className="row w-100">
                        <div className="col-md-12 mt-3">
                            <h6 className="my-2">Create Password:</h6>
                            <hr className="my-1" />
                        </div>
                        <div className="col-md-6">
                            {!hide_password &&
                                <MUIInputField
                                    type="password"
                                    label="Set New Password"
                                    name="password"
                                    value={data.password}
                                    onChange={onHandleChange}
                                    validation_error={errors.password}
                                    required
                                />}
                        </div>
                        <div className="col-md-6">
                            {!hide_password &&
                                <MUIInputField
                                    type="password"
                                    label="Confirm New Password"
                                    name="password_confirmation"
                                    value={data.password_confirmation}
                                    onChange={onHandleChange}
                                    validation_error={errors.password_confirmation}
                                    required
                                />}
                        </div>
                    </div>}
            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default UsersForm;