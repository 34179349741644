import * as React from 'react';
import TextField from '@mui/material/TextField';

const MUIInputField = ({ label, name, className, value = '', type="text", validation_error = '', variant = "standard", ...props }) => {
    return (
        <>
            <div className='w-100 my-2'>
                <TextField
                    fullWidth
                    id={name}
                    name={name}
                    type={type}
                    label={label}
                    variant={variant}
                    inputProps={{style:{fontSize: '16px'}}}
                    {...String(validation_error ?? '').length ? { error: true } : { error: false }}
                    {...String(validation_error ?? '').length ? { helperText: String(validation_error) } : { helperText: "" }}
                    {...String(value ?? '').length ? { value: String(value ?? '') } : { value: "" }}
                    {...props}
                />
            </div>
        </>
    );
};
export default MUIInputField;