import * as React from 'react';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MuiFullScreenDialog({title,status,handle,children}) {
  return (
    <div>
      <Dialog
        fullScreen
        open={status}
        onClose={handle}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: "#0f3f7d!important" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handle}
              aria-label="close">
              {/* <CloseIcon /> */}
              <i className="fa-solid fa-arrow-left"></i>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color:"#fff!important" }} variant="h6" component="div">
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handle}>close</Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '10' }}> 
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}
