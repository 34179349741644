import React from "react";
import LoadingButton from '../../../../Components/Shared/LoadingButton';
import MUIInputField from "../../../../Components/MuiFormComponents/InputBox";
import MUIInputRadio from "../../../../Components/MuiFormComponents/InputRadio";
import MUIGroupRadio from "../../../../Components/MuiFormComponents/InputGroupRadio";

const DepartmentForm = ({handleSubmit,data,setData,SubmitButtonName,errors,btnloading}) => {
    
    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };
    
    const onHandleRadioChange = (event) => {
        if (event.target.checked) {
            setData({ ...data, [event.target.name]: event.target.value})
        } else {
            setData({ ...data, [event.target.name]: event.target.value})
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                <MUIInputField
                    label="Department Name"
                    name="name"
                    value={data.name}
                    onChange={onHandleChange}
                    validation_error={errors.name}
                    required
                />
                <MUIInputField
                    label="Department Description"
                    name="description"
                    value={data.description}
                    onChange={onHandleChange}
                    validation_error={errors.description}
                    required 
                />
                <MUIGroupRadio name="status" label="Department Status" 
                    value={data.status} 
                    validation_error={errors.status} 
                    onChangeHandler={onHandleRadioChange} 
                    required={false}
                >
                    <MUIInputRadio value="1">Enabled</MUIInputRadio>
                    <MUIInputRadio value="0">Disabled</MUIInputRadio>
                </MUIGroupRadio>
            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default DepartmentForm;