/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import Form from "./Form";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FormSkeleton from "../../Components/Skeleton/FormSkeleton";
import { LoadResourceAction, ShowResourceAction, UpdateResourceAction } from './../../Redux/Actions/CrudActions';

const RequestsEdit = ({ ID }) => {

    const ACTION_TYPE = "COMMON";
    const SubmitButtonName = 'Update Request';
    const BACKEND_ROUTE = "customer-requests";

    var fields = {
        _method: 'PUT',
        title: '',
        customer_id: '',
        department_id: '',
        service_category_id: '',
        description: '',
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(ShowResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success) {
            setErrors(fields);
            setTimeout(() => { dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` }); }, 1000);
            setRefreshTable(true);
        }

        if (state.show.success && state.show.payload) {
            setData({
                "_method": "PUT",
                'customer_id': state.show.payload.data.customer_id,
                'service_category_id': state.show.payload.data.service_category_id,
                'title': state.show.payload.data.title,
                'description': state.show.payload.data.description,
            });
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        }
    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <div>
            {
                state.show.loading ?
                    <FormSkeleton />
                    :
                    <Form
                        btnloading={state.form.loading}
                        handleSubmit={handleSubmit}
                        data={data}
                        setData={setData}
                        SubmitButtonName={SubmitButtonName}
                        errors={errors}
                    />
            }
        </div>
    );
}

export default RequestsEdit;