/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Select from 'react-select';
import { GetDataService } from "../../../../Services/CrudServices";
import LoadingButton from '../../../../Components/Shared/LoadingButton';
import MUIInputField from "../../../../Components/MuiFormComponents/InputBox";
import MUIInputRadio from "../../../../Components/MuiFormComponents/InputRadio";
import MUIGroupRadio from "../../../../Components/MuiFormComponents/InputGroupRadio";

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const ServicesForm = ({ handleSubmit, data, setData, SubmitButtonName, errors, btnloading }) => {

    const [departments, setDepartments] = React.useState([]);

    const [subDepartments, setSubDepartments] = React.useState([]);

    const [departmentsLoading, setDepartmentsLoading] = React.useState(false);

    const [subDepartmentsLoading, setSubDepartmentsLoading] = React.useState(false);

    const [Levels, setLevels] = React.useState([]);

    const [LevelsLoading, setLevelsLoading] = React.useState(false);

    React.useEffect(() => {

        setDepartmentsLoading(true);
        if (data.utility_id) {
            GetDataService(`departments_by_utility/${data.utility_id}`).then((res) => {
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setDepartments(datalist);
                setDepartmentsLoading(false);
            }, error => {
                setDepartments([]);
                setDepartmentsLoading(false);
            });
        }

        setLevelsLoading(true);
        if (data.utility_id) {
            GetDataService(`service-request-level`).then((res) => {
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setLevels(datalist);
                setLevelsLoading(false);
            }, error => {
                setLevels([]);
                setLevelsLoading(false);
            });
        }

    }, [data.utility_id]);

    React.useEffect(() => {
        setSubDepartmentsLoading(true);
        if (data.utility_department_parent_id) {
            GetDataService(`sub_departments_by_department/${data.utility_department_parent_id}`).then((res) => {
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setSubDepartments(datalist);
                setSubDepartmentsLoading(false);
            }, error => {
                setSubDepartments([]);
                setSubDepartmentsLoading(false);
            });
        }
    }, [data.utility_department_parent_id]);

    const onHandleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    };

    const HandleReactSelectBox = (selection, action) => {
        setData({
            ...data,
            [action.name]: selection
                ? selection.value
                : null
        });
    };

    const onHandleRadioChange = (event) => {
        if (event.target.checked) {
            setData({ ...data, [event.target.name]: event.target.value })
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row my-2">
                <div className="col-sm-12">
                    <MUIInputField
                        label="Service Name"
                        name="name"
                        value={data.name}
                        onChange={onHandleChange}
                        validation_error={errors.name}
                        required
                    />
                </div>
                <div className="col-sm-12 my-1">
                    <MUIInputField
                        label="Service Description"
                        name="description"
                        value={data.description}
                        onChange={onHandleChange}
                        validation_error={errors.description}
                        required
                    />
                </div>
                <div className="col-sm-12 my-1">
                    <Select
                        id="utility_department_parent_id"
                        name="utility_department_parent_id"
                        options={departments}
                        isLoading={departmentsLoading}
                        isDisabled={false}
                        isClearable={true}
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        classNamePrefix="select"
                        value={departments.map((o) => o.value === data.utility_department_parent_id && o)}
                        placeholder={"Select Utility Department..."}
                        onChange={HandleReactSelectBox}

                    />
                    <div>{(errors.utility_department_parent_id && errors.utility_department_parent_id.length > 0) ? <span className="text-danger">Please Select Utility Department</span> : ''}</div>
                </div>
                <div className="col-sm-12 my-1">
                    <Select
                        id="utility_department_id"
                        name="utility_department_id"
                        options={subDepartments}
                        isLoading={subDepartmentsLoading}
                        isDisabled={false}
                        isClearable={true}
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        classNamePrefix="select"
                        value={subDepartments.map((o) => o.value === data.utility_department_id && o)}
                        placeholder={"Select Utility Sub Department..."}
                        onChange={HandleReactSelectBox}

                    />
                    <div>{(errors.utility_department_id && errors.utility_department_id.length > 0) ? <span className="text-danger">Please Select Utility Sub Department</span> : ''}</div>
                </div>
                <div className="col-sm-12 my-1">
                    <Select
                        id="level_id"
                        name="level_id"
                        options={Levels}
                        isLoading={LevelsLoading}
                        isDisabled={false}
                        isClearable={true}
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        placeholder="Select Service Request Level..."
                        classNamePrefix="select"
                        className="w-100 my-2"
                        value={Levels.map((o) => o.value === data.level_id && o)}
                        onChange={HandleReactSelectBox}
                    />
                    <div>{(errors.level_id && errors.level_id.length > 0) ? <span className="text-danger">Please Select Utility</span> : ''}</div>
                </div>
                <div className="col-sm-12">
                    <MUIGroupRadio name="status" label="Service Status"
                        value={data.status}
                        validation_error={errors.status}
                        onChangeHandler={onHandleRadioChange}
                        required={false}
                    >
                        <MUIInputRadio value="1">Enabled</MUIInputRadio>
                        <MUIInputRadio value="0">Disabled</MUIInputRadio>
                    </MUIGroupRadio>
                </div>
            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default ServicesForm;