import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { GetDataService } from "../../Services/CrudServices";

const InfoBox = () => {

    // const WORK_ORDER_BACKEND_ROUTE = "work-orders";

    const [workOrderCount, setWorkOrderCount] = useState();

    const [customerCount, setCustomerCount] = useState();

    const [utilitiesCount, setUtilitiesCount] = useState();

    // const [workOrderCount, setWorkOrderCount] = useState();

    React.useEffect(() => {

        GetDataService('work-orders').then((res) => {
            if (res.success) {
                // console.log(res.data.length);
                setWorkOrderCount(res.data.length);
            }
        }, (error) => { });

        GetDataService('customers').then((res) => {
            if (res.success) {
                // console.log(res.data.length);
                setCustomerCount(res.data.length);
            }
        }, (error) => { });

        GetDataService('utilities').then((res) => {
            if (res.success) {
                // console.log(res.data.length);
                setUtilitiesCount(res.data.length);
            }
        }, (error) => { });

    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-3">
                        <Link to="#">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-purple elevation-1">
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Pending Work Orders</span>
                                    <span className="info-box-number">2</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <Link to="/workorders">
                            <div className="info-box">
                                <span className="info-box-icon bg-info elevation-1">
                                    <i className="fa-solid fa-arrow-down-short-wide"></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Completed Work Orders</span>
                                    <span className="info-box-number">{workOrderCount}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <Link to="/customers">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-teal elevation-1">
                                    <i className="fa-solid fa-users-gear"></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Customers</span>
                                    <span className="info-box-number">{customerCount}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <Link to="/utilities">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-primary elevation-1">
                                    <i className="fa-solid fa-screwdriver-wrench"></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Utilities</span>
                                    <span className="info-box-number">{utilitiesCount}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InfoBox;