import React, { useState } from 'react'

export default function DynTextInputView({ index, input_props, editInput, updateInputProps }) {

    const { name, label, placeholder } = input_props;
    const [defaultValueView, setDefaultValueView] = useState("");

    return (
        <>
            <div className="form-group mb-0">
                <label>{label}</label>
                <input type="email" className="form-control form-control-sm" placeholder={placeholder} value={defaultValueView} onChange={(e) => setDefaultValueView('')} />
            </div>
        </>
    )
}
