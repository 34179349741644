import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

const  SideBarMenuItem = ({ routeName, icon, name, children }) => {
    const [MenuOpen, SetMenuOpen] = useState(false);

    function ToggleTreeMenu(e) {
        if (MenuOpen) SetMenuOpen(false);
        if (!MenuOpen) SetMenuOpen(true);
    }
    
    return (
        <>
            <li className={`nav-item ${children ? 'has-treeview' : ''} ${MenuOpen === true && children ? 'menu-open' : ''}`}>
                {
                    children ? <>
                        <div className="nav-link" onClick={() => ToggleTreeMenu()}>
                            <i className={`nav-icon ${icon}`}></i>
                            <p>{name}<i className="fas fa-angle-left right"></i></p>
                        </div>
                    </> : <>
                        {/* {...routeName ? { href: route(routeName) } : { href: "#" }} */}
                        <NavLink className={"nav-link menuItemName"} {...routeName ? { to: routeName } : { to: "/" }} title={name}>
                            <i className={`nav-icon ${icon}`}></i>
                            <p className='menuItemName'>{name}</p>
                        </NavLink>
                    </>
                }
                {children && <ul className={`nav nav-treeview ${MenuOpen === true ? 'd-menu-block' : ''}`}>{children}</ul>}
            </li>
        </>
    );
};

export default SideBarMenuItem;