import Form from "./Form";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from 'react-router-dom';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';
import MasterFormForm from "./Form";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './CreateMasterForm.css';
import MasterFormDragItem from "./MasterFormDragItem";
import MasterFormSectionItem from "./MasterFormSectionItem";
import MFCard from "./components/card/MFCard";
import MFCardHeader from "./components/card/MFCardHeader";
import MFCardBody from "./components/card/MFCardBody";
import MUIInputField from "../../../Components/MuiFormComponents/InputBox";
import { MenuItem, Select } from "@mui/material";
import { GetDataService } from "../../../Services/CrudServices";
import MUIDropDown from "../../../Components/MuiFormComponents/SelectBox";
import { searchObj } from "../../../utils";

const CreateMasterForm = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [new_section, set_new_section] = React.useState(false);

    const IndexPageName = 'Master form';
    const PageName = 'Create New Master Form';
    const SubmitButtonName = 'Create Master Form';

    const IndexRoute = '/masterform';

    var fields = {
        form_name: '',
        utility_id: '',
        utility_service_id: ''
    }

    const [FORM_DATA, setFORM_DATA] = useState(fields);

    const [data, setData] = useState({
        name: '',
        description: '',
        date: '',
    });

    const [form_sections, set_form_sections] = useState([])
    //     {id: 1, name: "Basic Info"},
    //     {id: 2, name: "Customer Info"},
    //     {id: 3, name: "Meter Info"},
    // ])
    let [master_fields, set_master_fields] = useState([
        { id: 1, name: "Account" },
        { id: 2, name: "Services" },
    ])
    let [current_fields, set_current_fields] = useState([])

    const SUBMIT_BUTTON_NAME = 'Create a Master Form';

    const [utilityLoading, setUtilityLoading] = useState(false);
    const [utilities, setUtilities] = useState([]);

    const [utilityServiceLoading, setUtilityServiceLoading] = useState(false);
    const [utilityServices, setUtilityServices] = useState([]);

    const [errors, setErrors] = useState(fields);

    const state = useSelector(state => state.common);

    useEffect(() => {
        setUtilityLoading(true);
        GetDataService(`utilities`).then((res) => {
            setUtilityLoading(false);
            setUtilities(res.data);
        }, error => {
            setUtilityLoading(false);
            setUtilities([]);
        });
    }, [])

    useEffect(() => {
        if (FORM_DATA.utility_id) {
            setUtilityServiceLoading(true);
            GetDataService(`utilities/${FORM_DATA.utility_id}`).then((res) => {
                setUtilityServiceLoading(false);
                setUtilityServices(res.data.services)
            }, error => {
                setUtilityServiceLoading(false);
                setUtilityServices([]);
            });
        }
    }, [FORM_DATA.utility_id]);

    useEffect(() => {
        console.log(FORM_DATA)
    }, [FORM_DATA])

    const HandleReactSelectBox = (event) => {
        setFORM_DATA({
            ...FORM_DATA,
            [event.target.name]: event.target.value
        })
    };

    function handleSubmit(e) {
        e.preventDefault();
        // console.log(data);
    }

    const onDragStart = (e) => {
        console.log('DragStart');
        console.log('e.target.id', e.target.getAttribute('data-id'));
        e.dataTransfer.setData("data-id", e.target.getAttribute('data-id'));
    }

    const onDragEnter = (e) => {
        console.log('onDragEnter')
        e.stopPropagation();
    }
    const onDragOver = (e) => {
        console.log('onDragOver')
        e.stopPropagation();
        e.preventDefault();
    }

    const onDropInCurrentFields = (e) => {
        let _active_section = active_section;
        console.log('onDropInCurrentFields')
        console.log('onDropInCurrentFields active_section', active_section)
        if (active_section) {
            set_active_section(false)
            let data_id = e.dataTransfer.getData("data-id")
            let filtered_arr = (master_fields.filter((mf) => mf.id == data_id))
            if (filtered_arr.length > 0) {
                let obj = filtered_arr[0]
                let obj_index = master_fields.findIndex((mf) => mf.id == data_id)
                master_fields.splice(obj_index, 1)
                // let _active_section = active_section;

                let form_sections_index = form_sections.findIndex((s) => s.id !== active_section, active_section)
                form_sections[form_sections_index].fields.push(obj)
                let _form_sections = form_sections;
                set_form_sections(_form_sections);
                // active_section.fields.push(obj);
                // let _active_section = form_sections[form_sections_index]
                // console.log('_active_section', _active_section);

                e.stopPropagation();
            }
        }
        set_active_section(_active_section)
    }
    const onDropInMasterFields = (e) => {
        console.log('onDropInMasterFields')

        let data_id = e.dataTransfer.getData("data-id")
        let filtered_arr = (current_fields.filter((mf) => mf.id == data_id))
        if (filtered_arr.length > 0) {
            let obj = filtered_arr[0]
            let obj_index = current_fields.findIndex((mf) => mf.id == data_id)
            current_fields.splice(obj_index, 1)
            set_master_fields([...master_fields, obj])

            e.stopPropagation();
        }
    }


    const [sections_counter, set_sections_counter] = useState(3);
    const [section_name, set_section_name] = useState('');
    const [active_section, set_active_section] = useState(false);

    const change_section_name = (e) => set_section_name(e.target.value)

    useEffect(() => console.log('useEffect form_sections', form_sections), [form_sections])
    useEffect(() => console.log('useEffect active_section', active_section), [active_section])

    const addSection = () => {
        let _sections_counter = sections_counter + 1;
        set_sections_counter(_sections_counter);
        let sec = {
            id: _sections_counter,
            name: section_name,
            fields: []
        }
        set_form_sections([...form_sections, sec])
        setModalShow(false)
        set_section_name('')
    }
    const deleteSection = (e, section) => {
        console.log('deleteSection section', section);
        let _form_sections = form_sections.filter((s) => s.id !== section.id, section)
        console.log('deleteSection _form_sections', _form_sections)
        set_form_sections(_form_sections);
    }

    const section_selected = (e, section) => {
        console.log('section_selected active_section', active_section)
        console.log('section_selected section', section)
        let nextSibling = e.target.parentElement.parentElement.nextElementSibling
        while (nextSibling) {
            // nextSibling.classList.remove("bg-info")
            nextSibling.style.backgroundColor = "#fff"
            nextSibling = nextSibling.nextElementSibling;
        }
        let previousSibling = e.target.parentElement.parentElement.previousElementSibling
        while (previousSibling) {
            // previousSibling.classList.remove("bg-info")
            previousSibling.style.backgroundColor = "#fff"
            previousSibling = previousSibling.previousElementSibling;
        }
        e.target.parentElement.parentElement.style.backgroundColor = "#e1f4fb"
        // e.target.parentElement.parentElement.classList.toggle("bg-info")
        // set_current_fields_cardname(section.name + ' Fields')
        // let section_current_fields_arr = current_fields.filter((cf) => cf.section.id == section.id, section)
        // console.log('section_current_fields_arr', section_current_fields_arr);
        let form_sections_index = form_sections.findIndex((s) => s.id == section.id, section)
        console.log(form_sections_index);
        set_active_section(section.id)
    }

    const [set_input_fields, setInputFields] = useState(null);
    const open_properties = (e, section) => {
        // alert("hello");

        console.log(section.id);

        setInputFields(section.id);
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName, IndexRoute, IndexPageName)} >

            <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Section
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="mb-3">
                        <label for="" class="form-label">Name</label>
                        <input type="text" onChange={(e) => change_section_name(e)} class="form-control" id="" value={section_name} placeholder="Enter Section Name" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={addSection}>Add</Button>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{PageName}</h3>
                </div>
                <div className="card-body pt-2">
                    <div className="row">
                        <div className="col-sm-12 p-0 border-bottom mb-2">
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <div className="">
                                        <MUIInputField
                                            label="Form Name"
                                            name="form_name"
                                            value={FORM_DATA.form_name}
                                            onChange={HandleReactSelectBox}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mt-2">
                                        <MUIDropDown
                                            label="Utility"
                                            name="utility_id"
                                            value={utilityLoading ? 'Loading' : FORM_DATA.utility_id}
                                            validation_error={errors.utility_id}
                                            onChangeHandler={HandleReactSelectBox}
                                            loading={utilityLoading}
                                            required={true}
                                        >
                                            {utilityLoading && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                                            {utilities.map((u, i) => <MenuItem key={i} value={u.id}>{u.name}</MenuItem>)}
                                        </MUIDropDown>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mt-2">
                                        <MUIDropDown
                                            label="Utility Service"
                                            name="utility_service_id"
                                            value={utilityServiceLoading ? 'Loading' : FORM_DATA.utility_service_id}
                                            validation_error={errors.utility_service_id}
                                            onChangeHandler={HandleReactSelectBox}
                                            loading={utilityServiceLoading}
                                            required={true}
                                        >
                                            {utilityServiceLoading && <MenuItem value={"Loading"}>Loading...</MenuItem>}
                                            {utilityServices.map((u, i) => <MenuItem key={i} value={u.id}>{u.name}</MenuItem>)}
                                        </MUIDropDown>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mt-4">
                                        <input type="submit" className="btn btn-md btn-primary mr-2" value="Save" />
                                        <input type="button" className="btn btn-md btn-warning mr-2" value="Reset" />
                                        <input type="button" className="btn btn-md btn-danger" value="Cancel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 p-0">
                            <div className="row">
                                <div className="col-sm-3 p-1">
                                    <MFCard>
                                        <MFCardHeader>
                                            <p className="p-0 m-0">Section</p>
                                            <div className="btn-group ml-auto">
                                                <i className="fas fa-add text-success" onClick={() => setModalShow(true)} />
                                            </div>
                                        </MFCardHeader>
                                        <MFCardBody>
                                            {form_sections.map((section) =>
                                                <MasterFormSectionItem
                                                    section={section}
                                                    section_selected={(e) => section_selected(e, section)}
                                                    deleteSection={(e) => deleteSection(e, section)}
                                                />
                                            )}
                                        </MFCardBody>
                                    </MFCard>
                                </div>
                                <div className="col-sm-2 p-1">
                                    <MFCard>
                                        <MFCardHeader>Master Fields</MFCardHeader>
                                        <MFCardBody onDragEnter={(e) => onDragEnter(e)}
                                            onDragOver={(e) => onDragOver(e)}
                                            onDrop={(e) => onDropInMasterFields(e)}
                                        >
                                            {
                                                master_fields.map((section) => <MasterFormDragItem
                                                    section={section}
                                                    draggable={true}
                                                    onDragStart={(e) => onDragStart(e)}
                                                    type="master"
                                                />
                                                )
                                            }
                                        </MFCardBody>
                                    </MFCard>
                                </div>
                                <div className="col-sm-2 p-1">
                                    <MFCard>
                                        <MFCardHeader>
                                            {
                                                active_section
                                                    ? (form_sections.filter((fs) => fs.id == active_section, active_section))[0].name
                                                    : 'Current'
                                            } Fields
                                        </MFCardHeader>
                                        <MFCardBody onDragEnter={(e) => onDragEnter(e)}
                                            onDragOver={(e) => onDragOver(e)}
                                            onDrop={(e) => onDropInCurrentFields(e)}
                                        >
                                            {
                                                active_section ?
                                                    (form_sections.filter((fs) => {
                                                        console.log(fs)
                                                        return fs.id == active_section
                                                    }, active_section))[0].fields.map((ac) => <MasterFormDragItem
                                                        section={ac}
                                                        draggable={true}
                                                        onDragStart={(e) => onDragStart(e)}
                                                        type="dropped"
                                                        open_properties={(e) => open_properties(e, ac)}
                                                    />
                                                    )
                                                    : <></>
                                            }
                                        </MFCardBody>
                                    </MFCard>
                                </div>
                                <div className="col-sm-5 p-1">
                                    <MFCard>
                                        <MFCardHeader>Set Field Type &amp; Properties</MFCardHeader>
                                        <MFCardBody>
                                            {
                                                set_input_fields ?? 'working'
                                            }
                                            <h1>Input</h1>

                                        </MFCardBody>
                                    </MFCard>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-12">
                            <h3>Form Preview</h3>

                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
}
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item"><NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
export default CreateMasterForm;
