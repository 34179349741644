import AppRoutes from './Routes';
import {BrowserRouter} from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import AppAlerts from './Components/NotifyAlert';

const App = () => {

  const profile_state = useSelector(state => state.userDetails);

  useEffect(() => {
      if (profile_state.permissions.length > 0) {
          localStorage.setItem('permissions', profile_state.permissions);
      }
  }, [profile_state])
  
  return (
    <BrowserRouter>
      <AppAlerts/>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
