import React from 'react';
import './SideDrawer.css'
import { Box } from '@mui/system';
import { grey } from '@mui/material/colors';
import Close from '@mui/icons-material/Close';
import { SwipeableDrawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export default function SideDrawer({ action_heading="", children, status, handle, layout = "sm" ,viewsize = '30vw'}) {

    return (
        <>

            {/* <div id="side_popup_overlay" className={`${status === true ? 'd-block' : 'd-none'}`} /> */}
            {/* <div id="side_popup_div" className={layout} style={status === true ? {marginRight:0,transition: 'margin-right 0.5s ease-in'} : {transition: 'margin-right 0.5s ease-in'}} >
                
                
                
                <div className="side_popup_div_heading d-flex justify-content-between align-items-center p-1 pl-3 pr-3">
                    <h5 className="m-0">
                        <div dangerouslySetInnerHTML={{ __html: action_heading }} /></h5>
                    <IconButton color="primary" component="label" id="close_side_popup_div" onClick={handle}>
                        <Close sx={{ color: grey[900] }} />
                    </IconButton>
                </div>
                <hr className="m-0" />
                <div id="side_popup_div_content" className="pl-3 pr-3 pb-5">
                    {children}
                </div>
            </div> */}

            <SwipeableDrawer
                anchor="right"
                open={status}
                onClose={handle}
                onOpen={handle}
                >
                <Box role="presentation" 
                    sx={
                        { 
                            width: {
                                xs: '100vw', // theme.breakpoints.up('xs')
                                sm: '100vw', // theme.breakpoints.up('sm')
                                md: viewsize, // theme.breakpoints.up('md')
                                lg: viewsize, // theme.breakpoints.up('lg')
                                xl: viewsize, // theme.breakpoints.up('xl')
                            } 
                        }
                    }
                >
                    <div className="side_popup_div_heading d-flex justify-content-between align-items-center p-1 pl-3 pr-3">
                        <h5 className="m-0" dangerouslySetInnerHTML={{ __html: action_heading }} ></h5>
                        <IconButton color="primary" component="label" onClick={handle}>
                            <Close sx={{ color: grey[900] }} />
                        </IconButton>
                    </div>
                    <hr className="m-0" />
                    <div className="pl-3 pr-3 pb-5">
                        {children}
                    </div>
                </Box>
            </SwipeableDrawer>
        </>
    );
}
