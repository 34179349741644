/* eslint-disable react-hooks/exhaustive-deps */
import UserEdit from './Edit';
import MaterialTable from "material-table";
import {isMobile} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Spinner } from '../../Components/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import SideDrawer from './../../Components/SideDrawer';
import { createTheme, ThemeProvider } from "@mui/material";
import { UpdateDataService } from '../../Services/CrudServices';
import { DestroyResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const UsersGrid = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {
    
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "users";
    const theme = createTheme();

    const [id, setID] = React.useState(null);

    const [refreshTable, setRefreshTable] = React.useState(false);

    const [editDrawerStatus, setEditDrawerStatus] = React.useState(null);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const permisions = localStorage.getItem('permissions');
    var fields={}
    const [data, setData] = useState(fields);
    React.useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        };
        setRefreshTable(false);
    }, [refreshTable]);


    const HandleEditDrawer = (event) => {
        if (editDrawerStatus) {
            setID(null);
            setEditDrawerStatus(false);
        }
    }
    const HandleEdit = (rowData) => {
        setID(rowData.id);
        setEditDrawerStatus(true);
    }
    
    const HandleEditPermission = (rowData) => {
        setID(rowData.id);
        navigate('user-permission');
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
        console.log(state);
    }

    const HandleActiveInActiveStatus = (rowData) => {
        var update_status_route = `update_resource_status/User/${rowData.id}`
        var newStatus = rowData.status === 1 ? false : true;
        var data = {'_method': 'put', 'status': newStatus};
        UpdateDataService(update_status_route, data)
        .then((res) => {
            setRefreshTable(true);
        }, error => {
            console.log("error");
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={isMobile ? '' : Title}
                    isLoading={state.all.loading}
                    {...data && {isLoading:state.all.loading}}
                    actions={[
                        {
                            hidden:permisions.includes('edit_user') ? false : true,
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            hidden:permisions.includes('edit_user_permission') ? false : true,
                            icon: () => <i className="fa-solid fa-gear"></i>,
                            tooltip: 'Edit Permission',
                            onClick: (event, rowData) => navigate(`/users/${rowData.id}/rights`)
                        },
                        {
                            hidden:permisions.includes('create_user') ? false : true,
                            icon: () => (isMobile)
                            ?<i className="fa-solid fa-plus theme-text-color-primary" style={{fontSize: '1.2rem'}}></i>
                            :<span className="btn btn-sm btn-theme-primary ml-2">Create a User</span>,
                            tooltip: 'Create a User',
                            isFreeAction: true,
                            onClick: (event, rowData) => HandleDrawerCreate()
                        },
                        rowData => ({
                            hidden:permisions.includes('deactivate_user') ? false : true,
                            icon: () => (rowData.status) 
                            ? <i className="fa-solid fa-ban text-danger" style={{fontSize: '1.2rem'}}></i> 
                            : <i className="fa-solid fa-ban text-success" style={{fontSize: '1.2rem'}}></i> ,
                            tooltip: (rowData.status) ?  'Deactivate User' : 'Activate User',
                            onClick: (event, rowData) => HandleActiveInActiveStatus(rowData),
                        }),
                    ]}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...dataList]
                                    data.splice(data.indexOf(oldData), 1);
                                    setDataList(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }}
                    options={{
                        actionsColumnIndex: 0
                    }}
                />
            </div>
            <SideDrawer layout= "md"
                status={editDrawerStatus} 
                handle={HandleEditDrawer} 
                action_heading={"Edit User"}>
                {id && <UserEdit ID={id} />}
            </SideDrawer>
        </ThemeProvider>
    );
}

export default UsersGrid;