/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
// import WorkOrdersGrid from './Grid';
import { NavLink } from 'react-router-dom';
import MaterialTable from "material-table";
import Badge from '../../Components/Badge';
import MuiModal from '../../Components/MuiModal';
import React, { useEffect, useState } from 'react';
import CustomerModal from './Partials/CustomerModal';
import SideDrawer from '../../Components/SideDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from "@mui/material";
import AssignedWorkOrdersForm from './AssignedWorkOrdersForm';
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';
import MuiFullScreenDialog from './../../Components/FullScreenDialog';

export default function WorkOrdersIndex() {

    const ACTION_TYPE = "COMMON";

    const PageName = 'Assigned Work Orders';

    const BACKEND_ROUTE = "assigned-work-orders";

    const theme = createTheme();

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    const [data, setData] = useState([]);

    const [workdata, setWorkData] = useState([]);

    const [id, setID] = React.useState(false);

    const [rowData, setRowData] = React.useState([]);

    const permisions = localStorage.getItem('permissions');

    const [openSideDrawer, setOpenSideDrawer] = React.useState(false);

    const [DialogName, setDialogName] = useState("Work Order Details");

    const [openCustomerModal, setOpenCustomerModal] = React.useState(false);

    const [openFullScreenDialog, setOpenFullScreenDialog] = React.useState(false);

    const [tablecolumn, setTablecolumn] = useState([
        {
            title: 'WO#',
            field: 'id',
            width: 10,
        },
        {
            title: 'Created On', field: 'created_at',
            render: rowData => <span className='font-weight-bold'>{rowData.created_at}</span>
        },

        {
            title: 'Current Status',
            field: "current_status.name",
            render: rowData => <Badge data={rowData.current_status.name} bgColor={rowData.current_status.color_code} textColor={rowData.current_status.text_color_code} />
        },
        {
            title: 'Utility',
            field: "customer_request.service_category.utility.name",
            render: rowData => <Badge bgColor={rowData.customer_request.service_category.utility.color_code} data={rowData.customer_request.service_category.utility.name} />
        },
        {
            title: 'Level',
            field: "current_level.name",
            render: rowData => <Badge bgColor={rowData.current_level.color_code} data={rowData.current_level.name} />
        },
        {
            title: 'Service',
            field: "customer_request.service_category.name",
            render: rowData => <span>{rowData.customer_request.service_category.name}</span>
        },
        {
            title: 'Account',
            field: "customer_request.account_number",
            render: rowData =>
                <a style={{ cursor: 'pointer' }}
                    className="text-link"
                    onClick={() => handleOpenCustomerModal(rowData)} >
                    <u>{rowData.customer_request.account_number}</u>
                </a>
        },
        { title: 'Cust. Name', field: 'customer_request.customer.full_name' },
        { title: 'Cust. Mobile No.', field: 'customer_request.customer.mobile_number' },
        { title: 'Cust. Address', field: 'customer_request.customer.street' },
        {
            title: 'Comments',
            field: "customer_request.short_desc",
            render: rowData => <span className='text-center' title={rowData.customer_request.description}>{rowData.customer_request.short_desc ?? '-'}</span>
        },
        { title: 'Updated On', field: 'updated_at' },
    ]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);
    
    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);

    const handleOpenCustomerModal = (rowData) => {
        setRowData(rowData);
        setOpenCustomerModal(true);
    };
    const HandleAssignedWOFormOpen = (rowData) => {
        setID(rowData.id);
        setRowData(rowData);
        setDialogName("Work Order Form <span class='badge badge-pill badge-info'>" + rowData.current_status.name + "</span>");
        setOpenSideDrawer(true);
    }
    const HandleAssignedWOFormClose = (event) => {
        if (openSideDrawer) {
            setID(null);
            setRowData([]);
            setOpenSideDrawer(false);
        }
    }

    const HandleOpenFullScreenDialog = (rowData) => {
        if (!openFullScreenDialog) {
            setID(rowData.id);
            setRowData(rowData);
            setOpenFullScreenDialog(true);
        }
    }
    const HandleCloseFullScreenDialog = () => {
        if (openFullScreenDialog) {
            setID(null);
            setRowData({});
            setOpenFullScreenDialog(false);
        }
    }
    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
            <div className="card rounded">
                <div className="card-body p-0">
                    <ThemeProvider theme={theme}>
                        <div style={{ maxWidth: '100%' }} className="text-sm">
                            <MaterialTable
                                data={data}
                                title={PageName}
                                columns={tablecolumn}
                                isLoading={state.all.loading}
                                {...workdata && { isLoading: state.all.loading }}
                                actions={[
                                    {
                                        hidden: permisions.includes('work_order_form_technician') ? false : true,
                                        icon: () => <i className="fa-solid fa-pen-to-square"></i>,
                                        tooltip: 'Open Side Window Work Order',
                                        onClick: (event, rowData) => HandleAssignedWOFormOpen(rowData)
                                    },
                                    // {
                                    //     icon: () => <i className="fa-solid fa-square-pen"></i>,
                                    //     tooltip: 'Open Full Screen Work Order',
                                    //     onClick: (event, rowData) => HandleOpenFullScreenDialog(rowData)
                                    // },
                                    {
                                        hidden: permisions.includes('work_order_form_technician') ? false : true,
                                        icon: () => <i className="fa-solid fa-circle-info"></i>,
                                        tooltip: 'Cutomer Account Information',
                                        onClick: (event, rowData) => handleOpenCustomerModal(rowData)
                                    }
                                ]}
                            />
                        </div>
                        {/* {openCustomerModal && <CustomerModal openCustomerModal={openCustomerModal} setOpenCustomerModal={setOpenCustomerModal} RowData={rowData} />}
                        {openSideDrawer && <SideDrawer action_heading={DialogName} layout={'lg'} status={openSideDrawer} handle={HandleAssignedWOFormClose} content={<AssignedWorkOrdersForm handle={HandleAssignedWOFormClose} RowData={rowData} ID={id} />} />}
                        {openFullScreenDialog && <FullScreenDialog title={"Customer Work Order"} status={openFullScreenDialog} handle={HandleCloseFullScreenDialog} content={<AssignedWorkOrdersForm handle={HandleCloseFullScreenDialog} RowData={rowData} ID={id} />} />}
                    
                     */}
                    
                        <SideDrawer 
                            layout={'lg'} 
                            viewsize={'70vw'}
                            status={openSideDrawer} 
                            action_heading={DialogName} 
                            handle={HandleAssignedWOFormClose}>
                            {openSideDrawer && <AssignedWorkOrdersForm handle={HandleAssignedWOFormClose} RowData={rowData} ID={id} />}
                        </SideDrawer>
                        
                        <MuiModal 
                            title={"Customer Account Information"} 
                            button_name={"Save Changes"} 
                            openCustomerModal={openCustomerModal} 
                            setOpenCustomerModal={setOpenCustomerModal}>
                            {openCustomerModal && <CustomerModal rowData={rowData} />}
                        </MuiModal>

                        <MuiFullScreenDialog 
                            title={"Customer Work Order"}
                            status={openFullScreenDialog}
                            handle={HandleCloseFullScreenDialog} >
                            {openFullScreenDialog && <AssignedWorkOrdersForm handle={HandleCloseFullScreenDialog} RowData={rowData} ID={id} />}
                        </MuiFullScreenDialog>
                    </ThemeProvider>
                </div>
            </div>
        </ContentWrapper>
    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
