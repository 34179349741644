import { NavLink } from 'react-router-dom';
import MaterialTable from "material-table";
import Badge from '../../Components/Badge';
import React, { useEffect, useState } from 'react';
import { Spinner } from './../../Components/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from "@mui/material";
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';
import { isMobile } from 'react-device-detect';

export default function CompletedWorkOrder() {

    const ACTION_TYPE = "COMMON";

    const PAGE_NAME = 'Completed Work Orders';

    const permisions = localStorage.getItem('permissions');

    const BACKEND_ROUTE = "completed-work-orders";

    const theme = createTheme();

    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    const state = useSelector(state => state.common);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);

    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            {
                title: 'WO#',
                field: 'id'
            },
            {
                title: 'Completed On',
                render: rowData => <span className='font-weight-bold'>{String(rowData.statuses[rowData.statuses.length - 1].pivot.created_at  ?? '-')}</span>
            },
            {
                title: 'Work Assignment',
                render: rowData => {
                    console.log(rowData.team);
                    if (rowData.team) {
                        return <div>
                            <div><h6 className='m-0'>Team</h6></div>
                            <div>Team Name: {rowData.team.name}</div>
                            <div>Team Leader Name: {rowData.team_leader.name}</div>
                        </div>
                    } else if (rowData.worker) {
                        return <div>
                            <div><h6 className='m-0'>Single Person</h6></div>
                            <div>Technician Name: {rowData.worker.name}</div>
                        </div>
                    } else {
                        return <span>Not Assigned</span>
                    }
                }
            },
            {
                title: 'Account',
                field: "customer_request.account_number",
            },
            {
                title: 'Level',
                field: "customer_request.service_request_level.name",
                render: rowData => <Badge bgColor={rowData.current_level.color_code} data={rowData.current_level.name} />
            },
            {
                title: 'Service',
                field: "customer_request.service_category.name",
                render: rowData => <span>{rowData.customer_request.service_category.name}</span>
            },
            { title: 'Cust. Name', field: 'customer_request.customer.full_name' },
            { title: 'Cust. Mobile No.', field: 'customer_request.customer.mobile_number' },
            { title: 'Cust. Address', field: 'customer_request.customer.street' },
            { title: 'Created On', field: 'created_at' },
            { title: 'Updated On', field: 'updated_at' },
        ]
    });


    return (
        <ContentWrapper page_name={PAGE_NAME} breadcrumbs={breadcrumbs(PAGE_NAME)}>
            <div className="card rounded">
                <div className="card-body p-0">
                    <ThemeProvider theme={theme}>
                        <div style={{ maxWidth: '100%' }} className="text-sm">
                            <MaterialTable
                                columns={tablecolumn.columns}
                                data={data}
                                title={isMobile ? '' : PAGE_NAME}
                                isLoading={state.all.loading}
                            />
                        </div>
                    </ThemeProvider>
                </div>
            </div>
        </ContentWrapper>
    );
}
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
