/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "../../Components/Shared/LoadingButton";
import FormSkeleton from "../../Components/Skeleton/FormSkeleton";
import ContentWrapper from "../../Layouts/AdminLTE/ContentWrapper";
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
import { UpdateResourceAction } from "../../Redux/Actions/CrudActions";
import MUIInputField from "../../Components/MuiFormComponents/InputBox";

export default function Profile(props) {

    const pageName = 'Profile';

    const ACTION_TYPE = "COMMON";
    
    const BACKEND_ROUTE = "users";

    var fields = { name: "", email: "", role_id: "", _method: 'PUT' }

    const dispatch = useDispatch();

    const profile_state = useSelector(state => state.userDetails);

    const common_state = useSelector(state => state.common);

    const [ID, setID] = useState('');

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    React.useEffect(() => {
        if (!profile_state.loading && profile_state.success) {
            setData({
                ...data,
                'name': profile_state.userProfile.name,
                'email': profile_state.userProfile.email,
                'role': profile_state.role.name,
            });
            setID(profile_state.userProfile.id);
        }
    }, [profile_state]);

    React.useEffect(() => {
        var val_error_state = common_state.form.errors;
        if (val_error_state && val_error_state.validation_errors) {
            const errors = val_error_state.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (common_state.form.success) {
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
                dispatch(LoadProfileAction());
            }, 2000);
        }
    }, [common_state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <ContentWrapper page_name={pageName} breadcrumbs={breadcrumbs(pageName)}>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{pageName}</h3>
                </div>
                <div className="card-body">
                    {profile_state.loading === false && profile_state.success
                        ?
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            {/* <img className="profile-user-img img-fluid img-circle" src="/dist/img/user4-128x128.jpg" alt="User profile" /> */}
                                            <div className="profile-user-first-letter img-fluid img-circle" src="/dist/img/user4-128x128.jpg" alt="User profile">
                                                <h2 className="profile-user-name profilelog">{data.name.charAt(0).toUpperCase()}</h2>
                                            </div>
                                        </div>
                                        <h3 className="profile-username text-center">{data.name}</h3>
                                        <p className="text-muted text-center">{data.role}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card">
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">

                                            <div className="flex flex-wrap">
                                                <MUIInputField
                                                    label="Enter Name"
                                                    name="name"
                                                    value={data.name}
                                                    onChange={e => setData({ ...data, 'name': e.target.value })}
                                                    validation_error={errors.name}
                                                    required
                                                />
                                                <MUIInputField
                                                    label="Enter email"
                                                    name="email"
                                                    value={data.email}
                                                    onChange={e => setData({ ...data, 'email': e.target.value })}
                                                    validation_error={errors.email}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                                            <LoadingButton loading={common_state.form.loading} type="submit">Update Profile</LoadingButton>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        : <FormSkeleton />}
                </div>
            </div>
        </ContentWrapper>
    );
}
function breadcrumbs(pageName) {
    return (
        <>
            <li className="breadcrumb-item active">{pageName}</li>
        </>
    )
}