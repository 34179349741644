import * as React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

export default function MUIGroupRadio({ label, name, value, validation_error = false, onChangeHandler, children, required }) {
    return (
        <div className="w-100 my-2">
            <FormControl error={validation_error.length > 0 ? true : false }>
                <FormLabel id={name}>{label}</FormLabel>
                <RadioGroup row aria-labelledby={name} name={name} value={value} onChange={onChangeHandler} required={required}>
                    {children}
                    {validation_error && <FormHelperText>{validation_error}</FormHelperText>}
                </RadioGroup>
            </FormControl>
        </div>
    );
}
