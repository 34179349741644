/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React ,  { useState, useEffect}from 'react';
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RequestsCreate from './Create';
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';
import SideDrawer from '../../Components/SideDrawer';
import { useSelector } from 'react-redux';
import RequestGrid from './Grid';

export default function SetingsIndex(props) {

    const PageName = 'Setings';
    const CreateNewButtonName = 'Create New Setings';
    const IndexRoute = '/settings';

    const BACKEND_ROUTE = 'customer-requests';
    const ACTION_TYPE = "COMMON";

    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            // { title: 'Id', field: 'id' },
            { title: 'Customer', render: rowData =>  <> {rowData.customer.first_name} {rowData.customer.last_name} </> },
            { title: 'Service Category', field: 'service_category.name' },
            { title: 'title', field: 'title' },
            { title: 'Description', field: 'description' },
            // { title: 'Status', render: rowData =>  
            //     <>
            //         <button className='btn btn-success btn-sm'>Approve</button> 
            //         <button className='ml-1 btn btn-danger btn-sm'>Reject</button> 
            //     </> 
            // },
        ]
    });

    const [data, setData] = useState([]);

    const [DrawerCreateStatus, setDrawerCreateStatus] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({type:`RESTART_${ACTION_TYPE}_LIST`});
        }
    }, [state]);

    const HandleDrawerCreate = () => {
        if (DrawerCreateStatus === false) {
            setDrawerCreateStatus(true);
        } else if (DrawerCreateStatus === true) {
            setDrawerCreateStatus(false);
        }
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
            <div className="card rounded">
                {DrawerCreateStatus &&
                    <SideDrawer 
                        action_heading={CreateNewButtonName}
                        status={DrawerCreateStatus}
                        handle={HandleDrawerCreate}
                        content={<RequestsCreate />}
                    />}
                <div className="card-body p-0">
                    <RequestGrid
                        dataList={data}
                        setDataList={setData}
                        columns={tablecolumn}
                        Title={PageName}
                        HandleDrawerCreate={HandleDrawerCreate}
                    />
                </div>
            </div>
        </ContentWrapper>

    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
