import { useLoadScript } from "@react-google-maps/api";
import BarChart from "../../Components/Charts/BarChart";
import { LineChart } from "../../Components/Charts/LineChart";
import { PieChart } from "../../Components/Charts/PieChart";
import Map from "../../Components/MapComponents/Map";

// const location = {
//     address: '1600 Amphitheatre Parkway, Mountain View, california.',
//     lat: 37.42216,
//     lng: -122.08427,
// }
// const location = [
//     {
//     address: '1600 Amphitheatre Parkway, Mountain View, california.',
//     lat: 37.42216,
//     lng: -122.08427,
//     },
//     {
//     address: '1600 Amphitheatre Pkwy Building 42, Mountain View, CA 94043, United States.',
//     lat: 37.42208501415534,
//     lng: -122.08598467325723,
//     },
//     {
//     address: 'Mountain View, CA 94043, USA.',
//     lat: 37.42146300546312,
//     lng: -122.08245488632738,
//     },
// ]

const Charts = () => {

    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY // google map api key
    // });


    



    return (
        <>
            <div className="container-fluid">
                <div className="row mt-5">
                    <div className="col-md-8">
                        <BarChart />
                    </div>
                    <div className="col-md-4">
                        <PieChart />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12">
                        <LineChart />
                    </div>
                </div>
                {/* <div className="row mt-5">
                    <div className="col-md-12">
                     
                        {
                        isLoaded ? <Map /> : null
                        }
                    </div>
                </div> */}
            </div>
        </>
    );
}
export default Charts;