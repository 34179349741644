import React, { useEffect } from 'react';
import ContentBody from './ContentBody';
import ContentHeader from './ContentHeader';
import 'react-toastify/dist/ReactToastify.css';

const ContentWrapper = ({page_name,breadcrumbs,children}) => {

    useEffect( () => {
        document.title = page_name +' | WOMS'; // Title of the page in browser
        document.title= document.title.toUpperCase();
    },[page_name]);

    return (
        <div className="content-wrapper px-0 py-2">
            <ContentHeader page_name={page_name} breadcrumbs={breadcrumbs}></ContentHeader>
            <ContentBody>{children}</ContentBody>
        </div>
    );
}
export default ContentWrapper;