/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import LocationForm from "./Form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const TeamsCreate = () => {

    const PageName = 'Create New Location';
    const SubmitButtonName = 'Create Location';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "locations";

    var fields = {
        name: '',
        status: '',
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        };
        setRefreshTable(false);
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (state.form.success) {
            setData(fields);
            setErrors(fields);
            setTimeout(() => { dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` }); }, 1000);
            setRefreshTable(true);
        }
    }, [state]);


    function handleSubmit(e) {
        e.preventDefault();
        dispatch(CreateResourceAction(BACKEND_ROUTE, data, ACTION_TYPE));
    }

    return (
    <LocationForm
        btnloading={state.form.loading}
        handleSubmit={handleSubmit}
        data={data}
        setData={setData}
        SubmitButtonName={SubmitButtonName}
        errors={errors}
    />
    );

}
export default TeamsCreate;
