import React from 'react';
import SideBar from '../../Components/Partials/SideBar';
import Navbar from '../../Components/Partials/Navbar';
import Footer from '../../Components/Partials/Footer';

const Wrapper = ({page_content}) => {
    return (
        <div className="wrapper">
            {/*Navbar*/}
            <Navbar/>
            {/*Sidebar*/}
            <SideBar/>
            {/*Content Wrapper.*/}
            {page_content}

            <Footer />
        </div>
    );
}
export default Wrapper;
