/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import RequestsForm from "./Form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const RequestsCreate = () => {

    const ACTION_TYPE = "COMMON";

    const SubmitButtonName = 'Create Request';
    
    const BACKEND_ROUTE = "customer-requests";

    var fields = {
        title: '',
        customer_id: '',
        department_id: '',
        service_category_id: '',
        description: '',
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (state.form.success) {
            setData(fields);
            setErrors(fields);
            setTimeout(() => { dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` }); }, 1000);
            setRefreshTable(true);
        }
    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        var formData = {
            "title":data.title,
            "description":data.description,
            "customer_id":data.customer_id,
            "service_category_id":data.service_category_id
        }
        dispatch(CreateResourceAction(BACKEND_ROUTE, formData, ACTION_TYPE));
    }

    return (
        <RequestsForm
            btnloading={state.form.loading}
            handleSubmit={handleSubmit}
            data={data}
            setData={setData}
            SubmitButtonName={SubmitButtonName}
            errors={errors}
        />
    );
}
export default RequestsCreate;
