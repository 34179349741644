
import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import ProfileReducer from "./ProfileReducer";
import { DummyReducers } from './AllReducers';
import * as ActionTypes from '../ActionTypes';
import { COMMONReducers } from './CommonReducers';

const initState = {
   message: null,
}

const CODE_ERROR_REDUCER = (state = initState, action) => {
   switch (action.type) {
      case ActionTypes.CODE_ERROR:
         return {
            ...state,
            message: action
         }
      default:
         return state
   }
};

const RootReducer = combineReducers({
   userAuth: AuthReducer,
   userDetails: ProfileReducer,
   dummy: DummyReducers,
   common: COMMONReducers,
   code_error: CODE_ERROR_REDUCER
});
export default RootReducer;