/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import UtilitiesEdit from './Edit';
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../Components/Spinner";
import { useSelector, useDispatch } from 'react-redux';
import SideDrawer from '../../../Components/SideDrawer';
import { createTheme, ThemeProvider } from "@mui/material";
import { UpdateDataService } from "../../../Services/CrudServices";
import { DestroyResourceAction, LoadResourceAction } from "../../../Redux/Actions/CrudActions";
import { isMobile } from "react-device-detect";

const UtilitiesGrid = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "utilities";

    const EDIT_PAGE_NAME = "Edit Utility";

    const theme = createTheme();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    const [editId, setEditId] = React.useState(null);

    const [refreshTable, setRefreshTable] = React.useState(false);

    const [editDrawerStatus, setEditDrawerStatus] = React.useState(null);

    React.useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        };
        setRefreshTable(false);
    }, [refreshTable]);

    const HandleEdit = (rowData) => {
        setEditId(rowData.id);
        setEditDrawerStatus(true);
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
        console.log(state);
    }

    const HandleCloseEditDrawer = (event) => {
        if (editDrawerStatus) {
            setEditId(null);
            setEditDrawerStatus(false);
        }
    }

    const HandleActiveInActiveStatus = (rowData) => {
        var update_status_route = `update_resource_status/Utility/${rowData.id}`
        var newStatus = rowData.status === 1 ? false : true;
        var data = { '_method': 'put', 'status': newStatus };
        UpdateDataService(update_status_route, data)
            .then((res) => {
                setRefreshTable(true);
            }, error => {
                console.log("error");
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={isMobile ? '' : Title}
                    isLoading={state.all.loading}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            icon: () => <i className="fa-solid fa-landmark"></i>,
                            tooltip: 'Utility Departments list',
                            onClick: (event, rowData) => navigate(`/utilities/${rowData.id}/departments`)
                        },
                        {
                            icon: () => <i className="fa-solid fa-helmet-safety"></i>,
                            tooltip: 'Utility Services list',
                            onClick: (event, rowData) => navigate(`/utilities/${rowData.id}/services`)
                        },
                        {
                            icon: () => (isMobile)
                            ?<i className="fa-solid fa-plus theme-text-color-primary" style={{fontSize: '1.2rem'}}></i> 
                            :<span className="btn btn-sm btn-theme-primary ml-2">Create a Utility</span>,
                            tooltip: 'Create a Utility',
                            isFreeAction: true,
                            onClick: (event, rowData) => HandleDrawerCreate()
                        },
                        rowData => ({
                            icon: () => (rowData.status)
                                ? <i className="fa-solid fa-ban text-danger" style={{ fontSize: '1.2rem' }}></i>
                                : <i className="fa-solid fa-ban text-success" style={{ fontSize: '1.2rem' }}></i>,
                            tooltip: (rowData.status) ? 'Deactivate Utility' : 'Activate Utility',
                            onClick: (event, rowData) => HandleActiveInActiveStatus(rowData),
                        }),
                    ]}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...dataList]
                                    data.splice(data.indexOf(oldData), 1);
                                    setDataList(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }}
                />
            </div>
            <SideDrawer action_heading={EDIT_PAGE_NAME}status={editDrawerStatus} handle={HandleCloseEditDrawer} >
                {editId && <UtilitiesEdit ID={editId} />}
            </SideDrawer>
        </ThemeProvider>
    );
}

// export default Form;
export default UtilitiesGrid;