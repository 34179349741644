/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import { useState } from 'react';
import { useEffect } from 'react';
import FieldMasterGrid from './Grid';
import FieldMasterCreate from './Create';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SideDrawer from '../../../Components/SideDrawer';
import { LoadResourceAction } from '../../../Redux/Actions/CrudActions';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';

export default function FieldMasterIndex() {

    const PAGE_NAME = "Master Field's List";

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = 'master-fields';

    const CREATE_BUTTON_NAME = 'Create a Master Field';

    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            { title: 'Master Field Id', field: 'id' },
            { title: 'Master Field Name', field: 'name' },
            { title: 'Master Field Status', render: rowData => <div> {
                rowData.status === 1 ? 
                <span className="badge badge-pill badge-success">Active</span> 
                : <span className="badge badge-pill badge-danger">Inactive</span>
            }
            </div> },
            { title: 'Utility Name', field: 'utility.name' },
            { title: 'Created At', field: 'created_at' },
            { title: 'Updated At', field: 'updated_at' },
        ]
    });

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    const [data, setData] = useState([]);
    
    const [DrawerCreateStatus, setDrawerCreateStatus] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list ? state.all.list : []);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);

    const HandleDrawerCreate = () => {
        if (DrawerCreateStatus === false) {
            setDrawerCreateStatus(true);
        } else if (DrawerCreateStatus === true) {
            setDrawerCreateStatus(false);
        }
    }
    
    return (
        <ContentWrapper page_name={PAGE_NAME} breadcrumbs={breadcrumbs(PAGE_NAME)}>
            <div className="card rounded">
                <div className="card-body p-0">
                    <SideDrawer 
                        status={DrawerCreateStatus} 
                        action_heading={CREATE_BUTTON_NAME} 
                        handle={HandleDrawerCreate}>
                        {DrawerCreateStatus && <FieldMasterCreate />}
                    </SideDrawer>
                    <FieldMasterGrid
                        dataList={data}
                        Title={PAGE_NAME}
                        setDataList={setData}
                        columns={tablecolumn}
                        HandleDrawerCreate={HandleDrawerCreate}
                    />
                </div>
            </div>
        </ContentWrapper>

    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
