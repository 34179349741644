import { HandleExeption, RaiseSomethingWentWrong } from './../Redux/HandleExeption';

export default class HttpService {

    logurl_token_name = process.env.REACT_APP_BACKEND_TOKEN_NAME;

    url = process.env.REACT_APP_BACKEND_HOST_ENDPOINT;

    logurl = process.env.REACT_APP_LOGIN_ROUTE;

    postData = async (payload, uri, tokenId = "", signal) => {

        const token = await localStorage.getItem(tokenId);

        const requestOptions = this.postRequestOptions(token, payload, signal);

        return fetch(this.url + "/" + uri, requestOptions)
            .then(response => response.json())
            .then(data => { HandleExeption(data); return data })
            .catch(error => {
                if (error.name === 'AbortError') {
                    return {
                        data: [],
                        success: false,
                        error_type: error.name,
                        message: "Request Aborted Successfully",
                    };
                } else {
                    RaiseSomethingWentWrong();
                    return {
                        data: [],
                        success: false,
                        message: error.message,
                        error_type: error.name
                    };
                }
            });
    }

    getData = async (uri, tokenId = "", signal = '') => {

        const token = await localStorage.getItem(tokenId);

        const requestOptions = this.getRequestOptions(token, signal);

        return fetch(this.url + "/" + uri, requestOptions)
            .then(response => response.json())
            .then(data => { HandleExeption(data); return data })
            .catch(error => {
                if (error.name === 'AbortError') {
                    return {
                        data: [],
                        success: false,
                        error_type: error.name,
                        message: "Request Aborted Successfully",
                    };
                } else {
                    RaiseSomethingWentWrong();
                    return {
                        data: [],
                        success: false,
                        message: error.message,
                        error_type: error.name
                    };
                }
            });
    }

    getRequestOptions = (token, signal) => {
        let requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json' },
        }
        if (signal) {
            requestOptions['signal'] = signal;
        }
        return requestOptions;
    }

    postRequestOptions = (token, item, signal = '') => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json' },
            body: JSON.stringify(item)
        }
        if (signal) {
            requestOptions['signal'] = signal;
        }
        return requestOptions;
    }

}