import React from "react";
import { useDrag } from "react-dnd";
import './TemplateInputElement.css';

function TemplateInputElement({ id, input_type_name, icon }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "div",
        item: { id: id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    return (
        <div className="tie" data-id={id} ref={drag}>
            <i className={`mr-2 ${icon}`}></i>
            {input_type_name}
        </div>
    );
}

export default TemplateInputElement;

