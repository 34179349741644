/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import SideDrawer from './../../Components/SideDrawer';
import { createTheme, ThemeProvider } from "@mui/material";
import { DestroyResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";
import { useSelector, useDispatch } from 'react-redux';
import RequestsEdit from './Edit';
import { UpdateDataService } from "../../Services/CrudServices";
import { Spinner } from "../../Components/Spinner";

const RequestGrid = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "customer-requests";

    const theme = createTheme();

    const [id, setID] = React.useState(null);

    const [refreshTable, setRefreshTable] = useState(false);

    const [editDrawerStatus, setEditDrawerStatus] = React.useState(null);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    const HandleEditDrawer = (event) => {
        if (editDrawerStatus) {
            setID(null);
            setEditDrawerStatus(false);
        }
    }
    const HandleEdit = (rowData) => {
        setID(rowData.id);
        setEditDrawerStatus(true);
    }

    const HandleApprove = (rowData) => {

        const updateURL = BACKEND_ROUTE + "/" + rowData.id + "/update-status";
        const payLoad = {
            _method: "PUT",
            mstr_req_st_id: 2
        };

        UpdateDataService(updateURL, payLoad).then((res) => {
            // console.log(res);
            setRefreshTable(true);
        }, error => {
            console.log("error");
        });

    }

    const HandleReject = (rowData) => {

        const updateURL = BACKEND_ROUTE + "/" + rowData.id + "/update-status";
        const payLoad = {
            _method: "PUT",
            mstr_req_st_id: 3
        };

        UpdateDataService(updateURL, payLoad).then((res) => {
            // console.log(res);
            setRefreshTable(true);
        }, error => {
            console.log("error");
        });
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
        console.log(state);
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={state.all.loading ? <Spinner />: Title}
                    isLoading={state.all.loading}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            icon: () => <span className="btn btn-sm btn-theme-primary ml-2">Create a Request</span>,
                            tooltip: 'Create a Request',
                            isFreeAction: true,
                            onClick: (event, rowData) => HandleDrawerCreate()
                        },
                        rowData => ({
                            icon: 'check',
                            tooltip: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? 'Request Already Approved' : 'Approve Request' : 'Approve Request',
                            onClick: (event, rowData) => HandleApprove(rowData),
                            disabled: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? true : false : false
                        }),
                        rowData => ({
                            icon: 'close',
                            tooltip: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? "Request Rejected" : "Reject Request" : 'Reject Request',
                            onClick: (event, rowData) => HandleReject(rowData),
                            disabled: (rowData.current_status != null) ? (rowData.current_status.mstr_req_st_id === 2 || rowData.current_status.mstr_req_st_id === 3) ? true : false : false
                        })
                    ]}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...dataList]
                                    data.splice(data.indexOf(oldData), 1);
                                    setDataList(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }}
                    options={{
                        filtering: true,
                        columnsButton: true,
                    }}
                />
            </div>
            <SideDrawer status={editDrawerStatus} handle={HandleEditDrawer} >
                {id && <RequestsEdit ID={id} />}
            </SideDrawer>
        </ThemeProvider>
    );
}

export default RequestGrid;