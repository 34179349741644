import React from "react";
import LoadingButton from '../../Components/Shared/LoadingButton';
import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import MUIInputRadio from "../../Components/MuiFormComponents/InputRadio";
import MUIGroupRadio from "../../Components/MuiFormComponents/InputGroupRadio";


const LocationForm = ({handleSubmit,data,setData,SubmitButtonName,errors,btnloading}) => {

    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };
    const onHandleRadioChange = (event) => {
        if (event.target.checked) {
            setData({ ...data, [event.target.name]: event.target.value})
        } else {
            setData({ ...data, [event.target.name]: event.target.value})
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                
                <MUIInputField
                    label="Location Name"
                    name="name"
                    value={data.name}
                    onChange={onHandleChange}
                    validation_error={errors.name}
                    required
                />
                <div className="w-100">
                    <MUIGroupRadio name="status" label="Location Status" 
                        value={data.status} 
                        validation_error={errors.status} 
                        onChangeHandler={onHandleRadioChange} 
                        required={false}
                        >
                        <MUIInputRadio value="1">Active</MUIInputRadio>
                        <MUIInputRadio value="0">Inactive</MUIInputRadio>
                    </MUIGroupRadio>
                </div>

            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default LocationForm;