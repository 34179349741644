import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { GetDataService } from '../../Services/CrudServices';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    // responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Work Order Status Wise Summary',
        },
    },
    maintainAspectRatio : false,
};

export function LineChart() {

    const [LoadingLineGraphData, setLoadingLineGraphData] = useState(true);
    const [lineGraphData, setLineGraphData] = useState({});

    useEffect(() => {
        GetDataService('master-work-order-statuses').then((res) => {
            if (res.success) {
                // console.log(res.data);
                const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
                var created_dataset_array = res.data.map((o,index) => {
                    return {
                        label: o.name,
                        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                        borderColor:o.color_code ?? '',
                        backgroundColor: o.color_code ?? '',
                    }
                })
                var dataset_with_label = {
                    labels,
                    datasets: created_dataset_array
                }
                setLineGraphData(dataset_with_label);
                setLoadingLineGraphData(false);
            }
        }, (error) => { });
    }, []);

    return (
        <>
            {
                LoadingLineGraphData
                    ? 'Loading...'
                    : 
                    <>
                        <div id="line-canvas-container">
                            <Line options={options} data={lineGraphData} width={600} height={250} />
                        </div>
                    </>
            }
        </>
    )
}