/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Tree from '../../../Components/Partials/Tree';
import { Spinner } from '../../../Components/Spinner';
import TreeItem from './../../../Components/Partials/TreeItem';
import { GetDataService } from '../../../Services/CrudServices';
import LoadingButton from '../../../Components/Shared/LoadingButton';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';
import { UpdateResourceAction } from '../../../Redux/Actions/CrudActions';
import { checkElementInArray, removeElementFromArray } from '../../../Helper/Helper';

export default function UserPermissionForm() {

    const BACKEND_ROUTE = "users";

    const ACTION_TYPE = "COMMON";

    const PageName = 'User Permissions'

    const { id } = useParams();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    var fields = { permissions: [] }

    const [user, setUser] = useState([]);

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(true);

    const [modulePermissions, setModulePermissions] = useState([]);

    const [userSelectedPermissions, setuserSelectedPermissions] = useState([]);

    const [roleSelectedPermissions, setRoleSelectedPermissions] = useState([]);

    const [userSelectedPermissionsloading, setuserSelectedPermissionsLoading] = useState(true);

    useEffect(() => {
        if (refresh) {
            setLoading(true);
            GetDataService(`users/${id}`).then((res) => {
                setLoading(false);
                setUser(res.data);
            }, (error) => {
                setLoading(false);
                setUser([]);
            });
            setLoading(true);
            GetDataService('permissions').then((res) => {
                setLoading(false);
                setModulePermissions(res.data);
            }, (error) => {
                setLoading(false);
                setModulePermissions([]);
            });
            setuserSelectedPermissionsLoading(true);
            GetDataService(`users/${id}/all-permissions`).then((res) => {
                setData({ ...data, permissions: res.data.user_permissions });
                setRoleSelectedPermissions(res.data.role_permissions);
                setuserSelectedPermissions(res.data.user_permissions);
                setuserSelectedPermissionsLoading(false);
            }, (error) => {
                setData({ fields });
                setRoleSelectedPermissions([]);
                setuserSelectedPermissions([]);
                setuserSelectedPermissionsLoading(false);
            });
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (state.form.success) {
            setRefresh(true);
            // setErrors({"permissions":fields});
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
    }, [state]);

    const onHandleMultiSelectionCheckBox = (event) => {
        if (event.target.classList.contains('parent')) {
            if (event.target.checked === true) {
                var childs = document.querySelectorAll(`.module_class_id_${event.target.id}`);
                childs.forEach((element) => {
                    element.checked = true;
                    if (checkElementInArray(data.permissions, element.value)) {
                        data.permissions.push(element.value);
                    }
                });
            } else {
                childs = document.querySelectorAll(`.module_class_id_${event.target.id}`);
                childs.forEach((element) => {
                    element.checked = false;
                    removeElementFromArray(data.permissions, element.value)
                });
            }
        } else {
            if (event.target.checked === true) {
                if (checkElementInArray(data.permissions, event.target.value)) {
                    data.permissions.push(event.target.value);
                }
            } else {
                removeElementFromArray(data.permissions, event.target.value)
            }
        }
    }

    function userParentHasPermission(permissions_objects) {
        var count_of_selected = 0;
        permissions_objects.forEach(element => {
            if (userSelectedPermissions.includes(element.name)) {
                count_of_selected += 1
            }
        });
        return count_of_selected === permissions_objects.length ? true : false;
    }

    function RoleParentHasPermission(permissions_objects) {
        var count_of_selected = 0;
        permissions_objects.forEach(element => {
            if (roleSelectedPermissions.includes(element.name)) {
                count_of_selected += 1
            }
        });
        return count_of_selected === permissions_objects.length ? true : false;
    }

    function userHasPermission(permission_name) {
        const stored_permissions = userSelectedPermissions;
        return stored_permissions.includes(permission_name);
    }

    function roleHasPermission(permission_name) {
        const stored_permissions = roleSelectedPermissions;
        return stored_permissions.includes(permission_name);
    }

    function handleSubmit(e) {
        e.preventDefault();
        let route = `${id}/update-direct-permissions`;
        var formdata = {
            "_method": "PUT",
            "permissions": data.permissions
        }
        dispatch(UpdateResourceAction(BACKEND_ROUTE, route, formdata, ACTION_TYPE));
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName, '/users', 'Users')}>
            <div className="card rounded">
                <div className="card-header">
                    <div className="card-title">
                        <u>{PageName}</u>
                        {
                            loading
                                ? <Spinner />
                                : <div><span className='m-0'><b>Username:</b> {user.name} (<span className='m-0'><b>Role:</b> {user.role}</span>)</span></div>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-wrap">
                            <div className='w-100'>
                                <h6 className={`${errors.permissions.length > 0 ? 'text-danger' : 'text-muted'}`}>Update Permissions: *</h6>
                                {errors.permissions && <small className="text-danger">{errors.permissions}</small>}
                                <hr className={`${errors.permissions.length > 0 && 'border-color-danger'}`} />
                                {userSelectedPermissionsloading ? <Spinner /> :
                                    <div style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
                                        <Tree className="w-100 pr-2">
                                            {modulePermissions.map(function (module, m_index) {
                                                return (
                                                    <TreeItem
                                                        key={m_index}
                                                        label={module.name}
                                                        class_parent="parent"
                                                        field_id={`${module.id}`}
                                                        onChangeHandler={onHandleMultiSelectionCheckBox}
                                                        {
                                                        ...RoleParentHasPermission(module.permissions) && {
                                                            checked: true,
                                                            disabled: true,
                                                            title: 'Cannot modified role given permission',
                                                        }
                                                        }
                                                        {
                                                        ...userParentHasPermission(module.permissions) && {
                                                            checked: true
                                                        }
                                                        }
                                                    >
                                                        {module.permissions.map(function (permission, p_index) {
                                                            return (
                                                                <TreeItem
                                                                    key={p_index}
                                                                    ischildren={true}
                                                                    value={permission.name}
                                                                    field_id={permission.name}
                                                                    label={permission.display_name}
                                                                    module_id={permission.module_id}
                                                                    checked={userHasPermission(permission.name)}
                                                                    onChangeHandler={onHandleMultiSelectionCheckBox}
                                                                    child_unique_class={`module_class_id_${permission.module_id}`}
                                                                    {...user.roles[0].is_system_role && {
                                                                        disabled: true,
                                                                        title: "This is system role cannot be changed."
                                                                    }}
                                                                    {
                                                                    ...roleHasPermission(permission.name) && {
                                                                        disabled: true,
                                                                        checked: true,
                                                                        title: 'Cannot modified role given permission',
                                                                    }
                                                                    }
                                                                />
                                                            )
                                                        })
                                                        }
                                                    </TreeItem>
                                                )
                                            })}
                                        </Tree>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-end border-top pt-2">
                            <button type='button' onClick={() => navigate('/users')} className='btn btn-danger mr-2'>Cancel</button>
                            <LoadingButton loading={state.form.loading} type="submit">Update User Rights</LoadingButton>
                        </div>
                    </form>
                </div>
            </div>
        </ContentWrapper>
    );
}


function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
