/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ShowResourceAction, UpdateResourceAction } from '../../Redux/Actions/CrudActions';
import { useEffect } from 'react';
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import ComingSoon from '../../Components/ComingSoonComponent/Index';
import { NavLink } from 'react-router-dom';
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */


const ChangePassword = () => {

    const IndexRoute = "/roles"
    const IndexPageName = 'Roles';
    const PageName = 'Change Password';
    const SubmitButtonName = 'Update Role';
    const BACKEND_ROUTE = "roles";
    const ACTION_TYPE = "COMMON";

    var fields = {
        name: '',
        description: '',
        is_system_role: false,
        permissions: [],
        _method: 'PUT',

    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const state = useSelector(state => state.common);

    let { id } = useParams();

    const dispatch = useDispatch();

    function show_page_form(edit_id = id) {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(ShowResourceAction(BACKEND_ROUTE, edit_id, ACTION_TYPE));
    }

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    useEffect(() => {
        // show_page_form();
    }, []);

    useEffect(() => {

        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success && state.form.payload) {
            setErrors(fields);
            setTimeout(() => {
                show_page_form();
            }, 1000);
        }

        if (state.show.success && state.show.payload) {
            setData({
                '_method': 'PUT',
                'name': state.show.payload.data.name  ?? '',
                'description': state.show.payload.data.description ?? '',
                'is_system_role': state.show.payload.data.is_system_role  ?? '',
                'permissions': state.show.payload.data.permissions  ?? '',
            });
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        }
    }, [state]);

    function handleSubmit(e) {
        console.log('submitdata', data)
        e.preventDefault();
        dispatch(UpdateResourceAction(BACKEND_ROUTE, id, data, ACTION_TYPE));
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName, IndexRoute, IndexPageName)}>
            <div className="card rounded shadow">
                <div className="card-header">
                    <h3 className="card-title">{PageName}</h3>
                </div>
                <div className="card-body">
                <ComingSoon title="Change password page is coming soon.." />
                    {/* {
                        state.show.loading ?
                            <FormSkeleton />
                            :
                            <RolesForm
                                btnloading={state.form.loading}
                                handleSubmit={handleSubmit}
                                data={data}
                                setData={setData}
                                SubmitButtonName={SubmitButtonName}
                                errors={errors}
                            />
                    } */}
                </div>
            </div>
        </ContentWrapper>
    );
}

function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item"><NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}

export default ChangePassword;