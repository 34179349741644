import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Tree from '../../../Components/Partials/Tree';
import { Spinner } from '../../../Components/Spinner';
import TreeItem from '../../../Components/Partials/TreeItem';
import { GetDataService } from '../../../Services/CrudServices';
import LoadingButton from '../../../Components/Shared/LoadingButton';
import MUIInputField from '../../../Components/MuiFormComponents/InputBox';
import { checkElementInArray, removeElementFromArray } from '../../../Helper/Helper';

const RolesForm = ({ handleSubmit, data, setData, SubmitButtonName, errors, btnloading }) => {

    const [modulePermissions, setModulePermissions] = useState([]);
    const [loading, setLoading] = useState(true);

    let navigate = useNavigate();

    useEffect(() => {
        GetDataService('permissions').then((res) => {
            setLoading(false);
            setModulePermissions(res.data);
        }, (error) => {
            setLoading(false);
            setModulePermissions([]);
        }
        );
    }, []);

    const onHandleMultiSelectionCheckBox = (event) => {
        if (event.target.classList.contains('parent')) {
            if (event.target.checked === true) {
                var childs = document.querySelectorAll(`.module_class_id_${event.target.id}`);
                childs.forEach((element) => {
                    element.checked = true;
                    if (checkElementInArray(data.permissions, element.value)) {
                        data.permissions.push(element.value);
                    }
                });
            } else {
                childs = document.querySelectorAll(`.module_class_id_${event.target.id}`);
                childs.forEach((element) => {
                    element.checked = false;
                    removeElementFromArray(data.permissions, element.value)
                });
            }
        } else {
            if (event.target.checked === true) {
                if (checkElementInArray(data.permissions, event.target.value)) {
                    data.permissions.push(event.target.value);
                }
            } else {
                removeElementFromArray(data.permissions, event.target.value)
            }
        }
    }

    const onHandleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };

    function roleHasPermission(permission_name) {
        const stored_permissions = data.permissions;
        return stored_permissions.includes(permission_name);
    }

    return (

        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                <MUIInputField
                    label="Role Name"
                    name="name"
                    value={data.name}
                    onChange={onHandleChange}
                    validation_error={errors.name}
                    required
                    {...data.is_system_role && {disabled:true}}
                />
                <MUIInputField
                    label="Role Description"
                    name="description"
                    value={data.description}
                    onChange={onHandleChange}
                    validation_error={errors.description}
                    multiline
                    maxRows={4}
                    required
                    {...data.is_system_role && {disabled:true}}
                />
                <div className='w-100 mt-3'>
                    <h6 className={`${errors.permissions.length > 0 ? 'text-danger' : 'text-muted'}`}>Select Permissions: *</h6>
                    {errors.permissions && <small className="text-danger">{errors.permissions}</small>}
                    <hr className={`${errors.permissions.length > 0 && 'border-color-danger'}`} />
                    {loading ? <Spinner /> :
                        <div style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
                            <Tree className="w-100 pr-2">
                                {modulePermissions.map(function (module, m_index) {
                                    return (
                                        <TreeItem
                                            key={m_index}
                                            checked={false}
                                            label={module.name}
                                            class_parent="parent"
                                            field_id={`${module.id}`}
                                            {...data.is_system_role && {disabled:true}}
                                            onChangeHandler={onHandleMultiSelectionCheckBox}
                                        >
                                            {module.permissions.map(function (permission, p_index) {
                                                return (
                                                    <TreeItem
                                                        key={p_index}
                                                        ischildren={true}
                                                        value={permission.name}
                                                        field_id={permission.name}
                                                        label={permission.display_name}
                                                        module_id={permission.module_id}
                                                        onChangeHandler={onHandleMultiSelectionCheckBox}
                                                        child_unique_class={`module_class_id_${permission.module_id}`}
                                                        {...data.is_system_role && {disabled:true,title:"This is system role cannot be changed."}}
                                                        checked={roleHasPermission(permission.name)} />
                                                    )
                                                })
                                            }
                                        </TreeItem>
                                    )
                                })}
                            </Tree>
                        </div>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-end border-top pt-2">
                <button type='button' onClick={() => navigate('/roles')} className='btn btn-danger mr-2'>Cancel</button>
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default RolesForm;

