/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import RequestStatusForm from "./Form";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FormSkeleton from "../../../Components/Skeleton/FormSkeleton";
import { LoadResourceAction, ShowResourceAction, UpdateResourceAction } from '../../../Redux/Actions/CrudActions';

const RequestStatusEdit = ({ ID }) => {
    const PageName = 'Edit Status';
    const SubmitButtonName = 'Update Status';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "master-request-statuses";

    var fields = {
        name: '',
        description: '',
        status: '',
        _method: 'PUT',
    }

    const [data, setData] = useState(fields);
    const [errors, setErrors] = useState(fields);
    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);
    const dispatch = useDispatch();

    // Load Particular Resource By ID and clear previous state for form.
    useEffect(() => {
        // Clear Previous State of The Form from Redux Store
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });

        // Fetch the Resource from Server via redux store.
        dispatch(ShowResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {

        if (state.form.validation_errors) {
            // store the validation errors in the state object setErrors.
            state.form.validation_errors.map((value, key) => {
                setErrors({ ...data, [key]: value })
            });
            // Restart the Form on validation errors came from the server.
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        // Successfully Update the resouce and clear the form.
        if (state.form.success && state.form.payload) {
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            }, 1000);
            setRefreshTable(true);
        }

        // Successfully Fetched resouce from the server
        if (state.show.success && state.show.payload) {
            // Set All data in state object.

            setData({
                "_method": "PUT",
                'name': state.show.payload.data.name,
                'description': state.show.payload.data.description,
                'status': state.show.payload.data.status,
            });
            // Restart the show action on came from the server.
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        }

    }, [state]);


    function handleSubmit(e) {
        e.preventDefault();
        // Call Redux Action to update the resouce
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <div>
            {
                state.show.loading ?
                    <FormSkeleton />
                    :
                    <RequestStatusForm
                        btnloading={state.form.loading}
                        handleSubmit={handleSubmit}
                        data={data}
                        setData={setData}
                        SubmitButtonName={SubmitButtonName}
                        errors={errors}
                    />
            }
        </div>
    );

}

export default RequestStatusEdit;