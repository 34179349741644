import React from 'react'
import './MasterFormSectionItem.css';

export default function MasterFormSectionItem({...props}) {
    return (
        <div className="MasterFormSectionItem d-flex align-items-center border border-secondary rounded-lg mb-1">
            <div
                className="flex-fill pl-2 py-0 "
                data-id={props.section.id} onClick={(e) => props.section_selected(e, props.section)}
            >
                <p className="m-0 p-0">{props.section.name}</p>
            </div>
            <div className="ml-auto text-xs m-0 pr-2">
                <i className="fas fa-pencil text-primary" />
                <i className="fas fa-trash ml-2  text-danger" onClick={(e) => props.deleteSection(e, props.section)}/>
            </div>
        </div>
    )
}
