import React from 'react';
import cx from 'classnames';
import { Spinner } from '../Spinner';

export default function LoadingButton({ loading, className, children, ...props }){
  const classNames = cx(
    'flex items-center',
    'btn btn-theme-primary',
    {
      'pointer-events-none bg-opacity-75 select-none': loading
    },
    className
  );
  return (
    <button disabled={loading} className={classNames} {...props}>
      <div>{children}</div>
      {loading && <Spinner color="text-white" />}
    </button>
  );
};
