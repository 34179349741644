import React, { useEffect, useState } from 'react'

export default function DynTextInput({index,input_props,editInput,updateInputProps}) {

    const { name , label , placeholder} = input_props;
    const [defaultValueView, setDefaultValueView] = useState("");

    return (
        <div className="form-group">
            {
                !editInput ? 
                <>
                    <label>{label}</label>
                    <input type="email" className="form-control form-control-sm" placeholder={placeholder} value={defaultValueView} onChange={ (e) => setDefaultValueView('') }/>
                </>
                : <>
                <label htmlFor="exampleFormControlInputn">Label Name</label>
                <input 
                    type="email" 
                    className="form-control form-control-sm" 
                    id="exampleFormControlInputn" 
                    placeholder="label name" 
                    value={label}
                    name="label"
                    onChange={ (e) => updateInputProps(index,e) }
                />               
                <label className="mt-1" htmlFor="placeholder_input">Place Holder</label>
                <input 
                    type="email" 
                    className="form-control form-control-sm" 
                    id="placeholder_input" 
                    placeholder="label name" 
                    defaultValue={placeholder}
                    name="placeholder"
                    onChange={ (e) =>updateInputProps(index,e) }
                />               
                </>
            }
           
        </div>
    )
}
