/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React,{useState} from 'react';
import Guest from '../../Layouts/Guest';
import Input from '../../Components/Form/Input';
import Label from '../../Components/Label';

import {useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom'; 
import {RegisterAction} from '../../Redux/Actions/AuthActions';
import { Alert } from '@mui/material';
import LoadingButton from '../../Components/Shared/LoadingButton';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';

export default function Register() {

    const dispatch = useDispatch();

    const state = useSelector(state => state.userAuth);

    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        message: '',
    });

    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const checkPasswordMatch = (password,password_confirmation) => {
        return  password !== password_confirmation ? true : false; 
    }
    
    
    const submit = (e) => {
        e.preventDefault();
        const passwordMatch = checkPasswordMatch(data.password, data.password_confirmation);
        if(passwordMatch === true){
            alert('passwords dont match. please check your password again');
            return;
        }
        dispatch(RegisterAction(data));
    };

    return (
        <Guest>
            <form onSubmit={submit}>
                <div className="text-muted text-center h5 mb-2">Log in</div>
                <div className="text-muted text-center h5 mb-4">
                    {errors.message && <Alert severity="error" color="error">{errors.message}! please check your credentials</Alert>}
                </div>
                <div>
                    {/* <Label forInput="name" value="Name" /> */}

                    <TextField
                        fullWidth
                        type="text"
                        name="name"
                        value={data.name}
                        className="mt-1 block w-full"
                        autoComplete="name"
                        isFocused={true}
                        handleChange={onHandleChange}
                        required
                        label="Name" variant="standard"
                    />
                </div>

                <div className="mt-4">
                    {/* <Label forInput="email" value="Email" /> */}

                    <TextField
                        fullWidth
                        type="email"
                        name="email"
                        value={data.email}
                        className="mt-1 block w-full"
                        autoComplete="username"
                        handleChange={onHandleChange}
                        label="Email" variant="standard"
                        required
                    />
                </div>

                <div className="mt-4">
                    {/* <Label forInput="password" value="Password" /> */}

                    <TextField
                        fullWidth
                        type="password"
                        name="password"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="new-password"
                        label="Password" variant="standard"
                        handleChange={onHandleChange}
                        required
                    />
                </div>

                <div className="mt-4">
                    {/* <Label forInput="password_confirmation" value="Confirm Password" /> */}

                    <TextField
                        fullWidth 
                        type="password"
                        name="password_confirmation"
                        value={data.password_confirmation}
                        className="mt-1 block w-full"
                        label="Confirm Password" variant="standard"
                        handleChange={onHandleChange}
                        required
                    />
                </div>

                <div className="d-flex justify-content-between mt-4">
                    <div className="float-left">
                        <NavLink to="/login" className="underline text-sm text-gray-600 hover:text-gray-900">
                            Already registered?
                        </NavLink>
                    </div>
                    <div className="ml-4 float-right">
                        <LoadingButton loading={state.loading}>Register</LoadingButton>
                    </div>
                </div>
            </form>
        </Guest>
    );
}
