/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import Form from "./Form";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FormSkeleton from "../../Components/Skeleton/FormSkeleton";
import { LoadResourceAction, ShowResourceAction, UpdateResourceAction } from './../../Redux/Actions/CrudActions';

const TeamsEdit = ({ ID }) => {

    const ACTION_TYPE = "COMMON";

    const SubmitButtonName = 'Update Team';

    const BACKEND_ROUTE = "teams";

    var fields = {
        name: '',
        status: '',
        leader_id: '',
        utility_id: '',
        location_id: '',
        team_members: [],
        utility_service_id: [],
        utility_department_id: [],
    }

    const dispatch = useDispatch();

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const state = useSelector(state => state.common);

    const [refreshTable, setRefreshTable] = useState(false);

    useEffect(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(ShowResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success) {
            setErrors(fields);
            setTimeout(() => { dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` }); }, 1000);
            setRefreshTable(true);
        }

        if (state.show.success && state.show.payload) {
            var fetched_data = state.show.payload.data;
            var location = fetched_data.location;
            var utility = fetched_data.utility_departments[0].utility;
            setData({
                _method: "PUT",
                name:fetched_data.name,
                status:fetched_data.status,
                utility_id:utility.id,
                location_id:fetched_data.location_id,
                team_members:fetched_data.team_members.map((o)=>o.id),
                utility_service_id:fetched_data.utility_services.map((o)=>o.id),
                utility_department_parent_id: state.show.payload.data.utility_departments[0].parent_id ?? "",
                utility_department_id:fetched_data.utility_departments.map((o)=>o.id),
            });
        }
    }, [state]);


    function handleSubmit(e) {
        e.preventDefault();
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <div>
            { state.show.loading ?
                <FormSkeleton />
                :
                <Form
                    btnloading={state.form.loading}
                    handleSubmit={handleSubmit}
                    data={data}
                    setData={setData}
                    SubmitButtonName={SubmitButtonName}
                    errors={errors}
                />
            }
        </div>
    );
}

export default TeamsEdit;