import React from 'react'
import DynTextInputView from './DynInputs/DynTextInputView'

export default function ViewInputElement({ id, input_type_name, icon, component, index, input_props }) {
	return (
			<div className="p-2">
				{
					component === 'dy_input' ?
						<DynTextInputView
							index={index}
							input_props={input_props}
						/>
						:
						<></>
				}
			</div>
	)
}
