/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import UsersForm from "./Form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const UsersCreate = () => {

    const SubmitButtonName = 'Create User';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "users";

    var fields = {
        title: "",
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        mobile_number: "",
        password: "",
        password_confirmation: "",
        role_id: "",
        utility_id:'',
        utility_department_id:[]
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success) {
            setData(fields);
            setErrors(fields);
            setTimeout(() => { dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` }); }, 1000);
            setRefreshTable(true);
        }

    }, [state]);

    const checkPasswordMatch = (password, password_confirmation) => {
        return password !== password_confirmation ? true : false;
    }

    function handleSubmit(e) {
        e.preventDefault();
        const passwordMatch = checkPasswordMatch(data.password, data.password_confirmation);
        if (passwordMatch === true) {
            setErrors({ ...errors, password_confirmation: 'confirm new password not matching with new password.' });
            return;
        }
        var formData = {...data,name: data.first_name + ' ' + data.last_name};
        dispatch(CreateResourceAction(BACKEND_ROUTE, formData, ACTION_TYPE));
    }

    return (
        <UsersForm
            btnloading={state.form.loading}
            handleSubmit={handleSubmit}
            data={data}
            setData={setData}
            SubmitButtonName={SubmitButtonName}
            errors={errors}
        />
    );
}
export default UsersCreate;
