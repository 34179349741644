import React from 'react'
import MUIInputField from '../../../Components/MuiFormComponents/InputBox';

export const Water = ({ accountFieldData, onHandleAccountFieldChange }) => {
    return (
        <fieldset>
            <legend>Water Account & Meter information</legend>
            <div className="row">
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Account Number"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.account): ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
                {/* <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Serial Number"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? accountFieldData.water_account.seq_number : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div> */}
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Cycle"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.cycle) : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Route"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.route) : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Old Meter Number"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.old_meter_number) : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Old Meter type"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.old_meter_type) : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Old Meter Make"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.old_meter_make) : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Old Meter Size"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.old_meter_size) : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
                <div className="col-md-3 col-sm-12 mb-2">
                    <MUIInputField
                        label="Old Radio Number"
                        onChange={onHandleAccountFieldChange}
                        value={accountFieldData.water_account ? String(accountFieldData.water_account.old_radio_number) : ''}
                        inputProps={{ readOnly: false }}
                    />
                </div>
            </div>
        </fieldset>
    )
}
