import * as ActionTypes from '../ActionTypes';
import { combineReducers } from 'redux';

const initDummyState = {
    listState : {
        list:[],
        loading:null,
        success:null,
        errors:null
    },
    formState : {
        payload:null,
        loading:null,
        success:null,
        errors:null,
        validation_errors:null
    },
    showState : {
        payload:null,
        loading:null,
        success:null,
        errors:null,
    },
    deleteState : {
        payload:null,
        loading:null,
        success:null,
        errors:null,
    }
};

export const DummyList = (state = initDummyState['listState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_DUMMY_LIST:
            return {
                ...state,
                list:[],
                loading:null,
                success:null,
                errors:null,
            };
        case ActionTypes.LOADING_DUMMY_LIST:
            return {
                ...state,
                list:[],
                loading:true,
                success:null,
                errors:null,
            };
        case ActionTypes.LOAD_DUMMY_SUCCESS:
            return {
                ...state,
                list:action.res.data,
                loading:false,
                success:action.res.success,
                errors:null,
            };
        case ActionTypes.LOAD_DUMMY_ERROR:
            return {
                ...state,
                list:[],
                loading:false,
                success:false,
                errors:action.res,
            };
        default:
            return state;
    }
};

export const DummyForm = (state = initDummyState['formState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_DUMMY_FORM:
            return {
                ...state,
                payload: null,
                loading: null,
                success: null,
                validation_errors: null,
                errors:null
            };
        case ActionTypes.LOADING_DUMMY_FORM:
            return {
                ...state,
                payload: null,
                loading: true,
            };
        case ActionTypes.CREATE_DUMMY_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                payload: action.res
            };
        case ActionTypes.CREATE_DUMMY_ERROR:
            return {
                ...state,
                loading: null,
                errors: action.res,
                payload: null,
            };
        case ActionTypes.UPDATE_DUMMY_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                payload: action.res
            };
        case ActionTypes.UPDATE_DUMMY_ERROR:
            return {
                ...state,
                loading: null,
                errors: action.res,
                payload: null,
            };
        default:
            return state;
    }
};

export const DummyShow = (state = initDummyState['deleteState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_DUMMY_SHOW:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:null
            };
        case ActionTypes.LOADING_DUMMY_SHOW:
            return {
                ...state,
                payload:null,
                loading:true,
                success:null,
                errors:null
            };
        case ActionTypes.SHOW_DUMMY_SUCCESS:
            return {
                ...state,
                loading:false,
                success:true,
                payload:action.res,
                errors:null
            };
        case ActionTypes.SHOW_DUMMY_ERROR:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:action.res,
            };
        default:
            return state;
    }
};

export const DummyDelete = (state = initDummyState['showState'], action) => {
    switch (action.type) {
        case ActionTypes.RESTART_DUMMY_DELETE:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:null
            };
        case ActionTypes.LOADING_DUMMY_DELETE:
            return {
                ...state,
                payload:null,
                loading:true,
                success:null,
                errors:null
            };
        case ActionTypes.DELETE_DUMMY_SUCCESS:
            return {
                ...state,
                loading:false,
                success:true,
                payload:action.res,
                errors:null
            };
        case ActionTypes.DELETE_DUMMY_ERROR:
            return {
                ...state,
                payload:null,
                loading:null,
                success:null,
                errors:action.res,
            };
        default:
            return state;
    }
};

export const DummyReducers = combineReducers({
    all: DummyList,
    form: DummyForm,
    show: DummyShow,
    delete: DummyDelete,
});


