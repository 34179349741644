import React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

const Guard = () => {
	const outlet = useOutlet();
	const user = localStorage.getItem(process.env.REACT_APP_BACKEND_TOKEN_NAME);
	if (!user) return <Navigate to={process.env.REACT_APP_LOGIN_ROUTE} />;
	return (<>{outlet}</>);
};

export default Guard;

export function GuestGuard() {
	const outlet = useOutlet();
	const user = localStorage.getItem(process.env.REACT_APP_BACKEND_TOKEN_NAME);
	if (user) return <Navigate to={process.env.REACT_APP_DASHBOARD_ROUTE} />;
	return (<>{outlet}</>);
}