/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import ServicesForm from "./Form";
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FormSkeleton from "../../../../Components/Skeleton/FormSkeleton";
import { LoadResourceAction, ShowResourceAction, UpdateResourceAction } from '../../../../Redux/Actions/CrudActions';

const ServicesEdit = ({ ID, departments }) => {

    const { utility_id } = useParams(); 

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "utility_services";

    const SUBMIT_BUTTON_NAME = 'Update Service';

    const LIST_BACKEND_ROUTE = `services_by_utility/${utility_id}`;

    var fields = { name: '', description: '', status: '', _method: 'PUT', utility_department_id:""}

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(LIST_BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    function show_page_form(edit_id = ID) {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(ShowResourceAction(BACKEND_ROUTE, edit_id, ACTION_TYPE));
    }

    useEffect(() => {
        show_page_form();
    }, []);

    useEffect(() => {

        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success && state.form.payload) {
            setErrors(fields);
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            setTimeout(() => { show_page_form(); }, 1000);
            setRefreshTable(true);
        }

        if (state.show.success && state.show.payload) {
            setData({
                "_method": "PUT",
                'utility_id': utility_id ?? utility_id,
                'name': state.show.payload.data.name ?? "",
                'status': state.show.payload.data.status ?? "",
                'level_id': state.show.payload.data.level_id ?? "",
                'description': state.show.payload.data.description ?? "",
                'utility_department_name': state.show.payload.data.utility_department_id.name ?? "",
                'utility_department_parent_id': state.show.payload.data.department.parent_id ?? "",
                'utility_department_id': state.show.payload.data.utility_department_id ?? "",
                'level_name': state.show.payload.data.level ? state.show.payload.data.level.name : "",
            });
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        }
    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        const FormData = {
            "_method": "PUT",
            "name": data.name,
            "status": data.status,
            "level_id": data.level_id,
            "utility_id": data.utility_id,
            "description": data.description,
            "utility_department_id": data.utility_department_id,
        }
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, FormData, ACTION_TYPE));
    }

    return (
        <div>
            {
            state.show.loading 
                ? <FormSkeleton />
                : <ServicesForm
                    data={data}
                    errors={errors}
                    setData={setData}
                    departments={departments}
                    handleSubmit={handleSubmit}
                    btnloading={state.form.loading}
                    SubmitButtonName={SUBMIT_BUTTON_NAME}
                />
            }
        </div>
    );
}

export default ServicesEdit;