import React from "react";
import Charts from './Charts';
import InfoBox from "./InfoBox";
import ContentWrapper from "../../Layouts/AdminLTE/ContentWrapper";

export default function Dashboard() {
    const pageName = 'Dashboard';
    return (
        <ContentWrapper page_name={pageName} breadcrumbs={breadcrumbs(pageName)}   >
            <div className="card p-2">
                <InfoBox />
                <Charts />
            </div>
        </ContentWrapper>
    );
}
function breadcrumbs(pageName) {
    return (
        <>
            <li className="breadcrumb-item active">{pageName}</li>
        </>
    )
}
