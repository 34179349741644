import React from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from "../../../Components/Spinner";
import { createTheme, ThemeProvider } from "@mui/material";
import { DestroyResourceAction } from '../../../Redux/Actions/CrudActions';
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";

const RolesGrid = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {

    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "roles";
    const CREATE_PAGE_ROUTE = "/roles/create";
    const EDIT_PAGE_ROUTE = "/roles/edit/";

    const theme = createTheme();

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const HandleCreate = (event) => {
        navigate(CREATE_PAGE_ROUTE, { replace: true });
    }

    const HandleEdit = (rowData) => {
        navigate(`${EDIT_PAGE_ROUTE}${rowData.id}`, { replace: true });
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={isMobile ? '': Title}
                    isLoading={state.all.loading}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            icon: () => <i className="fa-solid fa-list"></i>,
                            tooltip: 'Workorder Statuses',
                            onClick: (event, rowData) => navigate(`/roles/statuses`)
                        },
                        {
                            icon: () => (isMobile)
                            ?<i className="fa-solid fa-plus theme-text-color-primary" style={{fontSize: '1.2rem'}}></i> 
                            :<span className="btn btn-sm btn-theme-primary ml-2">Create a Role</span>,
                            tooltip: 'Create a Role',
                            isFreeAction: true,
                            onClick: (event, rowData) => HandleCreate()
                        },
                    ]}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...dataList]
                                    data.splice(data.indexOf(oldData), 1);
                                    setDataList(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }}
                    options={{
                        actionsColumnIndex: 0
                    }}
                />
            </div>
        </ThemeProvider>
    );
}

export default RolesGrid;