import React from 'react';
import { NavLink } from "react-router-dom";
export default function Footer(props) {
    return (
        <footer className="main-footer" style={{'position':'inherit','zIndex': 1}}>
            <strong>&copy; 2016-2024 <NavLink to="/">WOMS Portal</NavLink>.</strong> All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <strong>Powered by </strong>Xtreme <b>Version</b> 1.0.0
            </div>
        </footer>
    );
}
