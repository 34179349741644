import { DeleteService, ShowService } from './../../Services/CrudServices';
import { CreateService, IndexService, UpdateService } from '../../Services/CrudServices';


export const LoadResourceAction = (ROUTE, ACTION_TYPE, AbortControllerSignal = '') => {

    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_LIST` });
        IndexService(ROUTE, AbortControllerSignal).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `LOAD_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `LOAD_${ACTION_TYPE}_ERROR`, res });
            } else {
                dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
            }
        }).catch((error) => {
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
            console.log('LoadResourceActionResponse==================Error====================================', error);
            var error_message = error.message;
            dispatch({ type: `CODE_ERROR`, error_message });
        })
    }
}

export const ShowResourceAction = (ROUTE, ID, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_SHOW` });
        ShowService(ROUTE, ID).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `SHOW_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `SHOW_${ACTION_TYPE}_ERROR`, res });
            }
        }).catch((error) => {
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
            console.log('LoadResourceActionResponse==================Error====================================', error);
            var error_message = error.message;
            dispatch({ type: `CODE_ERROR`, error_message });
        })
    }
}

export const CreateResourceAction = (ROUTE, DATA, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_FORM` });
        CreateService(ROUTE, DATA).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `CREATE_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `CREATE_${ACTION_TYPE}_ERROR`, res });
            }
        }).catch((error) => {
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            console.log('LoadResourceActionResponse==================Error====================================', error);
            var error_message = error.message;
            dispatch({ type: `CODE_ERROR`, error_message });
        })
    }
}

export const UpdateResourceAction = (ROUTE, ID, DATA, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_FORM` });
        UpdateService(ROUTE, ID, DATA).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `UPDATE_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `UPDATE_${ACTION_TYPE}_ERROR`, res });
            }
        }).catch((error) => {
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            console.log('LoadResourceActionResponse==================Error====================================', error);
            var error_message = error.message;
            dispatch({ type: `CODE_ERROR`, error_message });
        })
    }
}

export const DestroyResourceAction = (ROUTE, ID, ACTION_TYPE) => {
    return (dispatch) => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_DELETE` });
        dispatch({ type: `LOADING_${ACTION_TYPE}_DELETE` });
        DeleteService(ROUTE, ID).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: `DELETE_${ACTION_TYPE}_SUCCESS`, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: `DELETE_${ACTION_TYPE}_ERROR`, res });
            }
        }).catch((error) => {
            dispatch({ type: `RESTART_${ACTION_TYPE}_DELETE` });
            console.log('LoadResourceActionResponse==================Error====================================', error);
            var error_message = error.message;
            dispatch({ type: `CODE_ERROR`, error_message });
        })
    }
}
