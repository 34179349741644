import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import LoadingButton from '../../Components/Shared/LoadingButton';


const Form = ({ handleSubmit, data, setData, SubmitButtonName, errors, btnloading }) => {

    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                <MUIInputField
                    label="Dummy Name"
                    name="name"
                    value={data.name}
                    onChange={onHandleChange}
                    validation_error={errors.name}
                    required
                />
                <MUIInputField
                    label="Dummy Description"
                    name="description"
                    value={data.description}
                    onChange={onHandleChange}
                    validation_error={errors.description}
                    required
                />
                <MUIInputField
                    label="Dummy Date"
                    name="date"
                    value={data.date}
                    onChange={onHandleChange}
                    validation_error={errors.date}
                    required
                />
            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>
    );
}

export default Form;