import React from 'react';
import { NavLink } from "react-router-dom";

const BreadCrumb = ({children}) => {
    return (
        <ol className="breadcrumb">
            <li className="breadcrumb-item">
                {/* <a href="/">Home</a> */}
                <NavLink to="/"  >Home</NavLink>
            </li>
            {children}
        </ol>
    );
}

export default BreadCrumb;