/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React from 'react';
import ApplicationLogo from '../Components/ApplicationLogo';
import { NavLink } from "react-router-dom";
export default function Guest({ children }) {
    return (
        // <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
        //     <div>
        //         <NavLink to="/">
        //             <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />
        //         </NavLink>
        //     </div>

        //     <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
        //         {children}
        //     </div>
        // </div>

        <>
            <div className='min-h-screen bg-gray-100 objectClass'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='bg-white px-5 py-3 loginFormDiv w-50 mx-auto my-0'>
                                <p className='text-center mb-1 loginHeader'>WOMS</p>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
