import React from "react";
import RequestStatusEdit from './Edit';
import MaterialTable from "material-table";
import { Spinner } from "../../../Components/Spinner";
import SideDrawer from '../../../Components/SideDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from "@mui/material";
import { DestroyResourceAction } from "../../../Redux/Actions/CrudActions";


const RequestStatusGird = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {

    const ACTION_TYPE = "DUMMY";
    const BACKEND_ROUTE = "service-categories";
    const theme = createTheme();

    const [id, setID] = React.useState(null);
    const [editDrawerStatus, setEditDrawerStatus] = React.useState(null);

    const state = useSelector(state => state.dummy);

    const dispatch = useDispatch();

    const HandleEditDrawer = (event) => {
        if (editDrawerStatus) {
            setID(null);
            setEditDrawerStatus(false);
        }
    }
    const HandleEdit = (rowData) => {
        setID(rowData.id);
        setEditDrawerStatus(true);
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
        console.log(state);
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={state.all.loading ? <Spinner />: Title}
                    isLoading={state.all.loading}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            icon: () => <span className="btn btn-sm btn-theme-primary ml-2">Create a Request Status</span>,
                            tooltip: 'Create a Request Status',
                            isFreeAction: true,
                            onClick: (event, rowData) => HandleDrawerCreate()
                        },
                    ]}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...dataList]
                                    data.splice(data.indexOf(oldData), 1);
                                    setDataList(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }}
                    options={{
                        actionsColumnIndex: 0
                    }}
                />
            </div>
            <SideDrawer status={editDrawerStatus} handle={HandleEditDrawer} >
                {id && <RequestStatusEdit ID={id} />}
            </SideDrawer>
        </ThemeProvider>
    );
}

// export default Form;
export default RequestStatusGird;