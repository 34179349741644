/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Select from 'react-select';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Water } from './AccountField/Water';
import { Electric } from './AccountField/Electric';
import { useDispatch, useSelector } from 'react-redux';
import { GetDataService } from '../../Services/CrudServices';
import LoadingButton from '../../Components/Shared/LoadingButton';
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import { CreateResourceAction } from '../../Redux/Actions/CrudActions';
import MUIInputField from '../../Components/MuiFormComponents/InputBox';


export default function SubmitWorkOrderFormV2() {

    const ACTION_TYPE = "COMMON";

    const PAGE_NAME = 'Submit New Work Order';

    const BACKEND_ROUTE = "admin-submit-new-work-order"

    var fields = {
        title: '',
        level_id: '',
        comments: '',
        utility_id: '',
        customer_id: '',
        utility_service_id: '',
    }

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    const [errors, setErrors] = React.useState(fields);

    const [formData, setFormData] = React.useState(fields);

    const [accountFieldData, setAccountFieldData] = React.useState(fields);

    const [utilityLoading, setUtilityLoading] = React.useState(false);

    const [current_utility_loading, set_current_utility_loading] = React.useState('');

    const [current_utility, set_current_utility] = React.useState('');

    const [customerLoading, setCustomerLoading] = React.useState(false);

    const [allLevelsLoading, setAllLevelsLoading] = React.useState(false);

    const [utilityServicesLoading, setUtilityServicesLoading] = React.useState(false);

    const [alllevels, setAllLevels] = React.useState([]);

    const [customers, setCustomers] = React.useState([]);

    const [utilities, setUtilities] = React.useState([]);

    const [utilityServices, setUtilityServices] = React.useState([]);

    React.useEffect(() => {

        setCustomerLoading(true);
        GetDataService(`customers`).then((res) => {
            setCustomerLoading(false);
            if (res.success) {
                setCustomers(res.data.map((o) => {
                    var account_array = [];
                    o.gas_account !== null && account_array.push(o.gas_account.utility.name);
                    o.water_account !== null && account_array.push(o.water_account.utility.name);
                    o.electricity_account !== null && account_array.push(o.electricity_account.utility.name);
                    o.sewer_account !== null && account_array.push(o.sewer_account.utility.name);
                    return {
                        value: o.id,
                        label: o.full_name + ' ( '+ account_array.join(', ') +' )',
                    }
                }));
            }
        }, error => {
            setCustomerLoading(false);
            setCustomers([]);
        });

        setAllLevelsLoading(true);
        GetDataService('service-request-level').then((res) => {
            if (res.success) {
                setAllLevelsLoading(false);
                setAllLevels(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setAllLevelsLoading(false);
            setAllLevels([]);
        });

    }, []);

    // fetch utility based on customers
    React.useEffect(() => {
        if (formData.customer_id) {
            setUtilities([]);
            setAccountFieldData({});
            setUtilityLoading(true);
            setFormData({ ...formData, utility_id: '' });
            GetDataService(`customers/${formData.customer_id}`).then((customer_response) => {
                if (customer_response.success) {
                    var accounts = customer_response.data.accounts;
                    GetDataService(`utilities`).then((utility_res) => {
                        if (utility_res.success) {
                            var customerhasaccounts = utility_res.data.filter((o) => { return accounts.hasOwnProperty(o.name.toLowerCase() + "_account") }, accounts).map((o) => { return { value: o.id, label: o.name } });
                            setUtilities(customerhasaccounts);
                            setAccountFieldData(customer_response.data);
                            setUtilityLoading(false);
                        }
                    }, error => {
                        setUtilities([]);
                        setAccountFieldData({});
                        setUtilityLoading(false);
                    });
                } else {
                    setUtilities([]);
                    setAccountFieldData({});
                    setUtilityLoading(false);
                }
            }, error => {
                setUtilities([]);
                setAccountFieldData({});
                setUtilityLoading(false);
            });
        }
    }, [formData.customer_id]);


    React.useEffect(() => {
        console.log("useEffect utilities array",utilities);
        if (utilities.length === 1) {
            setFormData({ ...formData, utility_id: utilities[0].value});
        }
    }, [utilities])
    

    // fetch accounts based on customers
    React.useEffect(() => {
        if (formData.customer_id && formData.utility_id) {
            set_current_utility('');
            set_current_utility_loading(true);
            GetDataService(`customers/${formData.customer_id}`).then((res) => {
                if (res.success) {
                    switch (formData.utility_id) {
                        case 1:
                            set_current_utility('gas');
                            set_current_utility_loading(false);
                            break;
                        case 2:
                            set_current_utility('water');
                            set_current_utility_loading(false);
                            break;
                        case 3:
                            set_current_utility('electric');
                            set_current_utility_loading(false);
                            break;
                        case 4:
                            set_current_utility('sewer');
                            set_current_utility_loading(false);
                            break;
                        default:
                            set_current_utility('');
                            set_current_utility_loading(false);
                    }
                }
            }, error => {
                set_current_utility('');
                set_current_utility_loading(false);
            });
        } else {
            set_current_utility('');
            set_current_utility_loading(false);
        }
    }, [formData.customer_id, formData.utility_id]);



    // fetch utilities services by utility..
    React.useEffect(() => {
        if (formData.utility_id) {
            setUtilityServices([]);
            setUtilityServicesLoading(true);
            GetDataService(`services_by_utility/${formData.utility_id}`).then((res) => {
                if (res.success) {
                    setUtilityServices(res.data.map((o) => { return { value: o.id, label: o.name } }));
                    setUtilityServicesLoading(false);
                } else {
                    setUtilityServices([]);
                    setUtilityServicesLoading(false);
                }
            }, error => {
                setUtilityServices([]);
                setUtilityServicesLoading(false);
            });
        } else {
            setUtilityServices([]);
            setUtilityServicesLoading(false);
        }
    }, [formData.utility_id]);

    // auto select level id based on utility_services
    React.useEffect(() => {
        if (formData.utility_service_id) {
            GetDataService(`utility_services/${formData.utility_service_id}`).then((res) => {
                if (res.success) {
                    setFormData({ ...formData, level_id: res.data.level_id });
                }
            }, error => {
                setFormData({ ...formData, level_id: '' });
            });
        }
    }, [formData.utility_service_id]);

    React.useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (state.form.success) {
            setErrors(fields);
            setFormData(fields);
            set_current_utility('');
            setAccountFieldData({});
        }
    }, [state]);

    const onHandleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    };

    const onHandleAccountFieldChange = (event) => {
        setAccountFieldData({
            ...accountFieldData,
            [event.target.name]: event.target.value
        })
    };

    const HandleReactSelectBox = (selection, action) => {
        setFormData({
            ...formData,
            [action.name]: selection
                ? selection.value
                : null
        });
    };

    const HandleReactSelectBoxForUS = (selection, action) => {
        setFormData({
            ...formData,
            [action.name]: selection
                ? selection.value
                : null, title: selection.label
        });
    };

    const onhandleSubmit = (e) => {
        e.preventDefault();
        dispatch(CreateResourceAction(BACKEND_ROUTE, formData, ACTION_TYPE));
    };

    return (
        <ContentWrapper page_name={PAGE_NAME} breadcrumbs={breadcrumbs(PAGE_NAME)}>
            <form onSubmit={onhandleSubmit}>
                <div className="card rounded">
                    <div className="card-header">
                        <div className="card-title">{PAGE_NAME}</div>
                        <div className="card-tools">
                            {/* <LoadingButton className="mx-1" loading={state.form.loading} type="submit">Submit Work Order</LoadingButton> */}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-lg-5 col-lg-4 col-sm-12">
                                <fieldset>
                                    <legend>Select Customer*</legend>
                                    <div className="row" style={{ marginTop: "-10px" }}>
                                        <div className="col-md-12">
                                            <Select
                                                id="customer_id"
                                                name="customer_id"
                                                options={customers}
                                                isLoading={customerLoading}
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                closeMenuOnSelect={true}
                                                classNamePrefix="select"
                                                value={customers.map((o) => o.value === formData.customer_id && o)}
                                                placeholder={customerLoading ? "Data Loading..." : "Select Customer..."}
                                                onChange={HandleReactSelectBox}
                                                required={true}
                                            />
                                            {(errors.customer_id && errors.customer_id.length > 0) ? <span className="text-danger">Please Select Customer</span> : ''}
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Customer Information</legend>
                                    <div className="row mb-2" style={{ marginTop: "-5px" }}>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="Name"
                                                value={accountFieldData.full_name}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-1">
                                            <MUIInputField
                                                label="Phone"
                                                value={accountFieldData.mobile_number}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-5 mb-1">
                                            <MUIInputField
                                                label="Email"
                                                value={accountFieldData.email}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <MUIInputField
                                                label="Street"
                                                value={accountFieldData.street}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="City"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.city}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="State"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.state}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="Zip"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.zip}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <MUIInputField
                                                label="Country"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.country}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Service Level* & Comments</legend>
                                    <div className="row" style={{ marginTop: "-10px" }}>
                                        <div className="col-md-12 col-sm-12 mb-2">
                                            <Select
                                                id="level_id"
                                                name="level_id"
                                                options={alllevels}
                                                isLoading={allLevelsLoading}
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                closeMenuOnSelect={true}
                                                classNamePrefix="select"
                                                value={alllevels.map((o) => o.value === formData.level_id && o)}
                                                placeholder={allLevelsLoading ? "Data Loading..." : "Select Service Request Level..."}
                                                onChange={HandleReactSelectBox}
                                                required={true}
                                            />
                                            {(errors.level_id && errors.level_id.length > 0) ? <span className="text-danger">Please Select Level</span> : ''}
                                        </div>
                                        <div className="col-md-12 col-sm-12 mb-2">
                                            <textarea
                                                placeholder='Comment'
                                                className="form-control mt-2"
                                                name="comments"
                                                value={formData.comments}
                                                onChange={onHandleChange}
                                                style={{ fontSize: '14px' }}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="col-lg-7 col-md-8 col-sm-12">
                                {/* <div style={{height:"60vh",maxHeight:"60vh",overflowY:"auto"}}> */}
                                <div>
                                    <fieldset>
                                        <legend>Select Utility & Services*</legend>
                                        <div className="row" style={{ marginTop: "-10px" }}>
                                            <div className="col-md-12 col-sm-12 mb-2">
                                                <Select
                                                    id="utility_id"
                                                    name="utility_id"
                                                    options={utilities}
                                                    isLoading={utilityLoading}
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    closeMenuOnSelect={true}
                                                    classNamePrefix="select"
                                                    value={utilities.map((o) => o.value === formData.utility_id && o)}
                                                    placeholder={utilityLoading ? "Data Loading..." : "Select Utility..."}
                                                    onChange={HandleReactSelectBox}
                                                    required={true}

                                                />
                                                {(errors.utility_id && errors.utility_id.length > 0) ? <span className="text-danger">Please Select Utility</span> : ''}
                                            </div>
                                            <div className="col-md-12 col-sm-12 mb-2">
                                                <Select
                                                    id="utility_service_id"
                                                    name="utility_service_id"
                                                    options={utilityServices}
                                                    isLoading={utilityServicesLoading}
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    closeMenuOnSelect={true}
                                                    classNamePrefix="select"
                                                    value={utilityServices.map((o) => o.value === formData.utility_service_id && o)}
                                                    placeholder={utilityServicesLoading ? "Data Loading..." : "Select Utility Service..."}
                                                    onChange={HandleReactSelectBoxForUS}
                                                    required={true}
                                                />
                                                {(errors.utility_service_id && errors.utility_service_id.length > 0) ? <span className="text-danger">Please Select Utility's Service</span> : ''}
                                            </div>
                                        </div>
                                    </fieldset>
                                    {(current_utility && current_utility === 'electric') && <Electric accountFieldData={accountFieldData} onHandleAccountFieldChange={onHandleAccountFieldChange} />}
                                    {(current_utility && current_utility === 'water') && <Water accountFieldData={accountFieldData} onHandleAccountFieldChange={onHandleAccountFieldChange} />}
                                    {
                                        current_utility_loading &&
                                        <Box sx={{ width: "100%" }}>
                                            <Skeleton />
                                            <Skeleton />
                                            <Skeleton />
                                        </Box>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-content-end px-2 py-4">
                            <LoadingButton className="mx-1" loading={state.form.loading} type="submit">Submit Work-Order</LoadingButton>
                        </div>
                    </div>
                </div>
            </form>
        </ContentWrapper>
    );
}
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
