/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import MUIInputField from './../../../Components/MuiFormComponents/InputBox';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const AssignedWOCustomerModal = ({ rowData }) => {

    const [customer, setCustomer] = React.useState({});
    const [gasAccount, setGasAccount] = React.useState({});
    const [waterAccount, setWaterAccount] = React.useState({});
    const [electricAccount, setElectricAccount] = React.useState({});
    const [sewerAccount, setSewerAccount] = React.useState({});
    const [utility_id, set_utility_id] = React.useState({});

    React.useEffect(() => {
        const utility_id_ = rowData.customer_request.service_category.utility_id;
        setCustomer(rowData.customer_request.customer);
        setGasAccount(rowData.customer_request.customer.gas_account);
        setWaterAccount(rowData.customer_request.customer.water_account);
        setElectricAccount(rowData.customer_request.customer.electricity_account);
        setSewerAccount(rowData.customer_request.customer.sewer_account);
        set_utility_id(utility_id_);
    }, []);

    return (
        <div className="row">
            <div className='col-lg-6 col-sm-12'>
                <fieldset>
                    <legend>Customer Information</legend>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mb-2">
                            <MUIInputField
                                label="Name"
                                value={customer ? String(customer.full_name) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                            <MUIInputField
                                label="Phone"
                                value={customer ? String(customer.mobile_number) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 mb-2">
                            <MUIInputField
                                label="Email"
                                value={customer ? String(customer.email) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 mb-2">
                            <MUIInputField
                                label="Street"
                                value={customer ? String(customer.street) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 mb-2">
                            <MUIInputField
                                label="City"
                                value={customer ? String(customer.city) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 mb-2">
                            <MUIInputField
                                label="State"
                                value={customer ? String(customer.state) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 mb-2">
                            <MUIInputField
                                label="zip"
                                value={customer ? String(customer.zip) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 mb-2">
                            <MUIInputField
                                label="Country"
                                value={customer ? String(customer.country) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className='col-lg-6 col-sm-12'>
                <fieldset >
                    <legend>Customer Account & Meter Information</legend>
                    {utility_id === 2 &&
                        <div className="row">
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Account Number"
                                    value={waterAccount ? String(waterAccount.account) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Cycle"
                                    value={waterAccount ? waterAccount.cycle : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Route"
                                    value={waterAccount ? waterAccount.route : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Number"
                                    value={waterAccount ? waterAccount.old_meter_number : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter type"
                                    value={waterAccount ? waterAccount.old_meter_type : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Make"
                                    value={waterAccount ? waterAccount.old_meter_make : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Size"
                                    value={waterAccount ? waterAccount.old_meter_size : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Radio Number"
                                    value={waterAccount ? waterAccount.old_radio_number : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                        </div>}
                    {utility_id === 3 &&
                        <div className="row">
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Account Number"
                                    value={electricAccount ? String(electricAccount.account) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter Number"
                                    value={electricAccount ? String(electricAccount.meter) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Cycle"
                                    value={electricAccount ? String(electricAccount.cycle) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Route"
                                    value={electricAccount ? String(electricAccount.route) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Read Sequence"
                                    value={electricAccount ? String(electricAccount.read_seq ?? '') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Critical"
                                    value={electricAccount ? String(electricAccount.critical) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter Sequence Number"
                                    value={electricAccount ? String(electricAccount.meter_seq_num) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Number"
                                    value={electricAccount ? String(electricAccount.old_meter_number) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter type"
                                    value={electricAccount ? electricAccount.old_meter_type : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter Size"
                                    value={electricAccount ? electricAccount.size : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Reading Date"
                                    value={electricAccount ? String(electricAccount.read_date) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Reading"
                                    value={electricAccount ? String(electricAccount.old_meter_last_reading) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                        </div>}
                </fieldset>
            </div>
        </div>
    );

}
export default AssignedWOCustomerModal;