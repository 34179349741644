/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import Form from "./Form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const DummyCreate = () => {

    const SubmitButtonName = 'Create Dummy';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "dummies";

    var fields = {
        name: '',
        description: '',
        date: '',
    }

    const [data, setData] = useState(fields);
    const [errors, setErrors] = useState(fields);
    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.validation_errors) {
            state.form.validation_errors.map((value, key) => {
                setErrors({ ...data, [key]: value })
            });
        } else if (state.form.success) {
            setData(fields);
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            }, 1000);
            setRefreshTable(true);
        }
    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(CreateResourceAction(BACKEND_ROUTE, data, ACTION_TYPE));
    }

    return (
        <Form
            btnloading={state.form.loading}
            handleSubmit={handleSubmit}
            data={data}
            setData={setData}
            SubmitButtonName={SubmitButtonName}
            errors={errors}
        />
    );
}
export default DummyCreate;
