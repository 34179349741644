/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import UsersGrid from './Grid';
import UsersCreate from './Create';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SideDrawer from './../../Components/SideDrawer';
import { useSelector, useDispatch } from 'react-redux';
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';

export default function UsersIndex() {

    const PageName = 'Users List';
    const CreateNewButtonName = 'Create New User';
    const BACKEND_ROUTE = 'users';
    const ACTION_TYPE = "COMMON";
    const permisions = localStorage.getItem('permissions');
    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            { title: 'Id', field: 'id' },
            { title: 'User', field: 'name' },
            {
                title: 'User Status', render: rowData => <div> {
                    rowData.status === 1 ?
                        <span className="badge badge-pill badge-success">Active</span>
                        : <span className="badge badge-pill badge-danger">Inactive</span>
                }
                </div>
            },
            { title: 'Email', field: 'email' },
            { title: 'Role', field: 'role' },
            { title: 'Created At', field: 'created_at' },
            { title: 'Updated At', field: 'updated_at' },
        ]
    });

    const [data, setData] = useState([]);

    const [DrawerCreateStatus, setDrawerCreateStatus] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);

    const HandleDrawerCreate = () => {
        if (DrawerCreateStatus === false) {
            setDrawerCreateStatus(true);
        } else if (DrawerCreateStatus === true) {
            setDrawerCreateStatus(false);
        }
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
            <div className="card rounded">
                {permisions.includes('create_user') 
                    &&
                    <SideDrawer
                        status={DrawerCreateStatus}
                        action_heading={CreateNewButtonName}
                        handle={HandleDrawerCreate}>
                        {DrawerCreateStatus && <UsersCreate />}
                    </SideDrawer>
                }
                <div className="card-body p-0">
                    <UsersGrid
                        dataList={data}
                        setDataList={setData}
                        columns={tablecolumn}
                        Title={PageName}
                        HandleDrawerCreate={HandleDrawerCreate}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
