/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import CustomersGrid from './Grid';
import CustomersCreate from './Create';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import SideDrawer from '../../Components/SideDrawer';
import ContentWrapper from "../../Layouts/AdminLTE/ContentWrapper";
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';

export default function CustomersIndex(props) {

    const PageName = 'Customers';
    const CreateNewButtonName = 'Create New Customer';
    const BACKEND_ROUTE = 'customers';
    const ACTION_TYPE = "COMMON";

    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            { title: 'Customer ID', field: 'id' },
            { title: 'Customer Name', field: 'full_name' },
            {
                title: 'Customer Status', render: rowData => <div> {
                    rowData.status === 1 ?
                        <span className="badge badge-pill badge-success">Active</span>
                        : <span className="badge badge-pill badge-danger">Inactive</span>
                }
                </div>
            },
            {
                title: 'Utility Account Numbers', render: rowData =>
                    <div className="flex flex-column">
                        {'e' in rowData.accounts_string && <span className="badge badge-sm badge-pill py-2 mb-1" style={{ backgroundColor: rowData.accounts_string.e[1], color: rowData.accounts_string.e[2]}}>{rowData.accounts_string.e[0]}</span>}
                        {'w' in rowData.accounts_string && <span className="badge badge-sm badge-pill py-2 mb-1" style={{ backgroundColor: rowData.accounts_string.w[1], color: rowData.accounts_string.w[2]}}>{rowData.accounts_string.w[0]}</span>}
                        {'g' in rowData.accounts_string && <span className="badge badge-sm badge-pill py-2 mb-1" style={{ backgroundColor: rowData.accounts_string.g[1], color: rowData.accounts_string.g[2]}}>{rowData.accounts_string.g[0]}</span>}
                        {'s' in rowData.accounts_string && <span className="badge badge-sm badge-pill py-2 mb-1" style={{ backgroundColor: rowData.accounts_string.s[1], color: rowData.accounts_string.s[2]}}>{rowData.accounts_string.s[0]}</span>}
                    </div>
            },
            { title: 'Customer Address', field: 'full_address' },
            { title: 'Email', field: 'email' },
            { title: 'Phone', field: 'mobile_number' },
            { title: 'Created At', field: 'created_at' },
            { title: 'Updated At', field: 'updated_at' },
        ]
    });

    const [data, setData] = useState([]);

    const [DrawerCreateStatus, setDrawerCreateStatus] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);

    const HandleDrawerCreate = () => {
        if (DrawerCreateStatus === false) {
            setDrawerCreateStatus(true);
        } else if (DrawerCreateStatus === true) {
            setDrawerCreateStatus(false);
        }
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
            <div className="card rounded">
                <SideDrawer 
                    status={DrawerCreateStatus} 
                    action_heading={CreateNewButtonName} 
                    handle={HandleDrawerCreate}>
                    {DrawerCreateStatus && <CustomersCreate />}
                </SideDrawer>
                <div className="card-body p-0">
                    <CustomersGrid
                        dataList={data}
                        setDataList={setData}
                        columns={tablecolumn}
                        Title={PageName}
                        HandleDrawerCreate={HandleDrawerCreate}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
