
import React from 'react';
import SideBarMenuItem from './SideBarMenuItem';

const SideBarMenu = () => {

    const permissions_from_localstorage = localStorage.getItem('permissions');

    return (
        <ul className="nav nav-pills nav-sidebar flex-column  ">
            {permissions_from_localstorage.includes('view_dashboard') && <SideBarMenuItem name="Dashboard" icon="fa fa-tachometer-alt" routeName="/dashboard" />}
            {permissions_from_localstorage.includes('create_workorder') && <SideBarMenuItem name="Submit Work Orders" icon="fa-solid fa-clipboard-check" routeName="/submit-new-work-order" />}
            {permissions_from_localstorage.includes('view_workorders') && <SideBarMenuItem name="Work Orders" icon="fa-solid fa-arrow-down-short-wide" routeName="/workorders" />}
            {permissions_from_localstorage.includes('view_work_order_by_map') && <SideBarMenuItem name="Work Orders By Map" icon="fa-solid fa-map-location-dot" routeName="/workordersbymap" />}
            {permissions_from_localstorage.includes('view_complete_workorders') && <SideBarMenuItem name="Completed Work Orders" icon="fa-solid fa-list-check" routeName="/completed-work-orders" />}
            {/* {permissions_from_localstorage.includes('view_dummies') && <SideBarMenuItem name="Dummies" icon="fa-solid fa-address-card" routeName="/dummies" />} */}
            {/* {permissions_from_localstorage.includes('view_requests') && <SideBarMenuItem name="Requests" icon="fa-solid fa-pen-to-square" routeName="/requests" />} */}
            {permissions_from_localstorage.includes('view_assigned_work_order') && <SideBarMenuItem name="Assigned Work Orders" icon="fa-solid fa-user-check" routeName="/assignedworkorders" />}
            {permissions_from_localstorage.includes('view_users') && <SideBarMenuItem name="Users" icon="fa-solid fa-users-gear" routeName="/users" />}
            {permissions_from_localstorage.includes('view_teams') && <SideBarMenuItem name="Teams" icon="fa-solid fa-user-group" routeName="/teams" />}
            {permissions_from_localstorage.includes('view_customers') && <SideBarMenuItem name="Customers" icon="fa-solid fa-users-line" routeName="/customers" />}
            {permissions_from_localstorage.includes('view_locations') && <SideBarMenuItem name="Location" icon="fa-solid fa-location-dot" routeName="/locations" />}
            {permissions_from_localstorage.includes('view_reports') && <SideBarMenuItem name="Reports" icon="fa-solid fa-file-lines" routeName="/reports" />}
            {permissions_from_localstorage.includes('setting_menu') && <SideBarMenuItem name="Settings" icon="fa-solid fa-gear" routeName="/configuration" />}
            {permissions_from_localstorage.includes('master_menu') &&
            <SideBarMenuItem name="Master" icon="fa-solid fa-list">
                {permissions_from_localstorage.includes('master_menu') && <SideBarMenuItem name="Roles" icon="fa-solid fa-address-card" routeName="/roles" />}
                {permissions_from_localstorage.includes('view_utility') && <SideBarMenuItem name="Utilities" icon="fa-solid fa-screwdriver-wrench" routeName="/utilities" />}
                {permissions_from_localstorage.includes('view_utility') && <SideBarMenuItem name="Service Request Level" icon="fa-solid fa-screwdriver-wrench" routeName="/service-request-services" />}
                {/* {permissions_from_localstorage.includes('view_request_statuses') && <SideBarMenuItem name="Request Statuses" icon="fa-solid fa-circle-arrow-left" routeName="/requeststatus" />} */}
                {permissions_from_localstorage.includes('view_work_order_statuses') && <SideBarMenuItem name="Work Order Statuses" icon="fa-solid fa-file-pen" routeName="/workorderstatuses" />}
                {/* {permissions_from_localstorage.includes('view_master_form_types') && <SideBarMenuItem name="Master Form Types" icon="fa-solid fa-pen-to-square" routeName="/masterformtype" />} */}
                {permissions_from_localstorage.includes('view_master_forms') && <SideBarMenuItem name="Master Forms" icon="fa fa-file-o" routeName="/masterform" />}
            </SideBarMenuItem>}
            {permissions_from_localstorage.includes('inventory_menu') &&
            <SideBarMenuItem name="Inventory" icon="fas fa-warehouse">
                <SideBarMenuItem name="Stocks" icon="fa-solid fa-circle" routeName="/stocks" />
            </SideBarMenuItem>}
            <SideBarMenuItem name="My Account" icon="fa fa-user">
                <SideBarMenuItem name="Profile" icon="fa-solid fa-id-card-clip" routeName="/profile" />
                <SideBarMenuItem name="Change Password" icon="fa-solid fa-key" routeName='/change-password' />
                {/* <SideBarMenuItem name="Settings" icon="fa-solid fa-gear" routeName="/configuration" /> */}
            </SideBarMenuItem>
        </ul>
    );
}
export default SideBarMenu;