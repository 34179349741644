/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line array-callback-return */

import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { LoadResourceAction } from "../../Redux/Actions/CrudActions";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

function WorkOrderMap() {

  const ACTION_TYPE = "COMMON";

  const BACKEND_ROUTE = "work-orders";

  const dispatch = useDispatch();

  const state = useSelector(state => state.common);

  const [workOrders, setWorkOrders] = React.useState([]);

  const [activeMarker, setActiveMarker] = React.useState(null);

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
    return () => {
      controller.abort();
      console.log("Aborted Customer");
    }
  }, []);

  React.useEffect(() => {
    console.log(workOrders);
  }, [workOrders])

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const google = window.google;

  const geocoder = new google.maps.Geocoder();

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    workOrders.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };


  const customIcon = (opts) => Object.assign({
    path: 'M320 144c0 79.5-64.5 144-144 144S32 223.5 32 144S96.5 0 176 0s144 64.5 144 144zM176 80c8.8 0 16-7.2 16-16s-7.2-16-16-16c-53 0-96 43-96 96c0 8.8 7.2 16 16 16s16-7.2 16-16c0-35.3 28.7-64 64-64zM144 480V317.1c10.4 1.9 21.1 2.9 32 2.9s21.6-1 32-2.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32z',
    fillOpacity: 1,
    strokeWeight: 1,
    scale: 0.05
  }, opts);

  function getLatLong(address) {
    geocoder.geocode({ 'address': address }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        var latitude = results[0].geometry.location.lat();
        var longitude = results[0].geometry.location.lng();
        return [latitude, longitude]
      }
    });
  }

  React.useEffect(() => {
    if (state.all.success && state.all.list) {
      setWorkOrders(state.all.list.map((o, index) => {
        return {
          id: o.id,
          sv_title: o.customer_request.title,
          pin_color: o.current_status.color_code,
          accNum: o.customer_request.account_number,
          name: o.customer_request.customer.full_name,
          pin_color_text: o.current_status.text_color_code,
          address: o.customer_request.customer.full_address,
          utility: o.customer_request.service_category.utility.name,
          position: { lat: parseFloat(o.customer_request.customer.lat), lng: parseFloat(o.customer_request.customer.long) },
        }
      }
      ))
      dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
    }
  }, [state]);

  return (
    <GoogleMap
      zoom={24}
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ height: "100vh" }}
      {...workOrders.length > 0 && { center: workOrders[0].position }}
    >
      {workOrders.map(
        (workOrder) => (
          <Marker
            key={workOrder.id}
            position={workOrder.position}
            onClick={() => handleActiveMarker(workOrder.id)}
            onLoad={marker => {
              marker.setIcon(customIcon({
                fillColor: workOrder.pin_color,
                strokeColor: workOrder.pin_color_text
              }));
            }}
          >
            {activeMarker === workOrder.id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div style={{ borderTop: "0px solid transparent", borderLeft: "0px solid transparent", width: "auto" }}>
                  <p style={{ margin: "8px" }}><i className="fa-solid fa-gear"></i>&nbsp;&nbsp;&nbsp;Service Request - {workOrder.sv_title}</p>
                  <p style={{ margin: "8px" }}><i className="fa-solid fa-user"></i>&nbsp;&nbsp;&nbsp;Name - {workOrder.name}</p>
                  <p style={{ margin: "8px" }}><i className="fa-solid fa-building-columns"></i>&nbsp;&nbsp;&nbsp;A/C No. - {workOrder.accNum}</p>
                  <p style={{ margin: "8px" }}><i className="fa-solid fa-screwdriver-wrench"></i>&nbsp;&nbsp;&nbsp;Utility - {workOrder.utility}</p>
                  <p style={{ margin: "8px" }}><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;&nbsp;Address - {workOrder.address}</p>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
    </GoogleMap>
  );
}

export default WorkOrderMap;