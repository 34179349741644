import React, { useState } from "react";
import WorkOrderMap from "./map";
import Select, { components } from 'react-select';
import TextField from '@mui/material/TextField';
import { useLoadScript } from "@react-google-maps/api";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GetDataService } from "../../Services/CrudServices";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ContentWrapper from "../../Layouts/AdminLTE/ContentWrapper";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SelectUtility from "./SelectUtility";
import SelectStatus from "./SelectStatus";
// import MySelect from "./MySelect.js";

export default function WorkOrdersByMap() {
    const pageName = 'Work Orders By Map';

    const { isLoaded } = useLoadScript({
            googleMapsApiKey:"AIzaSyBuy8PkXAoVQWsNpBl7Wt-mlYJNde4sals" 
        });
    console.log("isLoaded",isLoaded);

    var fields = {
        statuses:'',
        to_date:null,
        utility_id:'',
        from_date:null,
        utility_service_id:'',
    }

    const [formData, setFormData] = React.useState(fields);

    const [statuses, setStatuses] = React.useState([]);

    const [statusLoading, setStatusLoading] = React.useState(false);

    const [utilityLoading, setUtilityLoading] = React.useState(false);

    const [utilityServicesLoading, setUtilityServicesLoading] = React.useState(false);

    const [utilities, setUtilities] = React.useState([]);

    const [allStatuses, setAllStatuses] = React.useState([]);

    const [utilityServices, setUtilityServices] = React.useState([]);

    React.useEffect(() => {

        setUtilityLoading(true);
        GetDataService(`utilities`).then((res) => {
            setUtilityLoading(false);
            if (res.success) {
                // console.log(res.data)
                setUtilities(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setUtilityLoading(false);
            setUtilities([]);
        });

        setStatusLoading(true);
        GetDataService(`master-work-order-statuses`).then((res) => {
            setStatusLoading(false);
            if (res.success) {
                // console.log(res.data)
                setAllStatuses(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setStatusLoading(false);
            setAllStatuses([]);
        });

        GetDataService('master-work-order-statuses').then((res) => {
            if (res.success) {
                setStatuses(res.data);
            }
        }, (error) => { });

    }, []);

    // fetch utilities services by utility..
    React.useEffect(() => {
        if (formData.utility_id) {
            setUtilityServices([]);
            setUtilityServicesLoading(true);
            GetDataService(`services_by_utility/${formData.utility_id}`).then((res) => {
                if (res.success) {
                    setUtilityServices(res.data.map((o) => { return { value: o.id, label: o.name } }));
                    setUtilityServicesLoading(false);
                } else {
                    setUtilityServices([]);
                    setUtilityServicesLoading(false);
                }
            }, error => {
                setUtilityServices([]);
                setUtilityServicesLoading(false);
            });
        } else {
            setUtilityServices([]);
            setUtilityServicesLoading(false);
        }
    }, [formData.utility_id]);

    const HandleReactSelectBox = (selection, action) => {
        setFormData({
            ...formData,
            [action.name]: selection
                ? selection.value
                : null
        });
    };

    const onHandleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    };

    const onhandleSubmit = (e) => {
        e.preventDefault();
        // dispatch(CreateResourceAction(BACKEND_ROUTE, formData, ACTION_TYPE));
    };

    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const OptionStatus = props => {
        return (
            <div>
                <components.OptionStatus {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.name}</label>
                </components.OptionStatus>
            </div>
        );
    };

    const allOption = {
        label: "Select all",
        value: "*"
    };
    const allStatusOption = {
        name: "Select all",
        id: "*"
    };

    const ValueContainer = ({ children, ...props }) => {
        const currentValues = props.getValue();
        let toBeRendered = children;
        if (currentValues.some(val => val.value === allOption.value)) {
            toBeRendered = [[children[0][0]], children[1]];
        }

        return (
            <components.ValueContainer {...props}>
                {toBeRendered}
            </components.ValueContainer>
        );
    };
    const ValueContainerStatus = ({ children, ...props }) => {
        const currentValues = props.getValue();
        let toBeRendered = children;
        if (currentValues.some(val => val.id === allStatusOption.id)) {
            toBeRendered = [[children[0][0]], children[1]];
        }

        return (
            <components.ValueContainerStatus {...props}>
                {toBeRendered}
            </components.ValueContainerStatus>
        );
    };

    const MultiValue = props => {
        let labelToBeDisplayed = `${props.data.label}, `;
        if (props.data.value === allOption.value) {
            labelToBeDisplayed = "All is selected";
        }
        return (
            <components.MultiValue {...props}>
                <span>{labelToBeDisplayed}</span>
            </components.MultiValue>
        );
    };

    const MultiValueStatus = props => {
        let labelToBeDisplayed = `${props.data.name}, `;
        if (props.data.id === allStatusOption.id) {
            labelToBeDisplayed = "All is selected";
        }
        return (
            <components.MultiValueStatus {...props}>
                <span>{labelToBeDisplayed}</span>
            </components.MultiValueStatus>
        );
    };

    const [allSelected , setAllSelected] = useState(null);
    
    const handleChangeCheckbox = selected => {
        // console.log(selected);
        setAllSelected(selected);
    };

    const [allStatusesSelected , setAllStatusesSelected] = useState(null);
    
    const handleChangeCheckboxallStatuses = selected => {
        console.log(selected);
        setAllStatusesSelected(selected);
    };

//   const animatedComponents = makeAnimated();


    return (
        <ContentWrapper page_name={pageName} breadcrumbs={breadcrumbs(pageName)}   >
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <h5 style={{ color: "#0f3f7d" }}>{pageName}</h5>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-2 col-sm-12">
                            <Select
                                id="utility_id"
                                name="utility_id"
                                isDisabled={false}
                                isClearable={true}
                                isSearchable={true}
                                options={utilities}
                                classNamePrefix="select"
                                closeMenuOnSelect={true}
                                isLoading={utilityLoading}
                                placeholder={"Select Utility"}
                                onChange={HandleReactSelectBox}
                                value={utilities.map((o) => o.value === formData.utility_id && o)}

                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            {/* <Select
                                isDisabled={false}
                                isClearable={true}
                                isSearchable={true}
                                id="utility_service_id"
                                classNamePrefix="select"
                                closeMenuOnSelect={true}
                                name="utility_service_id"
                                options={utilityServices}
                                onChange={HandleReactSelectBox}
                                isLoading={utilityServicesLoading}
                                placeholder={"Select Utility Service..."}
                                value={utilityServices.map((o) => o.value === formData.utility_service_id && o)}
                            /> */}

                            <span
                                class="d-inline-block w-100"
                                data-toggle="popover"
                                data-trigger="focus"
                                data-content="Please Select"
                                >
                                <SelectUtility
                                    options={utilityServices}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option,
                                        MultiValue,
                                        ValueContainer,
                                    }}
                                    allowSelectAll={true}
                                    isDisabled={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    id="utility_service_id"
                                    classNamePrefix="select"
                                    name="utility_service_id"
                                    onChange={handleChangeCheckbox}
                                    value={allSelected}
                                    isLoading={utilityServicesLoading}
                                    placeholder={"Select Utility Service..."}
                                />
                            </span>

                        </div>
                        <div className="col-md-3 col-sm-12">
                            {/* <Select
                                id="Statues"
                                name="statuses"
                                isDisabled={false}
                                isClearable={true}
                                isSearchable={true}
                                options={allStatuses}
                                classNamePrefix="select"
                                closeMenuOnSelect={true}
                                onChange={HandleReactSelectBox}
                                isLoading={statusLoading}
                                placeholder={"Select Status..."}
                                value={allStatuses.map((o) => o.value === formData.statuses && o)}
                            /> */}

                            <span
                                class="d-inline-block w-100"
                                data-toggle="popover"
                                data-trigger="focus"
                                data-content="Please Select"
                                >
                                <SelectStatus
                                    options={allStatuses}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        OptionStatus,
                                        MultiValueStatus,
                                        ValueContainerStatus,
                                    }}
                                    allowSelectAll={true}
                                    isDisabled={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    id="Statues"
                                    classNamePrefix="select"
                                    name="statuses"
                                    onChange={handleChangeCheckboxallStatuses}
                                    value={allStatusesSelected}
                                    isLoading={statusLoading}
                                    placeholder={"Select Status..."}
                                />
                            </span>

                        </div>
                        <div className="col-md-2 col-sm-12 work_order_by_map_from_top_margin_class">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From Date"
                                    value={formData.from_date}
                                    onChange={(newValue) => { setFormData({ ...formData, 'from_date': newValue }); }}
                                    renderInput={(params) => <TextField variant="standard" {...params} style={{marginTop:'-10px'}} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-md-2 col-sm-12 work_order_by_map_from_top_margin_class">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To Date"
                                    value={formData.to_date}
                                    onChange={(newValue) => { setFormData({ ...formData, 'to_date': newValue }); }}
                                    renderInput={(params) => <TextField variant="standard" {...params} style={{marginTop:'-10px'}} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mx-auto my-3">
                            <button className="btn btn-sm btn-theme-primary mx-2 px-5">Search</button>
                            <button className="btn btn-sm btn-danger mx-2 px-5">Reset</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mx-auto my-3 work_order_by_map_images_above_map_parent_div">
                            {statuses.map(
                                (o) => {
                                    return (
                                        <div className="work_order_by_map_images_above_map_child_div">
                                            <i className="fa-solid fa-map-pin" style={{ color: o.color_code }}></i>
                                            <p>{o.name}</p>
                                        </div>)
                                }
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {
                                isLoaded ? <WorkOrderMap /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
}
function breadcrumbs(pageName) {
    return (
        <>
            <li className="breadcrumb-item active">{pageName}</li>
        </>
    )
}
