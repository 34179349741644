/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React from 'react';
import TextField from '@mui/material/TextField';

 const TextInput = ({ label, name, className, errors = [], ...props }) => {
  return (
    <div className="form-group mb-2">
      <label htmlFor={name} className="form-label">{label}:</label>
      <input type="text" id={name} name={name} className={`form-control form-control-sm ${errors.length ? 'error' : ''}`}/>
      {errors && <div className="form-error text-danger">{errors}</div>}
    </div>
  );
};
export default TextInput;
