/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import RequestGrid from './Grid';
import RequestsCreate from './Create';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import SideDrawer from '../../Components/SideDrawer';
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import MUIInputField from './../../Components/MuiFormComponents/InputBox';

export default function RequestsIndex(props) {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = 'customer-requests';

    const CreateNewButtonName = 'Create New Request';

    const PAGEDATA = {
        name: 'Requests',
        // name: 'Pending Requests',
        createbtn: 'Create New Request',
        backendroute: 'customer-requests',
        actiontype: 'COMMON',
    };

    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            {
                title: 'Status',
                field: 'current_status.master_status.name',
                render: rowData => <span className="badge badge-pill badge-info">{rowData.current_status.master_status.name}</span>,
                lookup: {
                    "Pending": "Pending",
                    "Approve": "Approve",
                    "Approve Pending": "Approve Pending",
                    "In Process": "In Process",
                    "Complaint": "Complaint",
                    "Reassigned": "Reassigned",
                    "On Hold": "On Hold",
                    "Finished": "Finished"
                }
            },
            {
                title: 'Level',
                field: 'service_request_level.name',
                filtering: false,
                render: rowData => <span className="badge badge-pill badge-danger">{rowData.service_request_level.name}</span>,
            },
            {
                title: 'Service Request Needed',
                field: 'service_category.name',
                filterComponent: ({ columnDef, onFilterChanged }) => (
                    <MUIInputField
                        placeholder="Type..."
                        value={columnDef.tableData.filterValue}
                        onChange={(e) => {
                            onFilterChanged(columnDef.tableData.id, e.target.value);
                        }}
                    />
                )
            },
            {
                title: 'Account',
                field: 'account_number',
                filterComponent: ({ columnDef, onFilterChanged }) => (
                    <MUIInputField
                        placeholder="Type..."
                        value={columnDef.tableData.filterValue}
                        onChange={(e) => {
                            onFilterChanged(columnDef.tableData.id, e.target.value);
                        }}
                    />
                )
            },
            {
                title: 'Customer',
                field: 'customer.full_name',
                filterComponent: ({ columnDef, onFilterChanged }) => (
                    <MUIInputField
                        placeholder="Type..."
                        value={columnDef.tableData.filterValue}
                        onChange={(e) => {
                            onFilterChanged(columnDef.tableData.id, e.target.value);
                        }}
                    />
                )
            },
            {
                title: 'Customer Address',
                field: 'customer.full_address',
                filtering: false,
                filterComponent: ({ columnDef, onFilterChanged }) => (
                    <MUIInputField
                        placeholder="Type..."
                        value={columnDef.tableData.filterValue}
                        onChange={(e) => {
                            // Calling the onFilterChanged with the current tableId and the new value
                            onFilterChanged(columnDef.tableData.id, e.target.value);
                        }}
                    />
                )
            },
            { title: 'Comments', field: 'description', filtering: false },
            { title: 'Created On', field: 'created_at', type: "datetime", filtering: false },
            { title: 'Updated On', field: 'updated_at', type: "datetime", filtering: false },
        ]
    });

    const [data, setData] = useState([]);

    const [DrawerCreateStatus, setDrawerCreateStatus] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);

    const HandleDrawerCreate = () => {
        if (DrawerCreateStatus === false) {
            setDrawerCreateStatus(true);
        } else if (DrawerCreateStatus === true) {
            setDrawerCreateStatus(false);
        }
    }

    return (
        <ContentWrapper page_name={PAGEDATA.name} breadcrumbs={breadcrumbs(PAGEDATA.name)}>
            <div className="card rounded">
                <SideDrawer
                    status={DrawerCreateStatus}
                    action_heading={CreateNewButtonName}
                    handle={HandleDrawerCreate}>
                    {DrawerCreateStatus && <RequestsCreate />}
                </SideDrawer>
                <div className="card-body p-0">
                    <RequestGrid

                        dataList={data}
                        setDataList={setData}
                        columns={tablecolumn}
                        Title={PAGEDATA.name}
                        HandleDrawerCreate={HandleDrawerCreate}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
