import BreadCrumb from "../../Components/Partials/BreadCrumb";
import React from "react";

const ContentHeader = ({page_name,breadcrumbs}) => {
    return (
        // Content Header (Page header)
        <div className="content-header py-0">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <BreadCrumb>
                            {breadcrumbs}
                        </BreadCrumb>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContentHeader;