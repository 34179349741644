/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import MaterialTable from "material-table";
// import { Spinner } from '../../Components/Spinner';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from "@mui/material";
import ContentWrapper from "../../../Layouts/AdminLTE/ContentWrapper";
import { LoadResourceAction } from '../../../Redux/Actions/CrudActions';
import ComingSoon from './../../../Components/ComingSoonComponent/Index';


export default function StocksIndex(props) {

    const PageName = 'Stocks';
    const BACKEND_ROUTE = 'dummies';
    const ACTION_TYPE = "COMMON";

    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            { title: 'Id', field: 'id' },
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            { title: 'Date', field: 'date' },
            { title: 'User ID', field: 'user_id' },
            { title: 'Created At', field: 'created_at' },
            { title: 'updated At', field: 'updated_at' },
        ]
    });

    const theme = createTheme();

    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    const state = useSelector(state => state.common)

    useEffect(() => {
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        return () => { }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({ type: `RESTART_${ACTION_TYPE}_LIST` });
        }
    }, [state]);


    return (
        <ContentWrapper
            page_name={PageName}
            breadcrumbs={<li className="breadcrumb-item active">{PageName}</li>}>
            <div className="card rounded">
                <div className="card-body p-0">
                    <ComingSoon title="Stocks page is coming soon.." />
                    {/* <ThemeProvider theme={theme}>
                        <div style={{ maxWidth: '100%' }}>
                            <MaterialTable
                                columns={tablecolumn.columns}
                                data={data}
                                title={state.all.loading ? <Spinner /> : PageName}
                                isLoading={state.all.loading}
                            />
                        </div>
                    </ThemeProvider> */}
                </div>
            </div>
        </ContentWrapper>
    );
};