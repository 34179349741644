import HttpService from './HttpService';

export const IndexService = (ROUTE, AbortControllerSignal = '') => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.getData(URL, TOKEN_ID, AbortControllerSignal);
}

export const CreateService = (ROUTE, DATA, AbortControllerSignal = '') => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData(DATA, URL, TOKEN_ID, AbortControllerSignal = '');
}

export const ShowService = (ROUTE, ID, AbortControllerSignal = '') => {
    const http = new HttpService();
    let URL = `${ROUTE}/${ID}`;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.getData(URL, TOKEN_ID, AbortControllerSignal = '');
}

export const UpdateService = (ROUTE, ID, DATA, AbortControllerSignal = '') => {
    const http = new HttpService();
    const URL = `${ROUTE}/${ID}`;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData(DATA, URL, TOKEN_ID, AbortControllerSignal = '');
}

export const DeleteService = (ROUTE, ID, AbortControllerSignal = '') => {
    const http = new HttpService();
    let URL = `${ROUTE}/${ID}`;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData({ "_method": 'delete' }, URL, TOKEN_ID, AbortControllerSignal = '');
}

export const GetDataService = (ROUTE, AbortControllerSignal) => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.getData(URL, TOKEN_ID, AbortControllerSignal = '');
}

export const UpdateDataService = (ROUTE, DATA, AbortControllerSignal = '') => {
    const http = new HttpService();
    const URL = ROUTE;
    const TOKEN_ID = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData(DATA, URL, TOKEN_ID, AbortControllerSignal = '');
}
