import React, { useState } from 'react';

const TreeItem = ({ field_name, label, value, children, field_id, checked = false, onChangeHandler, class_parent, ischildren=false, child_unique_class='',module_id,...props}) => {

  const [Active, SetActive] = useState(true);

  function ToggleActiveBtnIcon(e) {
    if (Active) SetActive(false);
    if (!Active) SetActive(true);
  }

  return (
    <>
      <div className={`tree-item ${children ? 'has-children border p-2' : ''} ${Active === true ? 'active' : ''}`} style={{width: '300px', maxWidth: '300', maxHeight: '350px'}}>
        <div className={`tree-item-container ${ !children && 'ml-3'}`}>
          <div className="tree-item-icon">
            { children &&

            <i className="icon fa fa-angle-down" onClick={() => ToggleActiveBtnIcon()}></i>
            }
            <i className="icon fas fa-folder"></i>
          </div>
          <div className="checkbox">
            <input type='checkbox' id={field_id.toLowerCase()} name={field_name} className={`${child_unique_class.toLowerCase() ?? ""} ${class_parent??''}`} onChange={onChangeHandler} defaultChecked={checked} {...ischildren && {defaultValue:value,data_module_id:module_id}} {...props}/>
            <label htmlFor={field_id.toLowerCase()} className="checkbox-view"></label>
            <span htmlFor={field_id.toLowerCase()} style={{fontSize: '14px'}}>{label}</span>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};
export default TreeItem; 