/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AlertOnRecordFetch = (message) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
const AlertOnSuccess = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
const AlertOnInfo = (message) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
const AlertOnError = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
const AlertOnValidation = (message) => {
    toast.warn(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const AppAlerts = (props) => {

    const delete_state = useSelector(state=>state.common.delete);

    const form_state = useSelector(state=>state.common.form);
    
    const all_state = useSelector(state=>state.common.all);

    const code_error = useSelector(state=>state.code_error);

    // For Create and Update Form
    useEffect(() => {
        if (form_state.success === true && form_state.payload.success === true){
            AlertOnSuccess(form_state.payload.message);
        }
        if (form_state.errors && form_state.errors.validation_errors && form_state.errors.success === false){
            AlertOnValidation(form_state.errors.message);
        }
    }, [form_state]);

    // For Delete
    useEffect(() => {
        if (delete_state.success === true && delete_state.payload){
            AlertOnSuccess(delete_state.payload.message);
        }
    }, [delete_state]);

    // For Code Error
    useEffect(() => {
        if (code_error.message !== null){
            AlertOnError(code_error.message);
        }
    }, [code_error]);
    
    // // For List Fetch
    // useEffect(() => {
    //     if (all_state.success === true && all_state.message){
    //         AlertOnRecordFetch(all_state.message);
    //     }
    // }, [all_state]);
    
    return (
        <>
            <ToastContainer />
        </>
    );
}
export default AppAlerts;