import update from 'immutability-helper'
import React from 'react'
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ContentWrapper from '../../../../Layouts/AdminLTE/ContentWrapper';
import { FormGenerator } from 'dynamic-material-ui';
import * as MUI from '@material-ui/core';
import { DndProvider } from 'react-dnd'; 
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop } from "react-dnd";
import TemplateInputElement from './TemplateInputElement';
import "./MasterForm.css"
import SelectedInputElement from './SelectedInputElement';
import DynTextInput from './DynInputs/DynTextInput';
import ViewInputElement from './ViewInputElement';

export default function MasterForm() {

    const PAGENAME = 'Utility Service Master Form';

    const [pageName, setPageName] = useState(PAGENAME);
    const JSONData = [
        {
            id: 1,
            type: 'textfield',
            props: {
                id:"name",
                label:"Hello, Whats your name?",
                fullWidth:true,
                margin:"normal",
                value:"Patched Name",
                errorText:""
            },
            rules: {
                validation: [
                    {
                        rule:"mandatory",
                        message:"Name is required!!"
                    }
                ]
            },
            layout: {
                row: 1,
                xs: { col: 6 },
                sm: { col: 6 },
                md: { col: 6 },
                lg: { col: 6 }
            },
        },
        {
            id: 2,
            type: 'textfield',
            props: {
                id:"name1",
                label:"Hello, Whats your name?",
                fullWidth:true,
                margin:"normal",
                value:"",
                errorText:""
            },
            rules: {
                validation: [
                    {
                        rule:"mandatory",
                        message:"Name is required!!"
                    }
                ]
            },
            layout: {
                row: 1,
                xs: { col: 3 },
                sm: { col: 3 },
                md: { col: 3 },
                lg: { col: 3 }
            },
        }
    ];
    const [ formData, setFormData] = useState(JSONData);
    const [ displayFormErrors, setDisplayFormErrors] = useState();

    const TemplateInputElementsList = [
        { id: 1, input_type_name: "Header Text" , icon: "fas fa-heading", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 2, input_type_name: "Label" , icon: "fas fa-font", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 3, input_type_name: "Paragraph" , icon: "fas fa-paragraph", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 4, input_type_name: "Line Break" , icon: "fas fa-arrows-alt-h", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 5, input_type_name: "Dropdown" , icon: "far fa-caret-square-down", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 6, input_type_name: "Tags" , icon: "fas fa-tags", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 7, input_type_name: "Checkboxes" , icon: "far fa-check-square", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 8, input_type_name: "Multiple Choice" , icon: "far fa-dot-circle", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 9, input_type_name: "Text Input" , icon: "fas fa-font", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 10, input_type_name: "Email" , icon: "fas fa-envelope", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 11, input_type_name: "Number Input" , icon: "fas fa-plus", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 12, input_type_name: "Phone Number" , icon: "fas fa-phone", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 13, input_type_name: "Multi-line Input" , icon: "fas fa-text-height", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 14, input_type_name: "Two Column Row" , icon: "fas fa-columns", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 15, input_type_name: "Three Columns Row" , icon: "fas fa-columns", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 16, input_type_name: "Four Columns Row" , icon: "fas fa-columns", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 17, input_type_name: "Image" , icon: "far fa-image", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 18, input_type_name: "Rating" , icon: "fas fa-star", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 19, input_type_name: "Date" , icon: "far fa-calendar-alt", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 20, input_type_name: "Signature" , icon: "fas fa-pen-square", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 21, input_type_name: "Website" , icon: "fas fa-link", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 22, input_type_name: "File Attachment" , icon: "fas fa-file", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 23, input_type_name: "Range" , icon: "fas fa-sliders-h", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 24, input_type_name: "Camera" , icon: "fas fa-camera", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
        { id: 25, input_type_name: "File Upload" , icon: "fas fa-file", component: 'dy_input' , 
            input_props : {
                name : 'email',
                label : 'Email Address',
                placeholder : 'name@example.com',
                id : 'email',
                class : '',
                defaultValue : '',
                required : true
            }
        },
    ];

    const [board, setBoard] = useState([]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: "div",
        drop: (item) => addImageToBoard(item.id),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const addImageToBoard = (id) => {
        const tieList = TemplateInputElementsList.filter((tie) => id === tie.id);
        console.log(tieList,tieList[0]);

        setBoard((board) => [...board, tieList[0]]);

    };

    const moveBoardElement = useCallback((dragIndex, hoverIndex) => {
        setBoard((board) =>
            update(board, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, board[dragIndex]],
                ],
            }),
        )
    }, [])
    const updateInputProps = (index,e) => {

        const boardData = JSON.parse(JSON.stringify(board)); // deep copy of board array

        const getArray = {...boardData[index].input_props};

        const {name , value} = e.target;

        boardData[index].input_props = {...getArray, [name]:value};
  
        // console.log("old*****",board,"new*****",boardData);

        setBoard(boardData);

    }

    const deleteBoardElement = (index) => {
        console.log(index);
        board.splice(index, 1);
        setBoard([...board]);
    }

    return (
        
            <ContentWrapper pageName={pageName} breadcrumbs={breadcrumbs(pageName, `/utilities`, "Utilities")}>
                <div className="card rounded">
                    <div className="card-body p-2">
                        <FormGenerator
                            guid="simple-form" //mandatory unique id
                            data={formData}
                            displayErrors={displayFormErrors} //Displays only mandatory field errors
                            library={MUI}
                            forceUpdate={false} //Default(false) -> Force update the form data
                            patch={{ 1: 'Patched Name' }} //pass patch to update the data partially {"1": "Name"}
                            
                        />
                    </div>
                </div>
                
                <div className="card rounded">
                    <div className="d-flex">
                        <div id="form-elements" className="py-5 px-2">
                            {TemplateInputElementsList.map((TIE) => {
                                return <TemplateInputElement input_type_name={TIE.input_type_name} id={TIE.id} icon={TIE.icon}/>;
                            })}
                        </div>
                        <div id="form-generator" className="bg-light p-5" ref={drop}>
                            {board.map((TIE, index) => {
                                return <SelectedInputElement 
                                            input_type_name={TIE.input_type_name} 
                                            id={TIE.id} 
                                            icon={TIE.icon}
                                            index={index}
                                            deleteBoardElement = {deleteBoardElement}
                                            moveBoardElement={moveBoardElement}
                                            updateInputProps = {updateInputProps}
                                            component={TIE.component}
                                            input_props={TIE.input_props}
                                        />;
                            })}
                        </div>
                        
                    </div>                    
                </div>
                <div className="card rounded">
                    <h1>Form OutPut</h1>
                    {board.map((TIE, index) => {
                        return <ViewInputElement
                            input_type_name={TIE.input_type_name}
                            id={TIE.id}
                            icon={TIE.icon}
                            index={index}
                            component={TIE.component}
                            input_props={TIE.input_props}
                        />;
                    })}
                </div>
            </ContentWrapper>
        
    )
}

function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {
                IndexPageName && 
                    <li className="breadcrumb-item">
                        <NavLink to={IndexRoute}>{IndexPageName}</NavLink>
                    </li>
            }
            <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>Utility Services</NavLink>
            </li>

            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
