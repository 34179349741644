/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetDataService } from "../../Services/CrudServices";
import LoadingButton from '../../Components/Shared/LoadingButton';
import MUIDropDown from "../../Components/MuiFormComponents/SelectBox";
import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import { checkElementInArray, removeElementFromArray } from "../../Helper/Helper";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const RequestsForm = ({ handleSubmit, data, setData, SubmitButtonName, errors, btnloading }) => {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "customer-requests";

    const [Customers, setCustomers] = useState([]);

    const [subCategories, setSubCategories] = useState([]);

    const [serviceDepartments, setServiceDepartments] = useState([]);

    const [loadingCustomers, setLoadingCustomers] = React.useState(false);

    const [loadingDepartments, setLoadingDepartments] = React.useState(false);

    const [loadingSubCategories, setLoadingSubCategories] = React.useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });

        setLoadingCustomers(true);
        GetDataService('customers').then((res) => {
            setLoadingCustomers(false);
            setCustomers(res.data);
        }, error => {
            setCustomers([]);
            setLoadingCustomers(false);
        });

        setLoadingDepartments(true);
        GetDataService('departments').then((res) => {
            setLoadingDepartments(false);
            if (res.success) {
                setServiceDepartments(res.data);
            }
        }, error => {
            setLoadingDepartments(false);
            setServiceDepartments([]);
        });

        if (data.department_id) {
            setLoadingSubCategories(true);
            GetDataService(`departments/${data.department_id}/sub-departments`).then((res) => {
                setLoadingSubCategories(false);
                if (res.success) {
                    setSubCategories(res.data);
                }
            }, error => {
                setLoadingSubCategories(false);
                setSubCategories([]);
            });
        }
    }, []);

    const onHandleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
        if (event.target.name === 'department_id' && event.target.value) {
            var department_id = event.target.value
            setLoadingSubCategories(true);
            GetDataService(`departments/${department_id}/sub-departments`).then((res) => {
                setLoadingSubCategories(false);
                if (res.success) {
                    setSubCategories(res.data.sub_departments);
                    setLoadingSubCategories(false);
                }  else {
                    setLoadingSubCategories(false);
                    setSubCategories([]);
                }
            }, error => {
                setLoadingSubCategories(false);
                setSubCategories([]);
            });
        }
    };
    
    const onHandleMultiSelectionCheckBox = (event) => {

        if (event.target.checked === true ) {
            if (checkElementInArray(data.status1,event.target.value)) {
                data.status1.push(event.target.value);
            }
        } else {
            removeElementFromArray(data.status1,event.target.value)
        }
        console.log(data.status1);
    }

    const onHandleCheckboxBooleanChange = (event) => {
        if (event.target.checked) {
            setData({ ...data, [event.target.name]: true});
        } else {
            console.log(event.target.name, event.target.checked);
            setData({ ...data, [event.target.name]: false});
        }
        console.log(data);
    };
    const onHandleCheckboxValueChange = (event) => {
        if (event.target.checked) {
            console.log("Checked Value", event.target.value,event.target.checked);
            setData({ ...data, [event.target.name]: event.target.value})
        } else {
            setData({ ...data, [event.target.name]: event.target.value})
        }
        console.log(data);
    };

    return (

        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                <MUIInputField
                    label="Title"
                    name="title"
                    value={data.title}
                    onChange={onHandleChange}
                    validation_error={errors.title}
                    required
                />
                <MUIInputField
                    label="Description"
                    name="description"
                    value={data.description}
                    onChange={onHandleChange}
                    validation_error={errors.description}
                    required
                />
                <MUIDropDown
                    label="Customer"
                    name="customer_id"
                    value={loadingCustomers ? '' : data.customer_id}
                    validation_error={errors.customer_id}
                    onChangeHandler={onHandleChange}
                    loading={loadingCustomers}
                    required={true}
                >
                    {Customers.map((customer, i) => <MenuItem key={i} value={customer.id}>{customer.full_name}</MenuItem>)}
                </MUIDropDown>

                <MUIDropDown
                    label="Utility"
                    name="department_id"
                    value={loadingDepartments ? '' : data.department_id}
                    validation_error={errors.department_id}
                    onChangeHandler={onHandleChange}
                    loading={loadingDepartments}
                    required={true}>
                    {serviceDepartments.map((option, i) => <MenuItem key={i} value={option.id}>{option.name}</MenuItem>)}
                </MUIDropDown>

                <MUIDropDown
                    label="Service"
                    name="service_category_id"
                    value={loadingSubCategories ? '' : data.service_category_id}
                    validation_error={errors.service_category_id}
                    onChangeHandler={onHandleChange}
                    loading={loadingSubCategories}
                    required={true}>
                    {subCategories.map((option, i) => <MenuItem key={i} value={option.id}>{option.name}</MenuItem>)}
                </MUIDropDown>

                {/* <MUIGroupCheckbox label="Checkbox" name="status" validation_error={false} required={true}>
                    <MUIInputCheckBox name="status1" value="1" onChangeHandler={onHandleMultiSelectionCheckBox}>A</MUIInputCheckBox>
                    <MUIInputCheckBox name="status1" value="2" onChangeHandler={onHandleMultiSelectionCheckBox}>B</MUIInputCheckBox>
                    <MUIInputCheckBox name="status1" value="3" onChangeHandler={onHandleMultiSelectionCheckBox}>C</MUIInputCheckBox>
                    <MUIInputCheckBox name="status1" value="1" onChangeHandler={onHandleCheckboxBooleanChange}>B</MUIInputCheckBox>
                </MUIGroupCheckbox> */}

                {/* <MUIGroupCheckbox label="Checkbox 2" name="status2" validation_error={false} required={true}>
                    <MUIInputCheckBox name="status2[]" value="0" onChangeHandler={onHandleCheckboxValueChange}>C</MUIInputCheckBox>
                    <MUIInputCheckBox name="status2[]" value="1" onChangeHandler={onHandleCheckboxValueChange}>D</MUIInputCheckBox>
                </MUIGroupCheckbox> */}

                {/* <MUIGroupRadio name="statusradior" label="Radio 1" value={data.statusradior} validation_error={false} onChangeHandler={onHandleCheckboxValueChange} required={true}>
                    <MUIInputRadio value="1">Status22</MUIInputRadio>
                    <MUIInputRadio value="2">Status23</MUIInputRadio>
                </MUIGroupRadio> */}

                {/* <DropDown 
                    className="w-full p-2 lg:w-100"
                    label="Service Category"
                    name="service_category_id"
                    onChange={onHandleChange}
                    value={data.service_category_id}
                    loading={loading}
                    required
                >
                    {
                        serviceCategory.map( (value,key ) => {
                            return(
                                <option key={key} value={value.id} >{value.name}</option>                              
                            )
                        })
                    }
                </DropDown> */}
            </div>
            <div className="flex items-center justify-content-end border-top pt-2">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>

    );
}

export default RequestsForm;