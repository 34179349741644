/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import Grid from './Grid';
import LocationCreate from './Create';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SideDrawer from './../../Components/SideDrawer';
import { useSelector, useDispatch } from 'react-redux';
import ContentWrapper from '../../Layouts/AdminLTE/ContentWrapper';
import { LoadResourceAction } from '../../Redux/Actions/CrudActions';

export default function LocationIndex(props) {

    const ACTION_TYPE = "COMMON";

    const PageName = 'Location';
    
    const BACKEND_ROUTE = 'locations';

    const CreateNewButtonName = 'Create New Location';
    
    const [tablecolumn, setTablecolumn] = useState({
        columns: [
            { title: 'Id', field: 'id' },
            { title: 'Name', field: 'name' },
            { title: 'Location Status', render: rowData => <div> {
                rowData.status === 1 ? 
                <span className="badge badge-pill badge-success">Active</span> 
                : <span className="badge badge-pill badge-danger">Inactive</span>
            }
            </div> },
            { title: 'created At', field: 'created_at' },
            { title: 'updated At', field: 'updated_at' },
        ]
    });

    const [data, setData] = useState([]);

    const [DrawerCreateStatus, setDrawerCreateStatus] = useState(false);

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE, signal));
        return () => {
            controller.abort();
            console.log("Aborted Customer");
        }
    }, []);

    useEffect(() => {
        if (state.all.success && state.all.list) {
            setData(state.all.list);
            dispatch({type:`RESTART_${ACTION_TYPE}_LIST`});
        }
    }, [state]);

    const HandleDrawerCreate = () => {
        if (DrawerCreateStatus === false) {
            setDrawerCreateStatus(true);
        } else if (DrawerCreateStatus === true) {
            setDrawerCreateStatus(false);
        }
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName)}>
            <div className="card rounded">
                <SideDrawer 
                    status={DrawerCreateStatus} 
                    action_heading={CreateNewButtonName} 
                    handle={HandleDrawerCreate}>
                    {DrawerCreateStatus && <LocationCreate />}
                </SideDrawer>
                <div className="card-body p-0">
                    <Grid
                        dataList={data}
                        setDataList={setData}
                        columns={tablecolumn}
                        Title={PageName}
                        HandleDrawerCreate={HandleDrawerCreate}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
};
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item">
                <NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
