/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import SubDepartmentForm from "./Form";
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../../../../Redux/Actions/CrudActions";

const SubDepartmentCreate = () => {

    const { department_id,utility_id } = useParams(); 

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = 'utility_sub_departments';

    const SUBMIT_BUTTON_NAME = 'Create a Sub-Department';

    const LIST_BACKEND_ROUTE = `sub_departments_by_department/${department_id}`;

    var fields = { name: '', description: '', status: '', parent_id:department_id, utility_id:utility_id }

    const [errors, setErrors] = useState(fields);

    const [formData, setFormData] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    useEffect(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(LIST_BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success) {
            setFormData(fields);
            setErrors(fields);
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            setRefreshTable(true);
        }

    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(CreateResourceAction(BACKEND_ROUTE, formData, ACTION_TYPE));
    }

    return (
        <SubDepartmentForm
            data={formData}
            errors={errors}
            setData={setFormData}
            handleSubmit={handleSubmit}
            btnloading={state.form.loading}
            SubmitButtonName={SUBMIT_BUTTON_NAME}
        />
    );
}

export default SubDepartmentCreate;
