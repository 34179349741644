import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { GetDataService } from '../../../Services/CrudServices';
import Tree from '../../../Components/Partials/Tree';
import { Spinner } from '../../../Components/Spinner';
import TreeItem from '../../../Components/Partials/TreeItem';
import LoadingButton from '../../../Components/Shared/LoadingButton';
import MUIInputField from '../../../Components/MuiFormComponents/InputBox';
import ContentWrapper from "../../../Layouts/AdminLTE/ContentWrapper";

const RolesStatuses = () => {
    const IndexRoute = "/roles"
    const IndexPageName = 'Role';
    const PageName = 'Assigned Work Order Status';
    const SubmitButtonName = 'Create Role';
    const BACKEND_ROUTE = "master-work-order-statuses";
    const ACTION_TYPE = "COMMON";
    const [workOrderStatuses, setWorkOrderStatuses] = useState([]);
    const dispatch = useDispatch();
    const state = useSelector(state => state.common);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(true);
    const onHandleMultiSelectionCheckBox = (event) => {

    }
    useEffect(() => {
        GetDataService(BACKEND_ROUTE).then((res) => {
            setLoading(false);
            setWorkOrderStatuses(res.data);
        }, (error) => {
            setLoading(false);
            setWorkOrderStatuses([]);
        }
        );
    }, []);
  
 

    function handleSubmit(e) {
        e.preventDefault();
        
    }

    
    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName, IndexRoute, IndexPageName)}>
            <div className="card rounded shadow">
                <div className="card-header">
                    <h3 className="card-title">{PageName}</h3>
                </div>
                <div className="card-body pt-0">
                    <div className="flex flex-wrap">
                        <div className='w-100 mt-3'>
                            {workOrderStatuses.map(function (module, m_index) {

                                return (
                                    <div className="flex flex-wrap">
                                        <TreeItem
                                            key={m_index}
                                            checked={false}
                                            label={module.name}
                                            class_parent="parent"
                                            field_id={`${module.id}`}

                                            onChangeHandler={onHandleMultiSelectionCheckBox}
                                        >

                                        </TreeItem>
                                    </div>
                                );

                            })}
                        </div></div>
                </div>
            </div>
        </ContentWrapper>
    );


}
function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item"><NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}

export default RolesStatuses;