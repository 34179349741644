/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
export default ({children}) => {
  return (
    <>
    <div className="control-group tree-wrapper">
        <div className="tree-container d-flex flex-wrap">
        {children}
        </div>
    </div>
    </>
  );
};
