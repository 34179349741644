import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MUIInputField from '../../../Components/MuiFormComponents/InputBox';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

export const Electric = ({ accountFieldData, onHandleAccountFieldChange }) => {
    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <legend>Location Information</legend>
                </AccordionSummary>
                <AccordionDetails>
                    <fieldset>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Account Number"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.account) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Cycle"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.cycle) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter Number"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.meter) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Route"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.route) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Read Sequence"
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.read_seq ?? '') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter Sequence Number"
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.meter_seq_num ?? '') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Premise"
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.premise ?? '') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                        </div>
                    </fieldset>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <legend>Meter Information</legend>
                </AccordionSummary>
                <AccordionDetails>
                    <fieldset>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Number"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.old_meter_number) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Type"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.old_meter_type) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter Size"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.size) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Meter AMPS"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.amps) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Critical"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.critical ?? '-') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                        </div>
                    </fieldset>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <legend>Meter Reading</legend>
                </AccordionSummary>
                <AccordionDetails>
                    <fieldset>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Last Reading"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.old_meter_last_reading) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="Old Meter Reading Date"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.read_date) : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                        </div>
                    </fieldset>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <legend>Comments about account</legend>
                </AccordionSummary>
                <AccordionDetails>
                    <fieldset>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="read_instr_1"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.read_instr_1 ?? '-') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="read_instr_2"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.read_instr_2 ?? '-') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12 mb-2">
                                <MUIInputField
                                    label="read_instr_3"
                                    onChange={onHandleAccountFieldChange}
                                    value={accountFieldData.electricity_account ? String(accountFieldData.electricity_account.read_instr_3 ?? '-') : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                        </div>
                    </fieldset>
                </AccordionDetails>
            </Accordion>
        </>
    )
}
