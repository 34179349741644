import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function Spinner({color="text-dark"}) {
    return (
        <div className="text-center">
            <div className={`spinner-border spinner-border-sm ${color}`}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}
export function SpinnerGrow({color="text-dark"}) {
    return (
        <div className="text-center">
            <div className={`spinner-grow spinner-grow-sm ${color}`}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}
export function SpinnerFullPageOverlay({open_state}) {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open_state}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

