import Form from "./Form";
import React,{useState} from "react";
import { NavLink, useParams } from 'react-router-dom';
import ContentWrapper from '../../../Layouts/AdminLTE/ContentWrapper';
import MasterFormForm from "./Form";
import { useSelector } from "react-redux";

const MasterFormCreate = () => {

    const IndexPageName = 'Master form';
    const PageName = 'Create New Master Form';
    const SubmitButtonName = 'Create Master Form';

    const IndexRoute = '/masterform';

    const { utility_id } = useParams(); 

    const [data, setData] = useState({
        name: '',
        description: '',
        date: '',
    });

    const SUBMIT_BUTTON_NAME = 'Create a Master Form';

    // const [errors, setErrors] = useState({
    //     name: '',
    //     description: '',
    //     date: '',
    // });

    const LIST_BACKEND_ROUTE = `services_by_utility/${utility_id}`;

    var fields = {name: '', description: '', status: '', utility_id:utility_id, utility_department_id:"", level_id: ''}

    const [errors, setErrors] = useState(fields);

    const [formData, setFormData] = useState(fields);

    const state = useSelector(state => state.common);

    function handleSubmit(e) {
        e.preventDefault();
        // console.log(data);
    }

    return (
        // <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName,IndexRoute,IndexPageName)} >
        //     <div className="card">
        //         <div className="card-header">
        //             <h3 className="card-title">{PageName}</h3>
        //         </div>
        //         <div className="card-body">
        //             <Form handleSubmit={handleSubmit} data={data} setData={setData} SubmitButtonName={SubmitButtonName} errors={errors}/>
        //         </div>
        //     </div>
        // </ContentWrapper>
        
        <MasterFormForm
            data={formData}
            errors={errors}
            setData={setFormData}
            handleSubmit={handleSubmit}
            btnloading={state.form.loading}
            SubmitButtonName={SUBMIT_BUTTON_NAME}
        />
    );
}
function breadcrumbs(PageName,IndexRoute=false,IndexPageName=false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item"><NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}
export default MasterFormCreate;
