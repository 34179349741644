/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import { Link, NavLink } from 'react-router-dom';
import '../Home/Home.css';
import { useOutlet } from 'react-router-dom';

function Home() {
    const outlet = useOutlet();
	const user = localStorage.getItem(process.env.REACT_APP_BACKEND_TOKEN_NAME);
    return (
        <>
            <nav className="navbar fixed-top navbar-expand-lg nav-header">
                <div className="container-fluid">
                    <NavLink className="navbar-brand text-white fw-bold navName" to="/"><span className='text-warning'>D</span>DWOMS</NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                {
                                    user ?
                                    <>
                                        <NavLink className="nav-link navMenu" aria-current="page" to="/dashboard">DASHBOARD</NavLink>
                                    </>
                                    :
                                    <>
                                        <NavLink className="nav-link navMenu" aria-current="page" to="/login">LOGIN</NavLink>
                                    </>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container-fluid bannerImg">
                <div className='flexDiv'>
                    <center>
                        <p className="text-white fw-bold bannerHeading">We are <span className="text-warning">DDWOMS</span></p>
                        {
                            user ?
                            <>
                                <p className="text-white fw-bold bannerSubHeading">You are already logged in. Click to go to Dashboard !</p>
                                <NavLink className="text-white mt-3 contactBtn" to="/dashboard">DASHBOARD</NavLink>
                            </>
                            :
                            <>
                                <p className="text-white fw-bold bannerSubHeading">You are not logged in, Please Click to Login !</p>
                                <NavLink className="text-white mt-3 contactBtn" to="/login">LOGIN</NavLink>
                            </>
                        }
                        {/* <p className="text-white fw-bold bannerSubHeading">We build Premium HTML Template for your business</p>
                        <button className="text-white mt-3 contactBtn">CONTACT
                            US</button> */}
                    </center>
                </div>
            </div>
        </>
    )
}
export default Home;