/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import './Guest.css';
import Alert from '@mui/material/Alert';
import Guest from '../../Layouts/Guest';
import React, { useState, } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import { LoginAction } from '../../Redux/Actions/AuthActions';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from './../../Components/Shared/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, FormHelperText, InputAdornment, OutlinedInput } from '@mui/material';


export default function Login() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector(state => state.userAuth);
    const [data, setData] = useState({
        email: '',
        password: '',
        remember: '',
    });
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        remember: '',
        message: '',
    });
    const [showPassword, setShowPassword] = React.useState({
        status: false,
    });
    const onHandleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            message: ""
        });
    };
    const handleClickShowPassword = () => {
        setShowPassword({
            ...showPassword,
            status: !showPassword.status,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleChangeCheckbox = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.checked
        });
    };
    const submit = (e) => {
        e.preventDefault();
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })
        dispatch(LoginAction(data, navigate));
    };
    React.useEffect(() => {
        if (!state.authResponse.status) {
            setErrors({
                ...errors,
                message: state.authResponse.message
            })
        }
    }, [state]);
    return (
        <Guest>
            <form onSubmit={submit} autoComplete={"off"}>
                <div className="text-muted text-center h5 mb-2">Log in</div>
                <div className="text-muted text-center h5 mb-4">
                    {errors.message && <Alert severity="error" color="error">{errors.message}! please check your credentials</Alert>}
                </div>
                <div className="d-block form-group mb-4">
                    <FormControl sx={{ width: '100%' }} variant="outlined" {...errors.email ? { error: true } : { error: false }} required>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <OutlinedInput
                            id="email"
                            name="email"
                            value={data.email}
                            onChange={onHandleChange}
                            label="Email"
                            required
                            {...errors.email ? { error: true } : { error: false }}
                        />
                        {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                    </FormControl>
                </div>
                <div className="d-block form-group">
                    <FormControl sx={{ width: '100%' }} variant="outlined" {...errors.password ? { error: true } : { error: false }} required>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            name="password"
                            type={showPassword.status ? 'text' : 'password'}
                            value={data.password}
                            onChange={onHandleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end">
                                        {showPassword.status ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            {...errors.password ? { error: true } : { error: false }}
                            required
                            label="Password"
                        />
                        {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
                    </FormControl>
                </div>
                <div className="d-block form-group">
                    <FormGroup>
                        <FormControlLabel name="remember" onChange={handleChangeCheckbox} control={<Checkbox />} label="Remember me" />
                    </FormGroup>
                </div>
                <div className="flex items-center justify-content-between form-group">
                    <LoadingButton className="mr-4 px-5" loading={state.loading}>Log in</LoadingButton>
                    <NavLink to="/" className="underline text-sm text-gray-600 hover:text-gray-900">Forgot your password?</NavLink>
                </div>
            </form>
        </Guest>
    );
}
