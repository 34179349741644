/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "../../Components/Shared/LoadingButton";
import FormSkeleton from "../../Components/Skeleton/FormSkeleton";
import ContentWrapper from "../../Layouts/AdminLTE/ContentWrapper";
import { LoadProfileAction } from '../../Redux/Actions/ProfileActions';
import { UpdateResourceAction } from "../../Redux/Actions/CrudActions";
import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SettingsSidebar from "../../Components/Partials/SettingsSidebar";
import TabSidePanel from "../../Components/SettingsTabPanels/TabSidePanel";
import ComingSoon from "../../Components/ComingSoonComponent/Index";
// import PanelForm from "../../Components/SettingsTabPanels/PanelForm";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Configuration(props) {

    const pageName = 'Settings';

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "users";

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const profile_state = useSelector(state => state.userDetails);

    const common_state = useSelector(state => state.common);

    const [ID, setID] = useState('');

    var fields = { name: "", email: "", role_id: "", _method: 'PUT' }

    const dispatch = useDispatch();

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    React.useEffect(() => {
        if (!profile_state.loading && profile_state.success) {
            setData({
                ...data,
                'name': profile_state.userProfile.name,
                'email': profile_state.userProfile.email,
                'role': profile_state.role.name,
            });
            setID(profile_state.userProfile.id);
        }
    }, [profile_state]);

    React.useEffect(() => {
        var val_error_state = common_state.form.errors;
        if (val_error_state && val_error_state.validation_errors) {
            const errors = val_error_state.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
        if (common_state.form.success) {
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
                dispatch(LoadProfileAction());
            }, 2000);
        }
    }, [common_state]);

    return (
        <ContentWrapper page_name={pageName} breadcrumbs={breadcrumbs(pageName)}>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{pageName}</h3>
                    {/* <div className="card-tools">
                        <button className="btn btn-theme-primary ml-2">Save</button>
                    </div> */}
                </div>
                <div className="card-body">
                <ComingSoon title="Configuration Settings page is coming soon.." />
                    {/* {profile_state.loading === false && profile_state.success
                        ?
                        <div className="row">
                            <div className="col-md-12">
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Site Settings" {...a11yProps(0)} />
                                            <Tab label="Admin Portal Brand Name" {...a11yProps(1)} />
                                            <Tab label="Logo" {...a11yProps(2)} />
                                            <Tab label="Site Menu" {...a11yProps(3)} />
                                            <Tab label="Configurations" {...a11yProps(4)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TabSidePanel />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        Admin Portal Brand Name
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        Logo
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        Sidemenus
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        Configurations
                                    </TabPanel>
                                </Box>
                            </div>
                        </div>
                        : <FormSkeleton />} */}
                </div>
            </div>
        </ContentWrapper>
    );
}
function breadcrumbs(pageName) {
    return (
        <>
            <li className="breadcrumb-item active">{pageName}</li>
        </>
    )
}