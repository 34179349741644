import React from 'react';
import './ComingSoon.css';

export default function ComingSoon({title}) {
    const pageName = 'Dashboard';
    return (
        <div className='mainDiv' style={{height:"79vh"}}>
            <p className='text'>{title}</p>
        </div>
    );
}