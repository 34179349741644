import React from 'react';
import './Skeleton.css';
export default function FormSkeleton() {
    return (
        <>
            <div className='skeleton-wrapper'>
                {/* <div className="skeleton round"></div> */}

                <div style={{ 'display': 'flex', 'gap': '5px', 'marginBottom':'15px' }}>
                    <div className="skeleton mid100"></div>
                </div>

                <div style={{ 'display': 'flex', 'gap': '5px', 'marginBottom':'15px' }}>
                    <div className="skeleton mid100"></div>
                </div>

                <div style={{ 'display': 'flex', 'gap': '5px', 'marginBottom':'15px' }}>
                    <div className="skeleton mid100"></div>
                </div>

                <div style={{ 'display': 'flex', 'gap': '5px', 'marginBottom':'15px' }}>
                    <div className="skeleton mid100"></div>
                </div>

                <div style={{ 'display': 'flex', 'gap': '5px', 'marginBottom':'15px' }}>
                    <div className="skeleton submitBtn"></div>
                </div>



                {/* <div className="skeleton"></div> */}
                {/* <div className="skeleton"></div>
                <div className="skeleton"></div> */}
            </div>
        </>
    )
}