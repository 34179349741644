/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import ServiceRequestLevelForm from "./Form";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { LoadResourceAction, ShowResourceAction, UpdateResourceAction } from '../../../Redux/Actions/CrudActions';
import FormSkeleton from "../../../Components/Skeleton/FormSkeleton";

const ServiceRequestLevelEdit = ({ ID }) => {

    
    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "service-request-level";

    const SUBMIT_BUTTON_NAME = 'Update Service Request Level';

    var fields = { name: '', color_code: '', description: '', status: '', _method: 'PUT' }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    useEffect(() => {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(ShowResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    function show_page_form(edit_id = ID) {
        dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        dispatch(ShowResourceAction(BACKEND_ROUTE, edit_id, ACTION_TYPE));
    }

    useEffect(() => {
        show_page_form();
    }, []);

    useEffect(() => {

        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success && state.form.payload) {
            setErrors(fields);
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            setTimeout(() => { show_page_form(); }, 1000);
            setRefreshTable(true);
        }

        if (state.show.success && state.show.payload) {
            setData({
                "_method": "PUT",
                'name': state.show.payload.data.name ?? '',
                'description': state.show.payload.data.description ?? '',
                'color_code': state.show.payload.data.color_code ?? '',
                'status': state.show.payload.data.status ?? '',
            });
            dispatch({ type: `RESTART_${ACTION_TYPE}_SHOW` });
        }
    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <div>
            {
            state.show.loading 
                ? <FormSkeleton />
                : <ServiceRequestLevelForm
                    data={data}
                    errors={errors}
                    setData={setData}
                    handleSubmit={handleSubmit}
                    btnloading={state.form.loading}
                    SubmitButtonName={SUBMIT_BUTTON_NAME}
                />
            }
        </div>
    );
}

export default ServiceRequestLevelEdit;