import React from 'react';
import 'nprogress/nprogress.css';
import nprogress from 'nprogress';
import Home from './Pages/Home/Home';
import Login from "../src/Pages/Auth/Login";
import Guard, { GuestGuard } from './Guard';
import DummyIndex from './Pages/Dummy/Index';
import Register from './Pages/Auth/Register';
import UsersIndex from './Pages/Users/Index';
import TeamsIndex from './Pages/Teams/Index';
import Profile from './Pages/MyAccount/Profile';
import ErrorPage from "../src/Pages/Error/Index";
import ReportsIndex from './Pages/Reports/Index';
import RolesEdit from './Pages/Master/Roles/Edit';
import RolesStatuses from './Pages/Master/Roles/Statuses';
import SettingsIndex from './Pages/Settings/Index';
import LocationIndex from './Pages/Location/Index';
import RequestsIndex from './Pages/Requests/Index';
import Dashboard from './Pages/Dashboard/Dashboard';
import RolesIndex from './Pages/Master/Roles/Index';
import Authenticated from './Layouts/Authenticated';
import CustomersIndex from './Pages/Customers/Index';
import RolesCreate from './Pages/Master/Roles/Create';
import WorkOrdersIndex from './Pages/WorkOrders/WorkOrderIndex';
import Configuration from './Pages/MyAccount/Configuration';
import WOSIndex from './Pages/Master/WorkOrderStatus/Index';
import UtilitiesIndex from './Pages/Master/Utilities/Index';
import MasterFormIndex from './Pages/Master/MasterForm/Index';
import MasterFormCreate from './Pages/Master/MasterForm/Create';
import { Routes, Route, useLocation } from 'react-router-dom';
import FormTypeIndex from './Pages/Master/MasterFormType/Index';
import RequestStatusIndex from './Pages/Master/RequestStatus/Index';
import AssignedWorkOrdersIndex from './Pages/WorkOrders/AssignedWorkOrdersIndex';
import UtilitySevicesIndex from './Pages/Master/Utilities/Services/Index';
import UtilitySeviceMasterForm from './Pages/Master/Utilities/Services/MasterForm';
import UtilityDepartmentIndex from './Pages/Master/Utilities/Departments/Index';
import ServiceRequestLevelIndex from './Pages/Master/ServiceRequestLevel/Index';
import SubDepartmentIndex from './Pages/Master/Utilities/Departments/SubDepartments/Index';
import UserPermissionForm from './Pages/Users/UserRights/Permission';
import SubmitWorkOrderForm from './Pages/WorkOrders/SubmitWorkOrderForm';
import SubmitWorkOrderFormV2 from './Pages/WorkOrders/SubmitWorkOrderFormV2';
import CompletedWorkOrder from './Pages/WorkOrders/CompletedWorkOrder';
import WorkOrdersByMap from './Pages/WorkOrdersByMap/Index';
import CreateMasterForm from './Pages/Master/MasterForm/CreateMasterForm';
import FieldMasterIndex from './Pages/Master/FieldMaster/Index';
import ChangePassword from './Pages/MyAccount/ChangePassword';
import StocksIndex from './Pages/Inventory/Stocks/Index';

const AppRoutes = () => {
    let location = useLocation();
    React.useEffect(() => {
        nprogress.start();
        nprogress.done();
    }, [location.pathname]);
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            {/* Auth Routes */}
            <Route path="/" element={<GuestGuard />}>
                <Route index path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
            </Route>
            {/* Authenticated Routes */} 
            <Route element={<Guard />}>
                {/* Admin Routes */}
                <Route element={<Authenticated />}>
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/change-password" element={<ChangePassword />} />
                    <Route exact path="/configuration" element={<Configuration />} />
                    <Route exact path="/dummies" element={<DummyIndex />} />
                    <Route exact path="/roles" element={<RolesIndex />} />
                    <Route exact path="/roles/create" element={<RolesCreate />} />
                    <Route exact path="/roles/edit/:id" element={<RolesEdit />} />
                    <Route exact path="/roles/statuses" element={<RolesStatuses />} />
                    <Route exact path="/requeststatus" element={<RequestStatusIndex />} />
                    <Route exact path="/workorderstatuses" element={<WOSIndex />} />
                    <Route exact path="/utilities" element={<UtilitiesIndex />} />
                    <Route exact path="/utilities/:utility_id/departments" element={<UtilityDepartmentIndex />} />
                    <Route exact path="/utilities/:utility_id/departments/:department_id/sub_departments" element={<SubDepartmentIndex />} />
                    <Route exact path="/utilities/:utility_id/services" element={<UtilitySevicesIndex />} />
                    <Route exact path="/utilities/:utility_id/services/:utility_service_id/master-form" element={<UtilitySeviceMasterForm />} />
                    <Route exact path="/service-request-services" element={<ServiceRequestLevelIndex />} />
                    <Route exact path="/reports" element={<ReportsIndex />} />
                    <Route exact path="/stocks" element={<StocksIndex />} />
                    <Route exact path="/settings" element={<SettingsIndex />} />
                    <Route exact path="/locations" element={<LocationIndex />} />
                    <Route exact path="/master-fields" element={<FieldMasterIndex />} />
                    <Route exact path="/masterformtype" element={<FormTypeIndex />} />
                    <Route exact path="/masterform" element={<MasterFormIndex />} />
                    <Route exact path="/masterform/create" element={<MasterFormCreate />} />
                    <Route exact path="/submit-new-work-order" element={<SubmitWorkOrderFormV2 />} />
                    <Route exact path="/submit-new-work-order-v2" element={<SubmitWorkOrderForm  />} />
                    <Route exact path="/masterform/create-form" element={<CreateMasterForm />} />
                    <Route exact path="/submit-new-work-order" element={<SubmitWorkOrderForm />} />
                    <Route exact path="/workorders" element={<WorkOrdersIndex />} />
                    <Route exact path="/completed-work-orders" element={<CompletedWorkOrder />} />
                    <Route exact path="/assignedworkorders" element={<AssignedWorkOrdersIndex />} />
                    <Route exact path="/workordersbymap" element={<WorkOrdersByMap />} />
                    <Route exact path="/customers" element={<CustomersIndex />} />
                    <Route exact path="/requests" element={<RequestsIndex />} />
                    <Route exact path="/users" element={<UsersIndex />} />
                    <Route exact path="/users/:id/rights" element={<UserPermissionForm />} />
                    <Route exact path="/teams" element={<TeamsIndex />} />
                </Route>
                {/* 404 Routes*/}
            </Route>
            <Route path="*" element={<ErrorPage status_code="404" message="Page not found!" />} />
        </Routes>
    );
}
export default AppRoutes;