import HttpService from './HttpService';


export const LoadProfile = () => {
    const http = new HttpService();
    const profileUrl = process.env.REACT_APP_BACKEND_PROFILE_ROUTE;
    const tokenId = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.getData(profileUrl, tokenId);
}

export const UpdateService = (data, id) => {
    const http = new HttpService();
    let url = process.env.REACT_APP_BACKEND_PROFILE_ROUTE + '/' + id;
    const tokenId = process.env.REACT_APP_BACKEND_TOKEN_NAME;
    return http.postData(data, url, tokenId);
}

