import * as ActionTypes from '../ActionTypes';

const initState = {
  authResponse: "",
  loading:false,
  validation_errors:false,
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.RESTART_AUTH_RESPONSE:
      return {
        ...state,
        authResponse: "",
        loading:false,
        validation_errors:false,
      };
    case ActionTypes.LOADING:
      return {
        ...state,
        authResponse: "loading...",
        loading:true,
        validation_errors:false,
      };

    case ActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loading:false,
        validation_errors:false,
        authResponse: action.res,
      };

    case ActionTypes.SIGNUP_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading:false,
        validation_errors:false,
        authResponse: "redirecting to dashboard...",
      };
      
    case ActionTypes.LOGIN_SUCCESS_SAVE:
      return {
        ...state,
        loading:false,
        validation_errors:false,
        authResponse: action.res,
      };

    case ActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loading:false,
        validation_errors:action.res.errors,
        authResponse: action.res,
      };


    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.LOGOUT_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };
    default:
      return state;
  }
};

export default AuthReducer;

