/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import FieldMasterCreateForm from "./Form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../../Redux/Actions/CrudActions";

const FieldMasterCreate = () => {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = 'master-fields';

    const SUBMIT_BUTTON_NAME = 'Create a Master Field';

    var fields = { name: '',  description: '', utility_id: '', status: '' }

    const [errors, setErrors] = useState(fields);

    const [formData, setFormData] = useState(fields);

    const [refreshTable, setRefreshTable] = useState(false);

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success) {
            setFormData(fields);
            setErrors(fields);
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            setRefreshTable(true);
        }

    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(CreateResourceAction(BACKEND_ROUTE, formData, ACTION_TYPE));
    }

    return (
        <FieldMasterCreateForm
            data={formData}
            errors={errors}
            setData={setFormData}
            handleSubmit={handleSubmit}
            btnloading={state.form.loading}
            SubmitButtonName={SUBMIT_BUTTON_NAME}
        />
    );
}

export default FieldMasterCreate;
