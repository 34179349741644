/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import RequestsForm from "./Form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const SetingsCreate = () => {

    const PageName = 'Create New Setings';
    const SubmitButtonName = 'Create Setings';
    const ACTION_TYPE = "COMMON";
    const BACKEND_ROUTE = "customer-requests";

    var fields = {
        customer_id: '',
        service_category_id: '',
        title: '',
        description: '',
    }

    const [data, setData] = useState(fields);
    const [errors, setErrors] = useState(fields);
    const [refreshTable, setRefreshTable] = useState(false);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);

    useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
            setRefreshTable(false);
        };
    }, [refreshTable]);

    useEffect(() => {
        if (state.form.validation_errors) {
            state.form.validation_errors.map((value, key) => {
                setErrors({ ...data, [key]: value })
            });
        } else if (state.form.success) {
            setData(fields);
            setTimeout(() => {
                dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            }, 1000);
            setRefreshTable(true);
        }
    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(CreateResourceAction(BACKEND_ROUTE, data, ACTION_TYPE));
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">{PageName}</h3>
            </div>
            <div className="card-body">
                <RequestsForm
                    btnloading={state.form.loading}
                    handleSubmit={handleSubmit}
                    data={data}
                    setData={setData}
                    SubmitButtonName={SubmitButtonName}
                    errors={errors}
                />
            </div>
        </div>
    );
}
export default SetingsCreate;
