import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import { GetDataService } from '../../Services/CrudServices';

ChartJS.register(ArcElement, Tooltip, Legend);

export function PieChart() {
    
    const [loading, setLoading] = useState(true);
    const [pieGraphData, setPieGraphData] = useState({});

    useEffect(() => {
        setLoading(true);
        GetDataService('utilities').then((res)=>{
            if (res.success) {
                const labelsArray = res.data.map((o) => o.name);
                const colorsArray = res.data.map((o) => o.color_code);
                setPieGraphData(
                    {
                        labels: labelsArray,
                        datasets: [
                            {
                                label: 'Utilities Service Requested',
                                data: [40, 80, 60, 30],
                                backgroundColor: colorsArray,
                                // borderColor: colorsArray,
                                borderColor: '#000',
                                borderWidth: 1,
                            }
                        ]
                    }
                )
                setLoading(false);
            }
        },(error)=>{
            console.log(error);
        });
    }, []);

    return (<>
        <p className='text-center font-weight-bold'><small>Utlities Wise Completed Work Order Summary</small></p>
        {loading ? 'Loading......' : <Pie data={pieGraphData} />}
    </>);
}