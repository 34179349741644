/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import RequestsEdit from './Edit';
import MaterialTable from "material-table";
import {isMobile} from 'react-device-detect';
import { Spinner } from "../../Components/Spinner";
import React, { useEffect, useState } from "react";
import SideDrawer from './../../Components/SideDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from "@mui/material";
import { UpdateDataService } from '../../Services/CrudServices';
import { DestroyResourceAction, LoadResourceAction } from "../../Redux/Actions/CrudActions";

const TeamsGrid = ({ columns, dataList, setDataList, Title, HandleDrawerCreate }) => {

    const ACTION_TYPE = "COMMON";

    const CREATE_BUTTON_NAME = "Create a Team";

    const EDIT_BUTTON_NAME = "Edit Team";

    const BACKEND_ROUTE = "teams";

    const theme = createTheme();

    const dispatch = useDispatch();

    const [id, setID] = React.useState(null);

    const [refreshTable, setRefreshTable] = React.useState(false);

    const [editDrawerStatus, setEditDrawerStatus] = React.useState(null);


    const state = useSelector(state => state.common);
    const permisions = localStorage.getItem('permissions');
    const [data, setWorkData] = useState([]);
    React.useEffect(() => {
        if (refreshTable) {
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        };
        setRefreshTable(false);
    }, [refreshTable]);
    const HandleEditDrawer = (event) => {
        if (editDrawerStatus) {
            setID(null);
            setEditDrawerStatus(false);
        }
    }
    const HandleEdit = (rowData) => {
        setID(rowData.id);
        setEditDrawerStatus(true);
    }

    const handleDelete = (oldData) => {
        var ID = oldData.id;
        dispatch(DestroyResourceAction(BACKEND_ROUTE, ID, ACTION_TYPE));
    }

    const HandleActiveInActiveStatus = (rowData) => {
        var update_status_route = `update_resource_status/Team/${rowData.id}`
        var newStatus = rowData.status === 1 ? false : true;
        var data = {'_method': 'put', 'status': newStatus};
        UpdateDataService(update_status_route, data)
        .then((res) => {
            setRefreshTable(true);
        }, error => {
            console.log("error");
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns.columns}
                    data={dataList}
                    title={isMobile ? '': Title}
                    isLoading={state.all.loading}
                    {...data && {isLoading:state.all.loading}}
                    actions={[
                        {
                            hidden:permisions.includes('edit_team') ? false : true,
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => HandleEdit(rowData)
                        },
                        {
                            hidden:permisions.includes('create_team') ? false : true,
                            icon: () => (isMobile) 
                            ? <i className="fa-solid fa-plus theme-text-color-primary" style={{fontSize: '1.2rem'}}></i>
                            : <span className="btn btn-sm btn-theme-primary ml-2">{CREATE_BUTTON_NAME}</span>,

                            tooltip: 'Create a Team',
                            isFreeAction: true,
                            onClick: () => HandleDrawerCreate()
                        },
                        rowData => ({
                            hidden:permisions.includes('deactivate_team') ? false : true,
                            icon: () => (rowData.status) 
                            ? <i className="fa-solid fa-ban text-danger" style={{fontSize: '1.2rem'}}></i> 
                            : <i className="fa-solid fa-ban text-success" style={{fontSize: '1.2rem'}}></i> ,
                            tooltip: (rowData.status) ?  'Deactivate Team' : 'Activate Team',
                            onClick: (event, rowData) => HandleActiveInActiveStatus(rowData),
                        }),
                    ]}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    const data = [...dataList]
                                    data.splice(data.indexOf(oldData), 1);
                                    setDataList(data)
                                    handleDelete(oldData)
                                }, 600);
                            }),
                    }}
                />
            </div>
            <SideDrawer layout= "md"
                status={editDrawerStatus} 
                handle={HandleEditDrawer} 
                action_heading={EDIT_BUTTON_NAME}>
                {id && <RequestsEdit ID={id} />}
            </SideDrawer>
        </ThemeProvider>
    );
}

export default TeamsGrid;
