/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import React from "react";
import Select from 'react-select';
import { Water } from './AccountField/Water';
import Accordion from '@mui/material/Accordion';
import { Electric } from './AccountField/Electric';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GetDataService } from "../../Services/CrudServices";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import LoadingButton from '../../Components/Shared/LoadingButton';
import MUIInputField from "../../Components/MuiFormComponents/InputBox"
import { LoadResourceAction, UpdateResourceAction } from "../../Redux/Actions/CrudActions";

const WorkOrderForm = ({ handle, RowData, ID }) => {

    const ACTION_TYPE = "COMMON";

    const BACKEND_ROUTE = "work-orders";

    var original_fields_value = {
        team_id: RowData.team_id,
        work_order_id: RowData.id,
        leader_id: RowData.team ? RowData.worker_id : null,
        worker_id: RowData.team ? null : RowData.worker_id,
        current_status_id: RowData.current_status_id,
        current_level_id: RowData.current_level_id,
        comment: null,
        customer_id: String(RowData.customer_request.customer.id),
        utility_id: String(RowData.customer_request.service_category.utility.id),
        customer_name: RowData.customer_request.customer.full_name,
        customer_phone: RowData.customer_request.customer.mobile_number,
        customer_email: RowData.customer_request.customer.email,
        customer_address: RowData.customer_request.customer.full_address,
        account_number: String(RowData.customer_request.account_number),
        street: String(RowData.customer_request.customer.street),
        city: String(RowData.customer_request.customer.city),
        state: String(RowData.customer_request.customer.state),
        zip: String(RowData.customer_request.customer.zip),
        country: String(RowData.customer_request.customer.country),
    }

    var error_fields = {
        team_id: '',
        worker_id: '',
        current_status_id: '',
        current_level_id: '',
    }

    const dispatch = useDispatch();

    const state = useSelector(state => state.common);

    const [users, setUsers] = React.useState([]);

    const [teams, setTeams] = React.useState([]);

    const [alllevels, setAllLevels] = React.useState([]);

    const [allstatus, setAllStatus] = React.useState([]);

    const [textArea, setTextArea] = React.useState(false);

    const [errors, setErrors] = React.useState(error_fields);

    const [teamMembers, setTeamMembers] = React.useState([]);

    const [teamLoading, setTeamLoading] = React.useState(false);

    const [userLoading, setUserLoading] = React.useState(false);

    const [refreshTable, setRefreshTable] = React.useState(false);

    const [current_utility, set_current_utility] = React.useState('');

    const [accountFieldData, setAccountFieldData] = React.useState({});

    const [formData, setFormData] = React.useState(original_fields_value);

    const [allstatusloading, setAllStatusLoading] = React.useState(false);

    const [alllevelsloading, setAllLevelsLoading] = React.useState(false);

    const [teamMembersLoading, setTeamMembersLoading] = React.useState(false);

    React.useEffect(() => {

        setTeamLoading(true);
        GetDataService(`teams`).then((res) => {
            setTeamLoading(false);
            if (res.success) {
                setTeams(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setTeamLoading(false);
            setTeams([]);
        });

        setUserLoading(true);
        GetDataService(`users`).then((res) => {
            setUserLoading(false);
            if (res.success) {
                var userlist = res.data.filter(user => user.roles[0].id === 5)
                setUsers(userlist.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setUserLoading(false);
            setUsers([]);
        });

        setAllStatusLoading(true);
        GetDataService('master-work-order-statuses').then((res) => {
            setAllStatusLoading(false);
            if (res.success) {
                setAllStatus(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setAllStatus([]);
        });

        GetDataService('service-request-level').then((res) => {
            setAllLevelsLoading(false);
            if (res.success) {
                setAllLevels(res.data.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setAllLevels([]);
        });

        setAccountFieldData(RowData.customer_request.customer);

    }, []);

    React.useEffect(() => {
        if (formData.team_id) {
            setTeamMembersLoading(true);
            GetDataService(`teams/${formData.team_id}`).then((res) => {
                setTeamMembersLoading(false);
                if (res.success) {
                    setTeamMembers(res.data.team_members.map((o) => { return { value: o.id, label: o.name } }));
                    setTeamMembersLoading(false);
                } else {
                    setTeamMembersLoading(false);
                    setTeamMembers([]);
                }
            }, error => {
                setTeamMembersLoading(false);
                setTeamMembers([]);
            });
        };
    }, [formData.team_id]);


    React.useEffect(() => {
        if (String(formData.utility_id) === "1") {
            set_current_utility('gas');
        }
        if (String(formData.utility_id) === "2") {
            set_current_utility('water');
        }
        if (String(formData.utility_id) === "3") {
            set_current_utility('electric');
        }
        if (String(formData.utility_id) === "4") {
            set_current_utility('gas');
        }
    }, []);

    React.useEffect(() => {
        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }
    }, [state.form.errors]);

    React.useEffect(() => {
        if (refreshTable) {
            setRefreshTable(false);
            console.log("refreshTable",refreshTable);
            dispatch(LoadResourceAction(BACKEND_ROUTE, ACTION_TYPE));
        }
    }, [refreshTable]);
    
    React.useEffect(() => {
        if (state.form.success) {
            setRefreshTable(true);
            setErrors(error_fields);
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
            setTimeout(()=>handle(),2000);
        }
    }, [state.form.success]);

    const onHandleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    };

    const onHandleAccountFieldChange = (event) => {
        setAccountFieldData({
            ...accountFieldData,
            [event.target.name]: event.target.value
        })
    };

    const HandleReactSelectBox = (selection, action) => {
        if (action.name === 'current_status_id') {setTextArea(true);}
        setFormData({ ...formData, [action.name]: selection ? selection.value : null });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var data = {
            _method: "put",
            comment: formData.comment,
            current_level_id: formData.current_level_id,
            current_status_id: formData.current_status_id,
            team_id: formData.team_id ? formData.team_id : null,
            worker_id: formData.team_id ? formData.leader_id : formData.worker_id,
        }
        dispatch(UpdateResourceAction(BACKEND_ROUTE, ID, data, ACTION_TYPE));
    }

    return (
        <>
            <form className="m-2" onSubmit={handleSubmit}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <legend>Work Order Information</legend>
                    </AccordionSummary>
                    <AccordionDetails>
                        <fieldset>
                            <div className="row">
                                <div className="col-md-2 p-1">
                                    <MUIInputField
                                        label="Work Order #"
                                        value={RowData.id}
                                    />
                                </div>
                                <div className="col-md-3 p-1">
                                    <MUIInputField
                                        label="Service Request"
                                        value={RowData.customer_request.title}
                                    />
                                </div>
                                <div className="col-md-3 p-1">
                                    <MUIInputField
                                        label="Service Request Level"
                                        value={RowData.current_level.name}
                                    />
                                </div>
                                <div className="col-md-4 p-1">
                                    <MUIInputField
                                        label="Request Created On"
                                        value={RowData.customer_request.created_at}
                                    />
                                </div>
                                <div className="col-md-4 p-1">
                                    <MUIInputField
                                        label="Customer Comments"
                                        value={RowData.customer_request.description}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <legend>Customer Information</legend>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-md-12">
                                <fieldset>
                                    <div className="row mb-2">
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="Customer Name"
                                                value={accountFieldData.full_name}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-1">
                                            <MUIInputField
                                                label="Phone Number"
                                                value={accountFieldData.mobile_number}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-5 mb-1">
                                            <MUIInputField
                                                label="Email Address"
                                                value={accountFieldData.email}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <MUIInputField
                                                label="Street Address"
                                                value={accountFieldData.street}
                                                onChange={onHandleAccountFieldChange}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="City"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.city}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="State"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.state}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                            <MUIInputField
                                                label="Zip"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.zip}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <MUIInputField
                                                label="Country"
                                                onChange={onHandleAccountFieldChange}
                                                value={accountFieldData.country}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <legend>Account Information</legend>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-md-12">
                                {(current_utility && current_utility === 'electric') && <Electric accountFieldData={accountFieldData} onHandleAccountFieldChange={onHandleAccountFieldChange} />}
                                {(current_utility && current_utility === 'water') && <Water accountFieldData={accountFieldData} onHandleAccountFieldChange={onHandleAccountFieldChange} />}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div className="row">
                    <div className="col-md-6">
                        <fieldset>
                            <legend>Work Assignment</legend>
                            <div className="row">
                                <div className="col-md-12">
                                    <h6>Current Assignment:
                                        {
                                            formData.team_id && formData.leader_id
                                                ? <span className="badge badge-info badge-pill mx-2">Team</span>
                                                : !formData.team_id && !formData.leader_id && formData.worker_id
                                                    ? <span className="badge badge-info badge-pill mx-2">Single Person</span>
                                                    : <span className="badge badge-danger badge-pill mx-2">Not Assigned! Please Select Appropriate Assignment</span>
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="flex w-100" style={{ gap: "10px" }}>
                                <div className="w-50 p-2 border-2 border-primary rounded" style={{ height: '140px', borderWidth: '2px' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6>Team Assignment:</h6>
                                        </div>
                                        <div className="col-md-12">
                                            <Select
                                                name="team_id"
                                                options={teams}
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                isLoading={teamLoading}
                                                classNamePrefix="select"
                                                closeMenuOnSelect={true}
                                                value={teams.map((o) => o.value === formData.team_id ? o : '')}
                                                placeholder={teamMembersLoading ? "Data Loading..." : "Select Team..."}
                                                onChange={HandleReactSelectBox}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Select
                                                name="leader_id"
                                                isClearable={true}
                                                isSearchable={true}
                                                options={teamMembers}
                                                classNamePrefix="select"
                                                closeMenuOnSelect={true}
                                                isLoading={teamMembersLoading}
                                                value={teamMembers.map((o) => o.value === formData.leader_id ? o : '')}
                                                placeholder={teamMembersLoading ? "Data Loading..." : "Select Team Leader..."}
                                                onChange={HandleReactSelectBox}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-50 p-2 border-2 border-primary rounded" style={{ height: '140px', borderWidth: '2px' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6>Single Person Assignment:</h6>
                                        </div>
                                        <div className="col-md-12">
                                            <Select
                                                id="worker_id"
                                                name="worker_id"
                                                options={users}
                                                isLoading={userLoading}
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder={alllevelsloading ? "Data Loading..." : "Select Worker..."}
                                                classNamePrefix="select"
                                                closeMenuOnSelect={true}
                                                value={users.map((o) => o.value === formData.worker_id ? o : '')}
                                                onChange={HandleReactSelectBox}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-md-6">
                        <fieldset>
                            <legend>Update Information</legend>
                            <div className="row mb-1">
                                <div className="col-md-12">
                                    <div className="d-flex align-items-top">
                                        <div>
                                            <h6 className="m-0 p-0">Current Status:</h6>
                                        </div>
                                        <div className="px-3">
                                            <span className="badge badge-pill badge-info">{RowData.statuses[RowData.statuses.length - 1].name}</span>
                                        </div>
                                        <div className="overflow-auto px-3 flex-fill" style={{ width: "40%", maxHeight: "80px", backgroundColor: "#fff2a4" }}>
                                            <div className="d-inline rounded text-sm px-2 py-1">
                                                {RowData.statuses[RowData.statuses.length - 1].pivot.comment}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Update Work Order Status:</h6>
                                    <Select
                                        name="current_status_id"
                                        options={allstatus}
                                        isLoading={allstatusloading}
                                        isDisabled={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        placeholder={alllevelsloading ? "Data Loading..." : "Search Status..."}
                                        value={allstatus.map((o) => o.value === formData.current_status_id ? o : '')}
                                        onChange={HandleReactSelectBox}
                                    />
                                    <h6 className="mt-3" style={{ display: textArea ? 'block' : 'none' }}>Status Comment:</h6>
                                    <textarea
                                        name="comment"
                                        cols="40"
                                        className="workOrderEditFormTextarea"
                                        style={{ display: textArea ? 'block' : 'none' }}
                                        onChange={(e) => onHandleChange(e)}
                                    >{formData.comment}</textarea>
                                </div>
                                <div className="col-md-6">
                                    <h6>Service Request Level:</h6>
                                    <Select
                                        name="current_level_id"
                                        options={alllevels}
                                        isLoading={alllevelsloading}
                                        isDisabled={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={alllevelsloading ? "Data Loading..." : "Search Level..."}
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        value={alllevels.map((o) => o.value === formData.current_level_id ? o : '')}
                                        onChange={HandleReactSelectBox}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div className="flex items-center justify-content-end px-2 py-4">
                    <button className="btn mx-1 btn-outline-danger" onClick={() => handle()}>Cancel</button>
                    <LoadingButton className="mx-1 btn" loading={state.form.loading} type="submit">Save Changes</LoadingButton>
                </div>
                <div style={{ marginBottom: "200px" }}></div>
            </form>
        </>
    );
}

export default WorkOrderForm;
