import React, { useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

const markers = [
  {
    id: 1,
    name: "DummyName_1",
    accNum: "123456789",
    dob: "12/10/1995",
    address: "Chicago, Illinois, 7245 Lake St, River Forest, IL 60305, United States",
    position: { lat: 41.881832, lng: -87.623177 },
  },
  {
    id: 2,
    name: "DummyName_2",
    accNum: "987654321",
    dob: "11/10/1995",
    address: "Denver, Colorado, 1007 York St, Denver, CO 80206, United States",
    position: { lat: 39.739235, lng: -104.99025 }
  },
  {
    id: 3,
    name: "DummyName_3",
    accNum: "147258369",
    dob: "10/10/1995",
    address: "Los Angeles, California",
    position: { lat: 34.052235, lng: -118.243683 }
  },
  {
    id: 4,
    name: "DummyName_4",
    accNum: "369258147",
    dob: "09/10/1995",
    address: "New York, New York",
    position: { lat: 40.712776, lng: -74.005974 }
  }
];

function Map() {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const google = window.google;

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ height: "100vh" }}
    >
      {markers.map(({ id,name,accNum, dob, address, position }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div style={{borderTop: "0px solid transparent",borderLeft: "0px solid transparent",width:"200px"}}>
                <p style={{margin:"8px"}}><i class="fa-solid fa-user"></i>&nbsp;&nbsp;&nbsp;Name - {name}</p>
                <p style={{margin:"8px"}}><i class="fa-solid fa-building-columns"></i>&nbsp;&nbsp;&nbsp;A/C No. - {accNum}</p>
                <p style={{margin:"8px"}}><i class="fa-solid fa-calendar-days"></i>&nbsp;&nbsp;&nbsp;DOB - {dob}</p>
                <p style={{margin:"8px"}}><i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;&nbsp;Address - {address}</p>
                {/* <p style={{lineHeight:"0"}}>{name}</p> */}
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default Map;