/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import RolesForm from "./Form";
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateResourceAction } from "../../../Redux/Actions/CrudActions";
import ContentWrapper from "../../../Layouts/AdminLTE/ContentWrapper";

const RolesCreate = () => {

    const IndexRoute = "/roles"
    const IndexPageName = 'Roles';
    const PageName = 'Create New Role';
    const SubmitButtonName = 'Create Role';
    const BACKEND_ROUTE = "roles";
    const ACTION_TYPE = "COMMON";

    var fields = {
        name: '',
        description: '',
        is_system_role: false,
        permissions: []
    }

    const [data, setData] = useState(fields);

    const [errors, setErrors] = useState(fields);

    const state = useSelector(state => state.common);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: `RESTART_${BACKEND_ROUTE}_FORM` });
    }, []);


    useEffect(() => {

        if (state.form.errors && state.form.errors.validation_errors) {
            const errors = state.form.errors.validation_errors;
            for (let error in errors) {
                var key = error;
                var value = errors[key][0];
                setErrors({ ...errors, [key]: value });
            }
            dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` });
        }

        if (state.form.success) {
            setData(fields);
            setErrors(fields);
            setTimeout(() => { dispatch({ type: `RESTART_${ACTION_TYPE}_FORM` })}, 1000);
            document.querySelectorAll('[type="checkbox"]').forEach((element) => { element.checked=false });
        }

    }, [state]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(CreateResourceAction(BACKEND_ROUTE, data, ACTION_TYPE));
    }

    return (
        <ContentWrapper page_name={PageName} breadcrumbs={breadcrumbs(PageName, IndexRoute, IndexPageName)}>
            <div className="card rounded shadow">
                <div className="card-header">
                    <h3 className="card-title">{PageName}</h3>
                </div>
                <div className="card-body pt-0">
                    <RolesForm
                        btnloading={state.form.loading}
                        handleSubmit={handleSubmit}
                        data={data}
                        setData={setData}
                        SubmitButtonName={SubmitButtonName}
                        errors={errors}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
}

function breadcrumbs(PageName, IndexRoute = false, IndexPageName = false) {
    return (
        <>
            {IndexPageName && <li className="breadcrumb-item"><NavLink to={IndexRoute}>{IndexPageName}</NavLink></li>}
            <li className="breadcrumb-item active">{PageName}</li>
        </>
    )
}

export default RolesCreate;