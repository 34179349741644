/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable-next-line array-callback-return */
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { GetDataService } from "../../Services/CrudServices";
import LoadingButton from '../../Components/Shared/LoadingButton';
import MUIInputField from "../../Components/MuiFormComponents/InputBox";
import MUIInputRadio from '../../Components/MuiFormComponents/InputRadio';
import MUIGroupRadio from '../../Components/MuiFormComponents/InputGroupRadio';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);
const TeamsForm = ({ handleSubmit, data, setData, SubmitButtonName, errors, btnloading }) => {

    const dispatch = useDispatch();

    const state_show = useSelector(state => state.common);

    const [users, setUsers] = useState([]);

    const [locations, setLocations] = React.useState([]);

    const [utilities, setUtilities] = React.useState([]);

    const [services, setServices] = React.useState([]);

    const [loadingUsers, setLoadingUsers] = React.useState(false);

    const [loadingDepartments, setLoadingDepartments] = React.useState(false);

    const [loadingLocation, setloadingLocation] = React.useState(false);

    const [utilitiesLoading, setUtilitiesLoading] = React.useState(false);

    const [departments, setDepartments] = React.useState([]);

    const [subDepartments, setSubDepartments] = React.useState([]);

    const [subDepartmentsLoading, setSubDepartmentsLoading] = React.useState(false);

    useEffect(() => {
        setloadingLocation(true);
        GetDataService('locations').then((res) => {
            setloadingLocation(false);
            if (res.success) {
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setloadingLocation(false);
                setLocations(datalist);
            }
        }, error => {
            setloadingLocation(false);
            setLocations([]);
        });

        setUtilitiesLoading(true);
        GetDataService('utilities').then((res) => {
            setUtilitiesLoading(false);
            if (res.success) {
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setUtilitiesLoading(false);
                setUtilities(datalist);
            }
        }, error => {
            setUtilitiesLoading(false);
            setUtilities([]);
        });

        setLoadingUsers(true);
        GetDataService('users').then((res) => {
            setLoadingUsers(false);
            if (res.success) {
                var userlist = res.data.filter(user => user.roles[0].id === 5)
                setUsers(userlist.map((o) => { return { value: o.id, label: o.name } }));
            }
        }, error => {
            setLoadingUsers(false);
            setUsers([]);
        });
    }, []);

    // var sub_departments_array  =  [];

    useEffect(() => {
        if (data.utility_id) {
            setUtilitiesLoading(true);
            GetDataService(`utilities/${data.utility_id}`).then((res) => {
                setUtilitiesLoading(false);
                if (res.success) {
                    var datalist = res.data.services.map((o) => { return { value: o.id, label: o.name } });
                    setServices(datalist);
                }
            }, error => {
                setUtilitiesLoading(false);
                setServices([]);
            });

            setLoadingDepartments(true);
            GetDataService(`departments_by_utility/${data.utility_id}`).then((res) => {
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setDepartments(datalist);
                setLoadingDepartments(false);
            }, error => {
                setDepartments([]);
                setLoadingDepartments(false);
            });
        }
    }, [data.utility_id]);

    const HandleReactSelectBox = (selection, action) => {
        setData({
            ...data,
            [action.name]: selection
                ? selection.value
                : null
        });
    };

    const onHandleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const onHandleRadioChange = (event) => {
        if (event.target.checked) {
            setData({ ...data, [event.target.name]: event.target.value })
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    };

    React.useEffect(() => {
        if (data.utility_department_parent_id) {
            setSubDepartmentsLoading(true);
            GetDataService(`sub_departments_by_department/${data.utility_department_parent_id}`).then((res) => {
                var datalist = res.data.map((o) => { return { value: o.id, label: o.name } });
                setSubDepartments(datalist);
                setSubDepartmentsLoading(false);
            }, error => {
                setSubDepartments([]);
                setSubDepartmentsLoading(false);
            });
        }
    }, [data.utility_department_parent_id]);

    const HandleReactSelect = (selection, action) => {
        setData({ ...data, [action.name]: selection ? selection.value : null });
    };

    const HandleMutipleReactSelect = (selection, action) => {
        setData({ ...data, [action.name]: selection.map(element => element.value) });
    };

    return (

        <form onSubmit={handleSubmit}>
            <div className="row" >
                <div className="col-md-12">
                    <MUIInputField
                        required
                        name="name"
                        label="Team Name"
                        value={data.name}
                        onChange={onHandleChange}
                        validation_error={errors.name}
                    >
                    </MUIInputField>
                </div>
                <div className="col-md-12 my-2">
                    <Select
                        required={true}
                        id="location_id"
                        name="location_id"
                        isDisabled={false}
                        isClearable={true}
                        options={locations}
                        isSearchable={true}
                        classNamePrefix="select"
                        closeMenuOnSelect={true}
                        isLoading={loadingLocation}
                        placeholder="Select Location..."
                        className="basic-multi-select"
                        onChange={HandleReactSelect}
                        value={locations.filter((o) => o.value === data.location_id)}
                    />
                </div>
                <div className="col-md-6 my-2">
                    <Select
                        id="utility_id"
                        required={true}
                        name="utility_id"
                        isDisabled={false}
                        isClearable={true}
                        options={utilities}
                        isSearchable={true}
                        classNamePrefix="select"
                        closeMenuOnSelect={true}
                        isLoading={utilitiesLoading}
                        className="basic-multi-select"
                        placeholder="Select Utility..."
                        onChange={HandleReactSelect}
                        value={utilities.filter((o) => o.value === data.utility_id)}
                    />
                </div>
                <div className="col-md-6 my-2">
                        <Select
                            id="utility_department_parent_id"
                            name="utility_department_parent_id"
                            options={departments}
                            isLoading={loadingDepartments}
                            isDisabled={false}
                            isClearable={true}
                            isSearchable={true}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            value={departments.map((o) => o.value === data.utility_department_parent_id && o)}
                            placeholder={"Select Utility Department..."}
                            onChange={HandleReactSelectBox}

                        />
                        <div>{(errors.utility_department_parent_id && errors.utility_department_parent_id.length > 0) ? <span className="text-danger">Please Select Utility Department</span> : ''}</div>
                </div>
                <div className="col-md-12 my-2">
                    <Select
                        isMulti
                        required={true}
                        className="w-100"
                        isDisabled={false}
                        isClearable={true}
                        isSearchable={true}
                        options={subDepartments}
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        id="utility_department_id"
                        name="utility_department_id"
                        isLoading={subDepartmentsLoading}
                        placeholder="Select Utility's Sub Department..."
                        formatGroupLabel={formatGroupLabel}
                        onChange={HandleMutipleReactSelect}
                        value={subDepartments ? subDepartments.filter((o) => data.utility_department_id.includes(o.value)) : []}
                    />
                </div>
                <div className="col-md-12 my-2">
                    <Select
                        isMulti
                        options={users}
                        id="team_members"
                        isClearable={true}
                        name="team_members"
                        isSearchable={true}
                        classNamePrefix="select"
                        isLoading={loadingUsers}
                        closeMenuOnSelect={false}
                        className="basic-multi-select"
                        placeholder="Select Team Members..."
                        onChange={HandleMutipleReactSelect}
                        value={users.filter((o) => data.team_members.includes(o.value))}
                    />
                </div>
                <div className="col-md-12 my-2">
                    <MUIGroupRadio name="status" label="Team Status"
                        value={data.status}
                        validation_error={errors.status}
                        onChangeHandler={onHandleRadioChange}
                        required={true}
                    >
                        <MUIInputRadio value="1">Active</MUIInputRadio>
                        <MUIInputRadio value="0">Inactive</MUIInputRadio>
                    </MUIGroupRadio>
                </div>
            </div>
            <div className="flex items-center justify-content-end px-2 py-4 bg-gray-100 border-t border-gray-200">
                <LoadingButton loading={btnloading} type="submit">{SubmitButtonName}</LoadingButton>
            </div>
        </form>

    );
}

export default TeamsForm;