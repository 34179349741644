import { toast } from 'react-toastify';

export function HandleExeption(data) {

    if (data.hasOwnProperty('exception_type')
        && data.exception_type === "unthenticated") {
        localStorage.removeItem(this.logurl_token_name);
        localStorage.removeItem('permissions')
        toast.warn('Your login has been expired! please log in again.', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(function () {window.location.href = '/login'}, 2000);
    }
}

export function RaiseSomethingWentWrong() {
    toast.error('Something Went Wrong!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}